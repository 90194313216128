import { DropDownIcon, PriorityIcon } from "assets/icons";
import React from "react";
import CustomButton from "shared/components/customButton";
import styles from "./style.module.scss";
import { Menu, MenuItem } from "@mui/material";
import { priority_enums } from "shared/utils/enum";
import GetPriority from "shared/components/getPriority";

function PriorityFilter({
  prioritySelection,
  setPrioritySelection,
  icon,
}: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      label: "High",
      id: priority_enums.high,
    },
    {
      label: "Medium",
      id: priority_enums.medium,
    },
    {
      label: "Low",
      id: priority_enums.low,
    },
  ];

  return (
    <div>
      <CustomButton
        label={
          prioritySelection ? (
            <GetPriority priority={prioritySelection?.id} />
          ) : (
            "Priority"
          )
        }
        IconRightSvg={icon ? PriorityIcon : DropDownIcon}
        iconStyle={styles.icon}
        buttonClass={styles.btn}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid #e2e2e2",
            padding: "0px 10px",
          },
        }}
      >
        {menuItems?.map((item) => {
          return (
            <MenuItem
              onClick={() => {
                handleClose();
                setPrioritySelection(item);
              }}
            >
              <GetPriority priority={item?.id} />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

export default PriorityFilter;
