import React from "react";
import styles from "./style.module.scss";
import { CheckboxIcon } from "assets/icons";

const CheckBox = ({ checkBox, handleCallback, type, color }: any) => {
  const containerStyle = {
    borderRadius: type === "circle" ? "50%" : undefined,
    background:
      type === "circle" && checkBox ? (color ? color : "#27a376") : undefined,
  };

  return (
    <div
      className={`${styles.checkbox} ${
        checkBox ? styles.checked : styles.unchecked
      }`}
      style={containerStyle}
      onClick={handleCallback}
    >
      {checkBox && <CheckboxIcon />}
    </div>
  );
};

export default CheckBox;
