import { Avatar, Grid } from "@mui/material";
import { AddIcon, DescriptionIcon, PaperClipIcon } from "assets/icons";
import { useState } from "react";
import styles from "./style.module.scss";
import EmojiPicker from "shared/components/emojiPicker";
import classNames from "classnames";
import CustomButton from "shared/components/customButton";
import TasksContainer from "../taskContainer";
import AddTasks from "../addTasks";
import AddDetails from "../addDetails";

function ProjectDetailsCard() {
  const [addTask, setAddTask] = useState<boolean>(false);
  const [isCommentAreaFocused, setIsCommentAreaFocused] = useState(false);

  const handleTextareaFocus = () => {
    setIsCommentAreaFocused(true);
  };

  const handleTextareaBlur = () => {
    setIsCommentAreaFocused(false);
  };

  const handleEmoji = () => {};
  return (
    <div className={styles.mainContainer}>
      <Grid container className={styles.mainGrid}>
        <Grid item xs={8} className={styles.rightGrid}>
          <div>
            <div style={{ position: "relative" }}>
              <DescriptionIcon className={styles.descriptionIcon} />
              <textarea className={styles.description} />
            </div>
          </div>

          <TasksContainer />

          <div>
            {addTask ? (
              <AddTasks setAddTask={setAddTask} />
            ) : (
              <button
                className={styles.addTaskBtn}
                onClick={() => {
                  setAddTask(true);
                }}
              >
                <div>
                  <label>Add task</label> <AddIcon />
                </div>
              </button>
            )}
          </div>

          <div className={styles.separator} />

          <div className={styles.commentMainContainer}>
            <div className={styles.commentContainer}>
              <div style={{ marginRight: "12px" }}>
                <Avatar
                  variant="square"
                  sx={{ width: "46px", height: "46px" }}
                />
              </div>

              <div
                style={{
                  position: "relative",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <textarea
                  className={classNames(
                    styles.commentArea,
                    isCommentAreaFocused && styles.commentFocused
                  )}
                  placeholder="Comment"
                  onFocus={() => handleTextareaFocus()}
                  onBlur={(e: any) => {
                    console.log("e.relatedTarget", e.relatedTarget);
                    if (
                      e.relatedTarget &&
                      e.relatedTarget.id === "emoji-button"
                    ) {
                      e.preventDefault();
                      return;
                    }
                    handleTextareaBlur();
                  }}
                />
                <PaperClipIcon
                  className={classNames(
                    styles.paperClip,
                    isCommentAreaFocused && styles.paperClipFocused
                  )}
                  onClick={(e: any) => {
                    e.preventDefault();
                    setIsCommentAreaFocused(true);
                  }}
                />
                {isCommentAreaFocused && (
                  <div className={styles.emojiPickerContainer}>
                    <EmojiPicker handleCallback={handleEmoji} />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.btnContainer}>
              <CustomButton label={"Comment"} buttonClass={styles.commentBtn} />
            </div>
          </div>
        </Grid>
        <Grid item xs={4} className={styles.leftGrid} sx={{ padding: "0px" }}>
          <div>
            <AddDetails />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ProjectDetailsCard;
