import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DropDownPointerIcon } from "assets/icons";

export default function Actions({ item, actionItems }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <DropDownPointerIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid #e2e2e2",
            padding: "0px 10px",
          },
        }}
      >
        {actionItems?.map((itm: any) => {
          return (
            <MenuItem
              onClick={() => {
                handleClose();
                itm?.action(item);
              }}
            >
              {itm?.icon}
              <span style={{ marginLeft: "10px" }} className={itm?.styles}>
                {" "}
                {itm?.label}
              </span>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
