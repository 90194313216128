import { Box, Grid, Tab, Tabs } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { SearchIcon } from "assets/icons";
import { Sample1, Sample2, Sample3, Sample4 } from "assets/sample";
import classNames from "classnames";
import { useFormik } from "formik";
import React, { useState } from "react";
import CustomButton from "shared/components/customButton";
import CustomFilter from "shared/components/customFilterTab";
import CustomInput from "shared/components/customInput";
import InviteCard from "shared/components/pages/channel/inviteCard";
import { toastMessage } from "shared/components/toast";
import useWindowSize from "shared/customHooks/useWindowSize";
import { SingleEmailValidation } from "shared/utils/validation";
import styles from "./style.module.scss";

// Interface for initial form values
interface InitialValues {
  email: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface InviteMembersProps {
  open: boolean;
  handleClose: any;
  selectedChannel: any;
}

export default function InviteMembers({
  open,
  handleClose,
  selectedChannel,
}: InviteMembersProps) {
  const width = useWindowSize();
  const isLargeScreen = width > 1530;
  const [value, setValue] = useState<any>(0);
  const [search, setSearch] = useState<any>("");
  const [selectedStatusFilter, setSelectedStatusFilter] =
    React.useState<any>(null);
  const [inviteLoader, setInviteLoader] = useState<boolean>(false);

  
  const tabs = [
    {
      value: 0,
      label: "All",
    },
    {
      value: 1,
      label: "Clients",
    },
    {
      value: 1,
      label: "Employees",
    },
  ];

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  /* ------------------------------------Formik handling------------------------------------- */

  const initialValues: InitialValues = {
    email: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: SingleEmailValidation,
    onSubmit: (value) => {
      handleInvite(value?.email);
    },
  });

  const { handleChange, handleSubmit, values, touched, errors, setFieldValue } =
    formik;

  /* ---------------------------------------------------------------------------------------- */

  const menuItems = [
    { label: "Invited", id: 1 },
    { label: "Not Invited", id: 2 },
    { label: "Active", id: 3 },
  ];

  const onClose = () => {
    handleClose();
    setSelectedStatusFilter(null);
    formik.resetForm();
    setSearch("");
  };

  const dummy = [
    {
      name: "Robert Anderson",
      email: "Robertandr96@gmail.com",
      profile_pic: Sample1,
    },
    {
      name: "Justin Trevor",
      email: "justintrevor01@gmail.com",
      profile_pic: Sample2,
    },
    {
      name: "Davis Rosser",
      email: "davisrosser@gmail.com",
      profile_pic: Sample3,
    },
    {
      name: "Emerson Levin",
      email: "emersonlevin16@gmail.com",
      profile_pic: Sample4,
    },
    {
      name: "Lydia Franci",
      email: "franci781@gmail.com",
      profile_pic: null,
    },
  ];

  const handleInvite = (email: any) => {
    setInviteLoader(true);
    setTimeout(() => {
      setInviteLoader(false);
      toastMessage("success", `${email} has been invited.`);
      formik.resetForm();
    }, 2000);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          sx: {
            padding: "24px",
            width: "100%",
            justifyContent: "center",
            maxWidth: (theme) => theme.spacing(120),
            borderRadius: "10px",
          },
        }}
      >
        <Grid container>
          <Grid item xs={12} md={5}>
            <div className={styles.inviteContainer}>
              <div className={styles.inviteTitle}>
                Invite Guests to Your Project
              </div>
              <div className={styles.inviteSubTitle}>
                Add email addresses to invite guests to collaborate on your
                project
              </div>
              <CustomInput
                type="email"
                placeholder={"Justintrevor@gmail.com"}
                heading=""
                // isRequired
                value={values.email}
                onChange={handleChange("email")}
                error={touched.email && errors.email ? errors.email : ""}
                onKeyDown={handleSubmit}
              />
              <CustomButton
                label="Invite Guest"
                buttonClass={styles.btn}
                loadingStyle={{
                  color: "white",
                  width: "20px !important",
                  height: "20px !important",
                }}
                loading={inviteLoader}
                onClick={() => {
                  handleSubmit();
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChangeTab}
                className={styles.tab}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#FF2626",
                  },
                }}
              >
                {tabs?.map((item: any, index: any) => {
                  return (
                    <Tab
                      key={index}
                      label={item?.label}
                      className={classNames(
                        styles.labelTab,
                        value === item?.value && styles.active
                      )}
                    />
                  );
                })}
              </Tabs>
            </Box>

            <div className={styles.topActionContainer}>
              <CustomFilter
                label="Status"
                selection={selectedStatusFilter}
                setSelection={setSelectedStatusFilter}
                menuItems={menuItems}
                selectionLabel={
                  selectedStatusFilter ? selectedStatusFilter?.label : null
                }
              />
              <div className={styles.searchContainer}>
                <SearchIcon className={styles.icon} />
                <input
                  value={search}
                  onChange={(e: any) => setSearch(e.target.value)}
                  placeholder="Search members"
                  className={styles.searchInput}
                />
              </div>
            </div>

            <div className={styles.membersContainer}>
              {dummy?.map((item: any, index: any, array: any) => {
                return open ? (
                  <InviteCard member={item} index={index} array={array} />
                ) : (
                  <></>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
