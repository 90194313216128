import React from "react";
import styles from "./style.module.scss";
import { status_enums } from "shared/utils/enum";

function GetStatus({ status }: any) {
  return (
    <div
      id={
        status_enums.done === status
          ? styles.done
          : status_enums.inprogress === status
          ? styles.progress
          : status_enums.planning === status
          ? styles.planning
          : status_enums.cancelled === status
          ? styles.cancelled
          : status_enums?.paused === status
          ? styles.paused
          : ""
      }
    >
      <span style={{ marginRight: "10px" }}>•</span>
      {status_enums?.done === status ? (
        <span>Done</span>
      ) : status_enums?.inprogress === status ? (
        <span>In Progress</span>
      ) : status_enums.planning === status ? (
        <span>Planning</span>
      ) : status_enums.cancelled === status ? (
        <span>Cancelled</span>
      ) : status_enums.paused === status ? (
        <span>Paused</span>
      ) : null}
    </div>
  );
}

export default GetStatus;
