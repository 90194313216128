import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  CalendarIcon2,
  CalendarIcon3,
  CrossIcon,
  DollarIcon2,
  DollarIconFilled,
  DollarSignIcon,
  DropDownIcon,
  InfoCircleGray,
} from "assets/icons";
import React, { useState } from "react";
import styles from "./style.module.scss";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { AddManualTimeValidation } from "shared/utils/validation";
import classNames from "classnames";
import CustomSelection from "shared/components/customSelection";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import CustomButton from "shared/components/customButton";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AddManualTimeProps {
  open: boolean;
  handleClose: any;
}

interface InitialValues {
  project: string;
  task: string;
  description: string;
  hours: string;
  mins: string;
  seconds: string;
  date: string;
}

const users = [
  {
    name: "Jane Doe",
  },
  {
    name: "Killmar",
  },
];

const projects = [
  {
    label: "Fatish",
    id: 1,
  },
  {
    label: "Multiverse X",
    id: 2,
  },
];

const tasks = [
  {
    label: "Redesign Home UI",
    id: 1,
  },
  {
    label: "CRM Development",
    id: 2,
  },
  {
    label: "New logo design",
    id: 3,
  },
];

export default function AddManualTime({
  open,
  handleClose,
}: AddManualTimeProps) {
  const [projectOpen, setProjectOpen] = useState<boolean>(false);
  const [projectSelected, setProjectSelected] = useState<any>(null);

  const [taskOpen, setTaskOpen] = useState<boolean>(false);
  const [taskSelected, setTaskSelected] = useState<any>(null);

  const [billability, setBillability] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(new Date());

  const CustomDatePicker = React.forwardRef(
    ({ value, onClick, className }: any, ref: any) => (
      <button className={styles.calendarBtn} onClick={onClick} ref={ref}>
        {moment(startDate).format("MMM DD, YYYY")} <CalendarIcon2 />
      </button>
    )
  );

  const initialValues: InitialValues = {
    project: "",
    task: "",
    description: "",
    hours: "",
    mins: "",
    seconds: "",
    date: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddManualTimeValidation,
    onSubmit: (value) => {
      console.log("value", value);
    },
  });

  // Destructuring formik methods and values
  const { handleChange, handleSubmit, values, touched, errors, setFieldValue } =
    formik;

  const onClose = () => {
    handleClose();
    formik.resetForm();
    formik?.resetForm();
    setProjectOpen(false);
    setTaskOpen(false);
    setProjectSelected(null);
    setTaskSelected(null);
    setStartDate(new Date());
    setBillability(false);
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#6C9B7D" : "#6C9B7D",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark" ? "#808080" : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const handleBillability = (event: any, login: boolean) => {
    setBillability(event.target.checked);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            padding: "24px",
            width: "100%",
            justifyContent: "center",
            maxWidth: (theme) => theme.spacing(100),
            borderRadius: "10px",
          },
        }}
      >
        <div className={styles.upperActionContainer}>
          <div className={styles.label}>Add Manual Time</div>
          <div onClick={() => onClose()}>
            <CrossIcon className={styles.crossIcon} />
          </div>
        </div>

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ position: "relative" }}>
              <label className={styles.inputLabel}>
                Select Project <span>*</span>
              </label>

              <div
                className={classNames(styles.selectionContainer)}
                onClick={(e: any) => {
                  setProjectOpen(true);
                }}
              >
                {projectSelected ? (
                  <label style={{ cursor: "pointer" }}>
                    {projectSelected?.label}
                  </label>
                ) : (
                  <label style={{ cursor: "pointer" }}>Select Project</label>
                )}

                <DropDownIcon />
              </div>
              <CustomSelection
                openSelection={projectOpen}
                setOpenSelection={setProjectOpen}
                optionsArray={projects}
                setSelected={(item: any) => {
                  setProjectSelected(item);
                  setFieldValue("project", item);
                }}
                height="auto"
                overflow="auto"
              />
              {touched.project && errors.project
                ? errors.project && (
                    <div className={styles.error}>{errors.project}</div>
                  )
                : ""}
            </div>

            <div
              style={{ position: "relative" }}
              className={styles.mobileTopMargin}
            >
              <label className={styles.inputLabel}>
                Select Task <span>*</span>
              </label>

              <div
                className={classNames(styles.selectionContainer)}
                onClick={(e: any) => {
                  setTaskOpen(true);
                }}
              >
                {taskSelected ? (
                  <label style={{ cursor: "pointer" }}>
                    {taskSelected?.label}
                  </label>
                ) : (
                  <label style={{ cursor: "pointer" }}>Select Task</label>
                )}

                <DropDownIcon />
              </div>
              <CustomSelection
                openSelection={taskOpen}
                setOpenSelection={setTaskOpen}
                optionsArray={tasks}
                setSelected={(item: any) => {
                  setTaskSelected(item);
                  setFieldValue("task", item);
                }}
                height="auto"
                overflow="auto"
              />
              {touched.task && errors.task
                ? errors.task && (
                    <div className={styles.error}>{errors.task}</div>
                  )
                : ""}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <label className={styles.inputLabel}>
              Description <span>*</span>
            </label>
            <textarea
              name="description"
              placeholder="description"
              onChange={(e) => {
                setFieldValue("description", e.target.value);
              }}
              className={classNames(
                styles.description,
                touched.description && errors.description && styles.errorBorder
              )}
              value={values.description}
            />
            {touched.description && errors.description
              ? errors.description && (
                  <div className={styles.error}>{errors.description}</div>
                )
              : ""}
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classNames(styles.headingContainer)}>
              <label
                className={styles.inputLabel}
                style={{ marginBottom: "10px" }}
              >
                Total Time <span>*</span>
              </label>
              <label className={styles.timeHeading}>
                {values?.hours ? values?.hours : "00"}:
                {values?.mins ? values?.mins : "00"}:
                {values?.seconds ? values?.seconds : "00"}
              </label>
            </div>

            <div className={styles.inputContainer}>
              <div>
                <input
                  value={values?.hours}
                  name="hours"
                  placeholder="Hrs"
                  onChange={(e: any) => {
                    if (e.target.value < 1) {
                      setFieldValue("hours", 1);
                    } else if (e.target.value <= 24) {
                      setFieldValue("hours", e.target.value);
                    } else if (e.target.value > 24) {
                      setFieldValue("hours", 24);
                    }
                  }}
                />
                {touched.hours && errors.hours
                  ? errors.hours && (
                      <div className={styles.error}>{errors.hours}</div>
                    )
                  : ""}
              </div>
              <div>
                <input
                  value={values?.mins}
                  placeholder="Min"
                  name="mins"
                  onChange={(e: any) => {
                    if (e.target.value < 1) {
                      setFieldValue("mins", 1);
                    } else if (e.target.value <= 60) {
                      setFieldValue("mins", e.target.value);
                    } else if (e.target.value > 60) {
                      setFieldValue("mins", 60);
                    }
                  }}
                />
                {touched.mins && errors.mins
                  ? errors.mins && (
                      <div className={styles.error}>{errors.mins}</div>
                    )
                  : ""}
              </div>
              <div>
                <input
                  value={values?.seconds}
                  placeholder="Sec"
                  name="seconds"
                  onChange={(e: any) => {
                    if (e.target.value < 1) {
                      setFieldValue("seconds", 1);
                    } else if (e.target.value <= 60) {
                      setFieldValue("seconds", e.target.value);
                    } else if (e.target.value > 60) {
                      setFieldValue("seconds", 60);
                    }
                  }}
                />
                {touched.seconds && errors.seconds
                  ? errors.seconds && (
                      <div className={styles.error}>{errors.seconds}</div>
                    )
                  : ""}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <label
              className={styles.inputLabel}
              style={{ marginBottom: "10px" }}
            >
              Select Date <span>*</span>
            </label>

            <div className={"dateContainerManual"}>
              <ReactDatePicker
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
                customInput={
                  <CustomDatePicker className="example-custom-input" />
                }
              />
            </div>
          </Grid>
        </Grid>
        <div>
          <label className={styles.inputLabel} style={{ marginTop: "24px" }}>
            Billability <span>*</span>
          </label>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <AntSwitch
              checked={billability}
              inputProps={{ "aria-label": "ant design" }}
              onChange={handleBillability}
            />
            <label
              className={styles.switchLabel}
              style={{ marginLeft: "10px" }}
            >
              Billable{" "}
            </label>
            <span style={{ marginLeft: "5px", display: "flex" }}>
              {" "}
              {!billability ? <DollarIcon2 /> : <DollarIconFilled />}
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InfoCircleGray />
            <label className={styles.note}>
              Hours that can be charged to a client for work performed.
            </label>
          </div>
        </div>

        <div className={styles.btnContainer}>
          <CustomButton
            label={"Cancel"}
            buttonClass={styles.cancelBtn}
            onClick={() => onClose()}
          />
          <CustomButton
            label={"Add Time"}
            buttonClass={styles.addBtn}
            onClick={() => handleSubmit()}
          />
        </div>
      </Dialog>
    </React.Fragment>
  );
}
