import React from "react";
import styles from "./style.module.scss";
import { invited_status_enums } from "shared/utils/enum";

function GetStatus({ status }: any) {
  return (
    <div
      id={
        invited_status_enums.active === status
          ? styles.active
          : invited_status_enums.invited === status
          ? styles.invited
          : invited_status_enums.not_invited === status
          ? styles.not_invited
          : ""
      }
    >
      {invited_status_enums?.active === status ? (
        <span>Active</span>
      ) : invited_status_enums?.invited === status ? (
        <span>Invited</span>
      ) : invited_status_enums.not_invited === status ? (
        <span>Not Invited</span>
      ) : null}
    </div>
  );
}

export default GetStatus;
