import { DropDownIcon, StatusIcon } from "assets/icons";
import React from "react";
import CustomButton from "shared/components/customButton";
import styles from "./style.module.scss";
import { Menu, MenuItem } from "@mui/material";
import { status_enums } from "shared/utils/enum";
import GetStatus from "shared/components/getStatus";

function StatusFilter({ statusSelection, setStatusSelection, icon }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      label: "Planning",
      id: status_enums.planning,
    },
    {
      label: "Done",
      id: status_enums.done,
    },
    {
      label: "In Progress",
      id: status_enums.inprogress,
    },
    {
      label: "Paused",
      id: status_enums.paused,
    },
    {
      label: "Cancelled",
      id: status_enums.cancelled,
    },
  ];

  return (
    <div>
      <CustomButton
        label={
          statusSelection ? (
            <GetStatus status={statusSelection?.id} />
          ) : (
            "Status"
          )
        }
        IconRightSvg={icon ? StatusIcon : DropDownIcon}
        iconStyle={styles.icon}
        buttonClass={styles.btn}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid #e2e2e2",
            padding: "0px 10px",
          },
        }}
      >
        {menuItems?.map((item) => {
          return (
            <MenuItem
              onClick={() => {
                handleClose();
                setStatusSelection(item);
              }}
            >
              <GetStatus status={item?.id} />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

export default StatusFilter;
