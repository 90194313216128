import React from "react";
import styles from "pages/meetings/style.module.scss";
import ReactDatePicker from "react-datepicker";
import { ArrowLeftIcon, ArrowRightIcon } from "assets/icons";

function CalendarGrid({ startDate, setStartDate }: any) {
  const formatMonthYear = (date: any) => {
    return date.toLocaleDateString("en-US", { month: "long", year: "numeric" });
  };
  return (
    <div className={styles.infoContainer}>
      <label className={styles.heading}>Calendar</label>
      <label className={styles.subHeading}>
        Select a date to view your meetings.
      </label>
      <div className={"dateMeetingContainer"}>
        <ReactDatePicker
          renderCustomHeader={({
            date,

            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-2px",
              }}
            >
              <button
                className={styles.dateCalendarBtn}
                style={{ marginRight: "20px", justifyContent: "start" }}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <ArrowLeftIcon />
              </button>
              <label className={styles.monthLabel}>
                {" "}
                {formatMonthYear(date)}
              </label>
              <button
                style={{ marginLeft: "20px", justifyContent: "end" }}
                className={styles.dateCalendarBtn}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <ArrowRightIcon />
              </button>
            </div>
          )}
          selected={startDate}
          onChange={(date) => setStartDate(date as Date)}
          minDate={new Date()}
          inline
        />
      </div>
    </div>
  );
}

export default CalendarGrid;
