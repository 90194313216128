import React from "react";
import styles from "./style.module.scss";

interface NoContentCardProps {
  Icon: any;
  label: any;
  description: any;
}

function NoContentCard({ Icon, label, description }: NoContentCardProps) {
  return (
    <div className={styles.container}>
      <Icon />
      <div className={styles.label}>{label}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
}

export default NoContentCard;
