import {
  HidePasswordSvgIcon,
  ShowPasswordSvgIcon
} from "assets/icons";
import classNames from "classnames";
import { useState } from "react";
import styles from "./style.module.scss";

interface CustomInputProps {
  placeholder?: any;
  heading?: any;
  isRequired?: boolean;
  type: any;
  showEye?: any;
  value: any;
  onChange: any;
  error?: any;
  onKeyDown?: any;
  disabled?: boolean;
  stylesContainer?: any;
  inputId?: any;
}

function CustomInput({
  placeholder,
  heading,
  isRequired,
  type,
  showEye,
  value,
  onChange,
  error,
  onKeyDown,
  disabled,
  stylesContainer,
  inputId,
}: CustomInputProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      onKeyDown?.();
    }
  };
  return (
    <div style={stylesContainer ? stylesContainer : {}}>
      <label className={styles.label}>
        {heading} <span>{isRequired ? "*" : ""}</span>
      </label>
      <div style={{ position: "relative" }}>
        <input
          className={classNames(
            styles.input,
            error && styles.errorBorder,
            
          )}
          id={inputId && inputId}
          placeholder={placeholder ? placeholder : ""}
          type={showPassword ? "text" : type}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          disabled={disabled ? disabled : false}
        />
        {showEye ? (
          showPassword ? (
            <HidePasswordSvgIcon
              onClick={() => setShowPassword(!showPassword)}
              className={styles.passwordIcon}
            />
          ) : (
            <ShowPasswordSvgIcon
              onClick={() => setShowPassword(!showPassword)}
              className={styles.passwordIcon}
            />
          )
        ) : null}
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}

export default CustomInput;
