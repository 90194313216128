import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CustomInput from "shared/components/customInput";
import { useFormik } from "formik";
import { EditServiceValidation } from "shared/utils/validation";
import classNames from "classnames";
import { CrossIcon, PlusIcon } from "assets/icons";
import CustomButton from "shared/components/customButton";
import DoneIcon from "@mui/icons-material/Done";
import { DesignPNG, DevelopmentPNG } from "assets/images";
import styles from "pages/editService/style.module.scss";

interface InitialValues {
  title: string;
  icon: any;
  keyPoint1: string;
  keyPoint2: string;
  headline: string;
  CTA: string;
  amount: string;
  highlight1: string;
  highlight2: string;
  highlight3: string;
  highlight4: string;
  highlight5: string;
  highlight6: string;
  highlight7: string;
  highlight8: string;
}

function DesignService() {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<any>({
    raw: "",
    preview: "",
  });

  const initialValues: InitialValues = {
    title: "UI UX",
    icon: "",
    headline:
      "Fuel your design journey with the freedom to customize as per your needs.",
    keyPoint1: "Paid monthly.",
    keyPoint2: "Pause or cancel anytime.",
    CTA: "Subscribe Now",
    amount: "1500",
    highlight1: "Unlimited design requests(2 at a time)",
    highlight2: "Unlimited revisions to ensure perfection",
    highlight3: "Invite your team to submit requests",
    highlight4: "Fast delivery: 3-5 business days average",
    highlight5: "Up to 40 hours of dedicated design work",
    highlight6: "Add up to 2 projects simultaneously",
    highlight7: "Optimized designs for conversions",
    highlight8: "Purposeful designs for better UX",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: EditServiceValidation,
    onSubmit: (value) => {
      console.log("value", value);
    },
  });

  // Destructuring formik methods and values
  const { handleChange, handleSubmit, values, touched, errors, setFieldValue } =
    formik;

  const handleGetIcon = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setImage({
          preview: e.target.result as string,
          raw: file,
        });
        setFieldValue("icon", file);
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    setFieldValue("icon", DesignPNG);
    setImage({
      preview: DesignPNG,
      raw: "",
    });
    // eslint-disable-next-line
  }, []);


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={7.5}>
        <div className={styles.mainContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Title"}
                heading="Title"
                type={"text"}
                value={values.title}
                onChange={handleChange("title")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={touched.title && errors.title ? errors.title : ""}
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label className={styles.inputLabel}>Icon</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <div
                className={
                  touched.icon && errors.icon
                    ? classNames(styles.icon, styles.errorBorder)
                    : classNames(styles.icon)
                }
                onClick={handleGetIcon}
              >
                {image?.preview ? (
                  <>
                    <label>
                      {image?.raw?.name ? image?.raw?.name : image?.preview}
                    </label>
                    <CrossIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setFieldValue("icon", "");
                        setImage({
                          raw: "",
                          preview: "",
                        });
                      }}
                    />
                  </>
                ) : (
                  <>
                    <label>Choose File</label>
                    <PlusIcon />
                  </>
                )}
              </div>
              {touched.icon && errors.icon
                ? errors.icon && (
                    <div className={styles.error}>{String(errors.icon)}</div>
                  )
                : ""}
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                placeholder={"Enter Headline"}
                heading="Headline"
                type={"text"}
                value={values.headline}
                onChange={handleChange("headline")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.headline && errors.headline ? errors.headline : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Amount"}
                heading="Amount (USD)"
                type={"text"}
                value={values.amount}
                onChange={handleChange("amount")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={touched.amount && errors.amount ? errors.amount : ""}
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Key Point 01"}
                heading="Key Point 01"
                type={"text"}
                value={values.keyPoint1}
                onChange={handleChange("keyPoint1")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.keyPoint1 && errors.keyPoint1 ? errors.keyPoint1 : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Key Point 02"}
                heading="Key Point 02"
                type={"text"}
                value={values.keyPoint2}
                onChange={handleChange("keyPoint2")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.keyPoint2 && errors.keyPoint2 ? errors.keyPoint2 : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter CTA"}
                heading="CTA"
                type={"text"}
                value={values.CTA}
                onChange={handleChange("CTA")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={touched.CTA && errors.CTA ? errors.CTA : ""}
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Highlight"}
                heading="Highlight 01"
                type={"text"}
                value={values.highlight1}
                onChange={handleChange("highlight1")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.highlight1 && errors.highlight1
                    ? errors.highlight1
                    : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Highlight"}
                heading="Highlight 02"
                type={"text"}
                value={values.highlight2}
                onChange={handleChange("highlight2")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.highlight2 && errors.highlight2
                    ? errors.highlight2
                    : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Highlight"}
                heading="Highlight 03"
                type={"text"}
                value={values.highlight3}
                onChange={handleChange("highlight3")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.highlight3 && errors.highlight3
                    ? errors.highlight3
                    : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Highlight"}
                heading="Highlight 04"
                type={"text"}
                value={values.highlight4}
                onChange={handleChange("highlight4")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.highlight4 && errors.highlight4
                    ? errors.highlight4
                    : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Highlight"}
                heading="Highlight 05"
                type={"text"}
                value={values.highlight5}
                onChange={handleChange("highlight5")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.highlight5 && errors.highlight5
                    ? errors.highlight5
                    : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Highlight"}
                heading="Highlight 06"
                type={"text"}
                value={values.highlight6}
                onChange={handleChange("highlight6")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.highlight6 && errors.highlight6
                    ? errors.highlight6
                    : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Highlight"}
                heading="Highlight 07"
                type={"text"}
                value={values.highlight7}
                onChange={handleChange("highlight7")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.highlight7 && errors.highlight7
                    ? errors.highlight7
                    : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Highlight"}
                heading="Highlight 08"
                type={"text"}
                value={values.highlight8}
                onChange={handleChange("highlight8")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.highlight8 && errors.highlight8
                    ? errors.highlight8
                    : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
          </Grid>
          <div className={styles.btnContainer}>
            <CustomButton
              label={"Update Service"}
              buttonClass={styles.updateBtn}
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={4.5}>
        <div
          className={classNames(
            styles.mainContainer,
            styles.serviceContainerTopLevel
          )}
        >
          <div className={styles.singleServiceContainer}>
            <div className={styles.preview}>
              <label>Preview</label>
            </div>
            <div style={{ padding: "16px" }}>
              <div className={styles.topServiceContainer}>
                <div className={styles.serviceHeadingContainer}>
                  <label className={styles.serviceName}>{values?.title}</label>
                  <div className={styles.serviceDescription}>
                    {values?.headline}
                  </div>
                </div>
                {image?.preview && (
                  <img
                    src={image?.preview}
                    alt="development"
                    className={styles.iconService}
                  />
                )}{" "}
              </div>
              <div>
                <label className={styles.serviceAmount}>
                  ${values?.amount}
                  <label>/month</label>
                </label>
              </div>
              <div className={styles.serviceSubHeadingContainer}>
                <label className={styles.serviceSubHeading}>
                  {values?.keyPoint1}
                </label>
                <label className={styles.serviceSubHeading}>
                  {values?.keyPoint2}
                </label>
              </div>
              <CustomButton label={values?.CTA} buttonClass={styles.btn} />
              <div className={styles.pointContainer}>
                <div className={styles.singleServicePoint}>
                  <DoneIcon
                    sx={{
                      color: "#808080",
                      fontSize: "16px",
                      marginRight: "6px",
                    }}
                  />
                  {values?.highlight1}
                </div>
                <div className={styles.singleServicePoint}>
                  <DoneIcon
                    sx={{
                      color: "#808080",
                      fontSize: "16px",
                      marginRight: "6px",
                    }}
                  />
                  {values?.highlight2}
                </div>
                <div className={styles.singleServicePoint}>
                  <DoneIcon
                    sx={{
                      color: "#808080",
                      fontSize: "16px",
                      marginRight: "6px",
                    }}
                  />
                  {values?.highlight3}
                </div>
                <div className={styles.singleServicePoint}>
                  <DoneIcon
                    sx={{
                      color: "#808080",
                      fontSize: "16px",
                      marginRight: "6px",
                    }}
                  />
                  {values?.highlight4}
                </div>
                <div className={styles.singleServicePoint}>
                  <DoneIcon
                    sx={{
                      color: "#808080",
                      fontSize: "16px",
                      marginRight: "6px",
                    }}
                  />
                  {values?.highlight5}
                </div>
                <div className={styles.singleServicePoint}>
                  <DoneIcon
                    sx={{
                      color: "#808080",
                      fontSize: "16px",
                      marginRight: "6px",
                    }}
                  />
                  {values?.highlight6}
                </div>
                <div className={styles.singleServicePoint}>
                  <DoneIcon
                    sx={{
                      color: "#808080",
                      fontSize: "16px",
                      marginRight: "6px",
                    }}
                  />
                  {values?.highlight7}
                </div>
                <div className={styles.singleServicePoint}>
                  <DoneIcon
                    sx={{
                      color: "#808080",
                      fontSize: "16px",
                      marginRight: "6px",
                    }}
                  />
                  {values?.highlight8}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default DesignService;
