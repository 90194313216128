import React, { useState } from "react";
import styles from "./style.module.scss";
import { MarkedIcon } from "assets/icons";
import { Box, Tab, Tabs } from "@mui/material";
import classNames from "classnames";
import { Sample1, Sample2, Sample3, Sample4 } from "assets/sample";

function Notifcations() {
  const [value, setValue] = useState<any>(0);

  const tabs = [
    {
      value: 0,
      label: "All",
    },
    {
      value: 1,
      label: "Unread",
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const dummy = [
    {
      label: "Robert Anderson joined Fatfish",
      users: ["Robert Anderson"],
      project: "Fatfish",
      img: Sample1,
    },
    {
      label: "Umair Ashraf added a comment to Fatfish",
      users: ["Umair Ashraf"],
      project: "Fatfish",
      comment: "Alright thanks i got the files",
      img: Sample2,
    },
    {
      label: "Ahkam Khan was removed from Fatfish",
      users: ["Ahkam Khan"],
      project: "Fatfish",
      img: Sample3,
    },
    {
      label: "Zoheb Hassan added New Employee Ahkam Khan",
      users: ["Zoheb Hassan", "Ahkam Khan"],
      project: "Fatfish",
      comment: "Alright thanks i got the files",
      img: Sample4,
    },
    {
      label: "Ali Hassan purchased UI UX subscription",
      users: ["Ali Hassan"],
      project: "Fatfish",
      img: Sample1,
    },
    {
      label: "Robert Anderson joined Fatfish",
      users: ["Robert Anderson"],
      project: "Fatfish",
      img: Sample1,
    },
    {
      label: "Umair Ashraf added a comment to Fatfish",
      users: ["Umair Ashraf"],
      project: "Fatfish",
      comment: "Alright thanks i got the files",
      img: Sample2,
    },
    {
      label: "Ahkam Khan was removed from Fatfish",
      users: ["Ahkam Khan"],
      project: "Fatfish",
      img: Sample3,
    },
    {
      label: "Zoheb Hassan added New Employee Ahkam Khan",
      users: ["Zoheb Hassan", "Ahkam Khan"],
      project: "Fatfish",
      comment: "Alright thanks i got the files",
      img: Sample4,
    },
    {
      label: "Ali Hassan purchased UI UX subscription",
      users: ["Ali Hassan"],
      project: "Fatfish",
      img: Sample1,
    },
  ];

  const highlightLabel = (item: any) => {
    let words: string[] = [];

    item?.label?.split(" ").forEach((word: string) => {
      let highlighted = false;

      // Check if the word matches any user
      item?.users?.map((user: string) => {
        if (word?.length > 1 && user.includes(word)) {
          words.push(
            `<span style="color: #2E2E2E; font-weight: 600">${word}</span>`
          );
          highlighted = true;
        }
      });

      // Check if the word matches the project
      if (item?.project === word) {
        words.push(
          `<span style="color: #2E2E2E; font-weight: 600">${word}</span>`
        );
        highlighted = true;
      }

      // If the word is not highlighted, add it as it is
      if (!highlighted) {
        words.push(word);
      }
    });

    return words.join(" ");
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.markedAllLabel}>
        <MarkedIcon />
        Mark all as read
      </div>

      <div className={styles.notificationContainer}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            className={styles.tab}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#FF2626",
              },
            }}
          >
            {tabs?.map((item: any, index: any) => {
              return (
                <Tab
                  key={index}
                  label={item?.label}
                  className={classNames(
                    styles.labelTab,
                    value === item?.value && styles.active
                  )}
                />
              );
            })}
          </Tabs>
        </Box>

        {dummy?.map((item, index) => {
          return (
            <div key={index} className={styles.singleContainer}>
              <img src={item?.img} className={styles.img} />
              <div>
                <div
                  className={classNames(styles.label, styles.mt)}
                  dangerouslySetInnerHTML={{ __html: highlightLabel(item) }}
                />
                <div className={classNames(styles.label, styles.mt)}>
                  Just Now
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Notifcations;
