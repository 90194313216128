import Grid from "@mui/material/Grid";
import { DoneIcon, GoogleIcon } from "assets/icons";
import { AuthBanner, FullImageLogoSvg } from "assets/images";
import classnames from "classnames";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IResolveParams, LoginSocialGoogle } from "reactjs-social-login";
import CustomButton from "shared/components/customButton";
import CheckBox from "shared/components/customCheckbox";
import CustomInput from "shared/components/customInput";
import Footer from "shared/components/footer";
import BookAnIntro from "shared/modals/bookAnIntro";
import ConfirmationModal from "shared/modals/confirmation";
import { setUser } from "shared/redux/reducers/userSlice";
import { routeConstant } from "shared/routes/routesConstant";
import { LoginValidation } from "shared/utils/validation";
import styles from "./style.module.scss";

// Interface for initial form values
interface InitialValues {
  email: string;
  password: string;
}

function Login() {
  // For Navigation
  const navigate = useNavigate();
  // For dispatching user
  const dispatch = useDispatch();

  /* ------------------------------------State variables------------------------------------- */
  const [checkBox, setCheckbox] = useState<boolean>(false);
  // eslint-disable-next-line
  const [googleLoading, setGoogleloading] = useState<boolean>(false);
  const [openBooking, setOpenBooking] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  /* ---------------------------------------------------------------------------------------- */

  // Initial form values
  const initialValues: InitialValues = {
    email: "",
    password: "",
  };

  /* ------------------------------------Formik handling------------------------------------- */

  // Formik hook for form handling
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: LoginValidation,
    onSubmit: (value) => {
      let obj: any = {
        user_info: { email: value?.email },
        isLoggedIn: true,
        role: { role_id: 1 },
      };

      dispatch(setUser(obj));
    },
  });

  // Destructuring formik methods and values
  const { handleChange, handleSubmit, values, touched, errors } = formik;

  /* ---------------------------------------------------------------------------------------- */

  // Callback function for social login success
  const onSuccess = (data: any, provider: any) => {
    console.log("data", data);
    console.log("provider", provider);
  };

  useEffect(() => {
    document.title = "Login |  Web Pioneer";
  }, []);

  return (
    <div className={styles.background}>
      <Grid container className={styles.outerMostGrid}>
        <Grid item xs={12} md={7} className={styles.welcomeGrid}>
          <div>
            {/* <img src={FullImageLogo} alt="Logo" className={styles.logo} /> */}
            <FullImageLogoSvg />
            <div className={styles.welcomeLabelContainer}>
              <div className={styles.welcomeLabel}>Welcome to </div>
              <div className={styles.welcomeLabel}>Web Pioneers.</div>
              <div className={styles.welcomeDescription}>
                Let's get you logged in first.
              </div>
            </div>
            <div className={styles.bannerContainer}>
              <AuthBanner className={styles.authBanner} />
            </div>
          </div>
        </Grid>

        {/* ------------------------------------Login Grid------------------------------------- */}

        <Grid
          item
          xs={12}
          md={5}
          display={"flex"}
          alignItems={"center"}
          className={styles.formContainer}
        >
          <div className={classnames(styles.authContainer)}>
            <h1 className={styles.heading}>Log In</h1>
            <div className={styles.inputContainer}>
              <CustomInput
                placeholder={"Enter your email..."}
                heading="Email Address"
                isRequired
                type={"text"}
                value={values.email}
                onChange={handleChange("email")}
                onKeyDown={handleSubmit}
                error={touched.email && errors.email ? errors.email : ""}
              />
              <CustomInput
                placeholder={"Enter your password..."}
                heading="Password"
                isRequired
                type={"password"}
                showEye
                value={values.password}
                onChange={handleChange("password")}
                onKeyDown={handleSubmit}
                error={
                  touched.password && errors.password ? errors.password : ""
                }
              />
            </div>
            <div className={styles.actionContainer}>
              <div className={styles.rememberMeSection}>
                <CheckBox
                  checkBox={checkBox}
                  handleCallback={() => setCheckbox(!checkBox)}
                />
                <label
                  className={styles.actionLabel}
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  onClick={() => setCheckbox(!checkBox)}
                >
                  Remember Me
                </label>
              </div>
              <div onClick={() => navigate(routeConstant.forgetPassword.path)}>
                <label
                  className={styles.actionLabel}
                  style={{ cursor: "pointer" }}
                >
                  Forgot Password
                </label>
              </div>
            </div>
            <CustomButton
              label="Login"
              containerClass={styles.loginBtn}
              labelStyle={{ cursor: "pointer" }}
              onClick={() => {
                handleSubmit();
              }}
            />
            <div className={styles.linesContainer}>
              <div className={styles.lines} />
              <div className={styles.loginLabel}>Or login with</div>
              <div className={styles.lines} />
            </div>
            <LoginSocialGoogle
              client_id={
                "620806407500-e5rn11mb39hq1km6eire8ftagellhrfm.apps.googleusercontent.com"
              }
              scope="openid profile email"
              discoveryDocs="claims_supported"
              cookie_policy="single_host_origin"
              onResolve={({ provider, data }: IResolveParams) => {
                onSuccess(data, provider);
              }}
              className="mb-3"
              onReject={(err) => {
                setGoogleloading(false);
              }}
            >
              <CustomButton
                label="Google"
                buttonClass={styles.googleBtn}
                labelStyle={{ marginLeft: "10px", cursor: "pointer" }}
                // iconLeft={GoogleIcons}
                iconStyle={styles.googleIcon}
                IconLeftSvg={GoogleIcon}
              />
            </LoginSocialGoogle>

            <label className={styles.accountCreateLabel}>
              You're new in here?{" "}
              <span onClick={() => navigate(routeConstant.signUp.path)}>
                Create Account
              </span>
            </label>

            <CustomButton
              label="Book an Intro Call"
              buttonClass={styles.introBtn}
              onClick={() => setOpenBooking(true)}
            />
          </div>
        </Grid>
      </Grid>
      <Footer />

      <BookAnIntro
        open={openBooking}
        setOpenConfirmation={setOpenConfirmation}
        handleClose={() => {
          setOpenBooking(false);
        }}
      />

      <ConfirmationModal
        open={openConfirmation}
        handleClose={() => {
          setOpenConfirmation(false);
        }}
        Icon={DoneIcon}
        label={"Meeting Booked Successfully!"}
        subLabel={`Your meeting confirmation and Google Meet link will be sent to your email shortly.`}
        actions={[
          {
            label: "Close",
            action: () => {
              setOpenConfirmation(false);
            },
            style: styles.close,
          },
        ]}
      />
    </div>
  );
}

export default Login;
