import { Grid } from "@mui/material";
import { useState } from "react";
import BackButton from "shared/components/backButton";
import MenuOptions from "shared/components/pages/channel/menuOptions";
import RequestCard from "shared/components/pages/channel/requestCard";
import { routeConstant } from "shared/routes/routesConstant";
import styles from "./style.module.scss";
import { Sample1, Sample2, Sample3, Sample4 } from "assets/sample";
import NoContentCard from "shared/components/noContentCard";
import { FoldernOpen } from "assets/icons";
import { toastMessage } from "shared/components/toast";

const dummy = [
  {
    id: 1,
    time: new Date(),
    status: 1,
    user: {
      name: "Katherine",
      profile_picture: Sample1,
    },
    message:
      "I understand that you looking for a preview of the homepage. Here is the pdf file.",
    file: {
      name: "Home",
      type: "pdf",
      size: "25MB",
    },
  },
  {
    id: 2,
    time: new Date(),
    status: 1,
    user: {
      name: "Jake Doe",
      profile_picture: Sample2,
    },
    message:
      "Just some small feedback from my side, before I show it to the client.",
  },
  {
    id: 3,
    time: new Date(),
    status: 1,
    user: {
      name: "Janet",
      profile_picture: Sample4,
    },
    message:
      "Here are all the changes you asked for in documentation, have a look.",
    file: {
      name: "Feedback",
      type: "docx",
      size: "9MB",
    },
  },
  {
    id: 4,
    time: new Date(),
    status: 1,
    user: {
      name: "Kate",
      profile_picture: Sample3,
    },
    message: "I read all your points, and will work on it soon.",
  },
];

function Projects() {
  const [allRequest, setAllRequests] = useState<any>(dummy);

  const handleApprove = (item: any) => {
    const index = allRequest.findIndex((itm: any) => itm.id === item.id);

    if (index !== -1) {
      const updatedItem = { ...item, status: 0 };
      const updatedRequests = [...allRequest];
      updatedRequests[index] = updatedItem;
      setAllRequests(updatedRequests);
      toastMessage("success", "Request approved successfully.");
    }
  };

  const handleDelete = (item: any) => {
    const updated = allRequest?.filter((itm: any) => itm.id !== item?.id);
    setAllRequests(updated);
    toastMessage("success", "Request deleted successfully.");
  };

  const handleEdit = (item: any, editMsg: any) => {
    const index = allRequest.findIndex((itm: any) => itm.id === item.id);

    if (index !== -1) {
      const updatedItem = { ...item, isEdit: 1, message: editMsg };
      const updatedRequests = [...allRequest];
      updatedRequests[index] = updatedItem;
      setAllRequests(updatedRequests);
      toastMessage("success", "Request edited successfully.");
    }
  };

  const handleDeleteAll = () => {
    let temp = allRequest?.filter((item: any) => item?.status !== 1);
    setAllRequests(temp);
    toastMessage("success", "All requests deleted successfully.");
  };

  const handleApproveAll = () => {
    let temp: any = [];
    allRequest?.map((itm: any) => temp.push({ ...itm, status: 0 }));
    setAllRequests(temp);
    toastMessage("success", "All requests approved successfully.");
  };

  const handleRemoveFile = (item: any) => {
    const index = allRequest.findIndex((itm: any) => itm.id === item.id);

    if (index !== -1) {
      const updatedItem = { ...item, file: null };
      const updatedRequests = [...allRequest];
      updatedRequests[index] = updatedItem;
      setAllRequests(updatedRequests);
      toastMessage("success", "File removed successfully.");
    }
  };

  return (
    <>
      <BackButton
        label={"Message Requests"}
        navigate={routeConstant.channels.path}
      />
      <div className={styles.mainContainer}>
        <div className={styles.gridContainer}>
          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={12} lg={6} className={styles.pendingContainer}>
              <div className={styles.headingContainer}>
                <div className={styles.pendingHeading}>
                  <span style={{ marginRight: "5px", color: "#5B97BD" }}>
                    •
                  </span>
                  Pending
                </div>
                <div>
                  <MenuOptions
                    handleDeleteAll={handleDeleteAll}
                    handleApproveAll={handleApproveAll}
                  />
                </div>
              </div>

              <div className={styles.requestContainer}>
                {allRequest.filter((item: any) => item?.status === 1)
                  ?.length === 0 ? (
                  <NoContentCard
                    Icon={FoldernOpen}
                    label="No Pending Approvals!"
                    description={"There are no more pending approvals."}
                  />
                ) : (
                  allRequest
                    .filter((item: any) => item?.status === 1)
                    ?.map((itm: any) => {
                      return (
                        <RequestCard
                          item={itm}
                          handleApprove={handleApprove}
                          handleDelete={handleDelete}
                          handleEdit={handleEdit}
                          handleRemoveFile={handleRemoveFile}
                        />
                      );
                    })
                )}
              </div>
            </Grid>
            <Grid item xs={12} lg={6} className={styles.approvedContainer}>
              <div className={styles.headingContainer}>
                <div className={styles.approvedHeading}>
                  <span style={{ marginRight: "5px", color: "#6C9B7D" }}>
                    •
                  </span>
                  Approved
                </div>
                <div />
              </div>
              <div className={styles.requestContainer}>
                {allRequest.filter((item: any) => item?.status === 0)
                  ?.length === 0 ? (
                  <NoContentCard
                    Icon={FoldernOpen}
                    label="No Approved Requests!"
                    description={"There are no more approved requests."}
                  />
                ) : (
                  allRequest
                    .filter((item: any) => item?.status === 0)
                    ?.map((itm: any) => {
                      return <RequestCard item={itm} isApproved={true} />;
                    })
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Projects;
