import { Box, Skeleton } from "@mui/material";
import React from "react";

function ChatListRowLoader() {
  return (
    <>
      {Array.from(Array(3).keys()).map((itm, inx) => (
        <Box display={"flex"} key={inx} marginBottom={"10px"} padding={"10px"}>
          <Skeleton variant="circular" width={46} height={46} />
          <Box marginLeft={"10px"}>
            <Skeleton variant="rounded" width={120} height={20} />
            <Skeleton
              variant="rounded"
              width={150}
              height={20}
              style={{ marginTop: "10px" }}
            />
          </Box>
        </Box>
      ))}
    </>
  );
}

export default ChatListRowLoader;
