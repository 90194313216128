import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon2,
  DropDownIcon,
} from "assets/icons";
import React, { forwardRef } from "react";
import CustomButton from "shared/components/customButton";
import styles from "./style.module.scss";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getYear, getMonth } from "date-fns"; // Importing from date-fns

interface DateFilterProps {
  dueDateSelection: Date | null;
  setDueDateSelection: (date: Date | null) => void;
  icon?: any;
}

// Define a custom range function
const range = (start: number, end: number, step = 1): number[] => {
  let output = [];
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};

const DateFilter: React.FC<DateFilterProps> = ({
  dueDateSelection,
  setDueDateSelection,
  icon,
}) => {
  const ExampleCustomInput = forwardRef<HTMLButtonElement, any>(
    ({ value, onClick }, ref) => (
      <button className={styles.customButton} onClick={onClick} ref={ref}>
        {dueDateSelection
          ? moment(dueDateSelection).format("ddd, DD MMMM, YYYY")
          : "Due Date"}
        {icon ? (
          <CalendarIcon2 className={styles.icon} />
        ) : (
          <DropDownIcon className={styles.icon} />
        )}
      </button>
    )
  );

  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div>
      <ReactDatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{ margin: 10, display: "flex", justifyContent: "center" }}
          >
            <button
              className={styles.calendarBtn}
              style={{ marginRight: "20px" }}
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <ArrowLeftIcon />
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(Number(value))}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <button
              style={{ marginLeft: "20px" }}
              className={styles.calendarBtn}
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <ArrowRightIcon />
            </button>
          </div>
        )}
        selected={dueDateSelection}
        onChange={(date) => setDueDateSelection(date as Date)}
        customInput={<ExampleCustomInput />}
        maxDate={new Date()}
      />
    </div>
  );
};

export default DateFilter;
