import React from "react";
import AddTimeTracker from "./addTimeTracker";
import AddMembers from "./addMembers";
import AddStatus from "./addStatus";
import styles from "./style.module.scss";

function AddDetails() {
  return (
    <div>
      <div className={styles.timeContainer}>
        <AddTimeTracker />
      </div>
      <div className={styles.membersContainer}>
        <AddMembers />
      </div>
      <div className={styles.statusContainer}>
        <AddStatus />
      </div>
    </div>
  );
}

export default AddDetails;
