import React from "react";
import styles from "./style.module.scss";
import { invoice_status_enums, status_enums } from "shared/utils/enum";

function PayStatus({ status }: any) {
  return (
    <div
      id={
        invoice_status_enums.paid === status
          ? styles.paid
          : invoice_status_enums.withdrawn === status
          ? styles.withdrawn
          : invoice_status_enums.cancelled === status
          ? styles.cancelled
          : invoice_status_enums?.unpaid === status
          ? styles.cancelled
          : ""
      }
    >
      {invoice_status_enums?.paid === status ? (
        <span>• Paid</span>
      ) : invoice_status_enums?.withdrawn === status ? (
        <span>• Withdrawn</span>
      ) : invoice_status_enums.cancelled === status ? (
        <span>• Cancelled</span>
      ) : invoice_status_enums.unpaid === status ? (
        <span>• Paused</span>
      ) : null}
    </div>
  );
}

export default PayStatus;
