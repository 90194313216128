import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { CrossIcon, DoneIcon } from "assets/icons";
import  React, {useState} from "react";
import CustomButton from "shared/components/customButton";
import styles from "./style.module.scss";
import useWindowSize from "shared/customHooks/useWindowSize";
import classNames from "classnames";
import CustomInput from "shared/components/customInput";
import { useFormik } from "formik";
import { SingleEmailValidation } from "shared/utils/validation";
import { toastMessage } from "shared/components/toast";

// Interface for initial form values
interface InitialValues {
  email: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SendInviteProps {
  open: boolean;
  handleClose: any;
  name: any;
  role: any;
  email: any;
}

export default function SentInvite({
  open,
  handleClose,
  name,
  role,
  email,
}: SendInviteProps) {
  const width = useWindowSize();
  const isLargeScreen = width > 1530;
  const [inviteLoader, setInviteLoader] = useState<boolean>(false);


  const initialValues: InitialValues = {
    email: email ? email : "",
  };

  /* ------------------------------------Formik handling------------------------------------- */

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: SingleEmailValidation,
    onSubmit: (value) => {
      handleInvite(value?.email);
    },
  });

  const handleInvite = (email: any) => {
    setInviteLoader(true);
    setTimeout(() => {
      setInviteLoader(false);
      toastMessage("success", `${email} has been invited.`);
      formik.resetForm();
    }, 2000);
  };

  const { handleChange, handleSubmit, values, touched, errors, setFieldValue } =
    formik;

  /* ---------------------------------------------------------------------------------------- */

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            padding: "24px",
            width: "100%",
            justifyContent: "center",
            maxWidth: (theme) => theme.spacing(75),
            borderRadius: "10px",
          },
        }}
      >
        <CrossIcon className={styles.crossIcon} onClick={() => handleClose()} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <DoneIcon />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <label
            className={classNames(styles.label, {
              [styles.BiggerScreenLabel]: isLargeScreen,
            })}
          >
            {name} Added Successfully{" "}
          </label>
          <label
            className={classNames(styles.subLabel, {
              [styles.BiggerScreenSubLabel]: isLargeScreen,
            })}
          >
            Share the invitation link with {name?.split(" ")[0]}
          </label>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "8px",
          }}
        >
          <div className={styles.tagContainer}>
            <label className={styles.labelTag}>{role}</label>
          </div>
        </div>

        <div className={styles.actionContainer}>
          <CustomInput
            type="email"
            placeholder={"Justintrevor@gmail.com"}
            heading=""
            value={values.email}
            onChange={handleChange("email")}
            error={touched.email && errors.email ? errors.email : ""}
            onKeyDown={handleSubmit}
            inputId={styles.inputEmail}
            stylesContainer={{ width: "100%" }}
          />
          <CustomButton
            label={"Send Invite Via Email"}
            buttonClass={styles.btn}
            onClick={() => handleSubmit()}
            loadingStyle={{ color: "white", width: "20px !important", height: "20px !important" }}
            loading={inviteLoader}
          />
        </div>
      </Dialog>
    </React.Fragment>
  );
}
