import { Grid } from "@mui/material";
import React from "react";
import styles from "./style.module.scss";
import AddProjectTab from "shared/components/pages/dashboard/addProjectTab";
import WelcomeTab from "shared/components/pages/dashboard/welcomeTab";
import MessagesTab from "shared/components/pages/dashboard/messagesTab";
import StatsTab from "shared/components/pages/dashboard/statsTab";


function Dashboard() {
  return (
    <div className={styles.mainContainer}>
      <Grid container className={styles.mainGrid}>
        <Grid item sm={12} md={8} width={"100%"} className={styles.rightGrid}>
          <WelcomeTab />
          <StatsTab/>
        </Grid>
        <Grid item sm={12} md={4} width={"100%"} className={styles.leftGrid}>
          <AddProjectTab />
          <MessagesTab />
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;
