import React, { useState } from "react";
import styles from "./style.module.scss";
import { Box, Tab, Tabs } from "@mui/material";
import classNames from "classnames";
import GeneralSettings from "../generalSettings";
import BillingsSettings from "../billingsSettings";
import ClientProject from "../clientProject";
import ReviewSettings from "../reviewsSettings";

function ClientSettings() {
  const [activeTab, setActiveTab] = useState<any>(0);
  const [showTab, setShowTab] = useState<boolean>(false);
  const tabs = [
    {
      value: 0,
      label: "General Info",
    },
    {
      value: 1,
      label: "Billing Overview",
    },
    {
      value: 2,
      label: "Projects",
    },
    {
      value: 3,
      label: "Reviews",
    },
  ];

  const activeTabEnums = {
    general: 0,
    billing: 1,
    projects: 2,
    reviews: 3,
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <div className={styles.mainContainer}>
      {!showTab && (
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className={styles.tabsContainer}
        >
          <Tabs
            value={activeTab}
            onChange={handleChange}
            className={styles.tab}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#FF2626",
              },
            }}
          >
            {tabs?.map((item: any, index: any) => {
              return (
                <Tab
                  key={index}
                  label={item?.label}
                  className={classNames(
                    styles.labelTab,
                    activeTab === item?.value && styles.active
                  )}
                />
              );
            })}
          </Tabs>
        </Box>
      )}

      <div>
        {activeTab === activeTabEnums.general ? (
          <GeneralSettings />
        ) : activeTab === activeTabEnums.billing ? (
          <BillingsSettings setShowTab={setShowTab} />
        ) : activeTab === activeTabEnums.projects ? (
          <ClientProject />
        ) : activeTab === activeTabEnums.reviews ? (
          <ReviewSettings />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ClientSettings;
