import { Sample1, Sample2 } from "assets/sample";
import React, { useRef, useState } from "react";
import styles from "./style.module.scss";
import {
  AccountIcon,
  CloseCircleIcon,
  DeleteIcon,
  DropDownIcon,
  EmailIcon,
  MemberTableIcon,
  PhoneIcon,
  PlusCrossIcon,
  WarningIcon,
} from "assets/icons";
import CustomButton from "../customButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ConfirmationModal from "shared/modals/confirmation";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import { toastMessage } from "../toast";

function ProfileCard() {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<any>({
    raw: "",
    preview: "",
  });

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageChange = (e: any) => {
    let url = URL.createObjectURL(e.target.files[0]);
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setImage({
          preview: e.target.result as string,
          raw: file,
        });
      };

      reader.readAsDataURL(file);
    }
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.imgContainer}>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <img
          src={image?.preview ? image?.preview : Sample1}
          alt="profile_picture"
          className={styles.profilePic}
        />
        <div className={styles.plusIconContainer} onClick={handleButtonClick}>
          <PlusCrossIcon />
        </div>
      </div>
      <div className={styles.headingContainer}>
        <label className={styles.nameHeading}>Elisa Feliz</label>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "8px",
        }}
      >
        <div className={styles.tagContainer}>
          <label className={styles.labelTag}>Active</label>
        </div>
      </div>

      <div className={styles.infoContainer}>
        <div className={styles.singleInfoContainer}>
          <MemberTableIcon />
          <label className={styles.infoHeading}>CLT2204</label>
        </div>
        <div className={styles.singleInfoContainer}>
          <EmailIcon />
          <label className={styles.infoHeading}>Elisafeliz@gmail.com</label>
        </div>
        <div className={styles.singleInfoContainer}>
          <PhoneIcon />
          <label className={styles.infoHeading}>+923054063572</label>
        </div>
        <div className={styles.singleInfoContainer}>
          <AccountIcon />
          <label className={styles.infoHeading}>Client</label>
        </div>
      </div>
      <div className={styles.infoContainer3}>
        <div className={styles.infoheading2}>Company</div>
        <div className={styles.info} style={{ marginTop: "8px" }}>
          Fatfish
        </div>
      </div>
      <div className={styles.infoContainer3}>
        <div className={styles.infoheading2}>Added by</div>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "8px" }}
        >
          <img src={Sample2} className={styles.addedProfilePic} />{" "}
          <label className={styles.info} style={{ marginLeft: "10px" }}>
            Zoheb Hassan
          </label>
        </div>
      </div>
      <div>
        <ActionContainer />
      </div>
    </div>
  );
}

export default ProfileCard;

function ActionContainer() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deactivateOpen, setDeactivateOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <CustomButton
          label={"Action"}
          containerClass={styles.buttonClassContainer}
          buttonClass={open ? styles.invertBtn : styles.btn}
          IconRightSvg={DropDownIcon}
        />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid #e2e2e2",
            padding: "0px 10px",
            borderRadius: "10px",
          },
        }}
      >
        <MenuItem 
        onClick={() => {
          setDeactivateOpen(true);
          handleClose();
        }}>
          <CloseCircleIcon />
          <span
            style={{ marginLeft: "10px", color: "#3D3D3D" }}
            className={styles.menuItem}
          >
            Deactivate
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteOpen(true);
            handleClose();
          }}
        >
          <DeleteIcon />
          <span
            style={{ marginLeft: "10px", color: "red" }}
            className={styles.menuItem}
          >
            Delete
          </span>
        </MenuItem>
      </Menu>

      <ConfirmationModal
        open={deleteOpen || deactivateOpen}
        handleClose={() => {
          setDeleteOpen(false);
          setDeactivateOpen(false);
        }}
        Icon={WarningIcon}
        label={"Confirm Action"}
        subLabel={`Are you sure you want to ${
          deleteOpen ? "delete" : deactivateOpen ? "deactivate" : ""
        }?"`}
        actions={[
          {
            label: "Cancel",
            action: () => {
              {
                setDeleteOpen(false);
                setDeactivateOpen(false);
              }
            },
            style: styles.cancel,
          },
          {
            label: `Yes, ${deleteOpen ? "Delete" : "Deactivate"}.`,
            action: () => {
              if (deleteOpen) {
                navigate(routeConstant.clients.path);
                toastMessage("success", "User Deleted.");
              } else {
                navigate(routeConstant.clients.path);
                toastMessage("success", "User Deactivated.");
              }

            },
            style: styles.confirm,
          },
        ]}
      />
    </div>
  );
}
