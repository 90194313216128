import Menu from "@mui/material/Menu";
import { EmojiIcon } from "assets/icons";
import Picker from "emoji-picker-react";
import React, { useState } from "react";
import styles from "./style.module.scss";

interface EmojiPickerProps {
  handleCallback: any;
}

export default function EmojiPicker({ handleCallback }: EmojiPickerProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEmojiClick = (event: any, emojiObject: any) => {
    handleCallback(event?.emoji);
    handleClose();
  };

  return (
    <div>
      <button
        id="emoji-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={styles.btn}
      >
        <EmojiIcon />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "emoji-button",
          sx: {
            padding: "0px",
          },
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            borderRight: "1px solid #e2e2e2",
            padding: "0px",
          },
        }}
      >
        <div>
          <Picker style={{ width: "100%" }} onEmojiClick={onEmojiClick} />
        </div>
      </Menu>
    </div>
  );
}
