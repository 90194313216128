import { Box, Dialog, Slide } from "@mui/material";
import React from "react";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";
import useWindowSize from "shared/customHooks/useWindowSize";
import classNames from "classnames";
import { TransitionProps } from "@mui/material/transitions";

// Create a transition component using the Slide animation from Material UI
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Define the interface for the props that the ConfirmationModal component will accept
interface ConfirmationProps {
  open: boolean; // Whether the modal is open or not
  handleClose: () => void; // Function to handle closing the modal
  Icon?: React.ElementType; // Optional icon component to display
  label?: string; // Optional label text to display
  subLabel?: string; // Optional sub-label text to display
  actions?: { label: string; style: string; action: () => void }[]; // Optional actions to display as buttons
}

// Define the ConfirmationModal functional component
const ConfirmationModal: React.FC<ConfirmationProps> = ({
  open, // Whether the modal is open
  handleClose, // Function to handle closing the modal
  Icon, // Optional icon component
  label, // Optional label text
  subLabel, // Optional sub-label text
  actions, // Optional actions as buttons
}) => {
  const width = useWindowSize(); // Get the current window width using a custom hook
  const isLargeScreen = width > 1530; // Determine if the screen is large based on width

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      PaperProps={{
        sx: {
          padding: "24px",
          maxWidth: isLargeScreen ? `${400 * (width / 1000)}px` : "546px",
          width: "100%",
          height: isLargeScreen ? `${150 * (width / 1000)}px` : "180px",
          justifyContent: "center",
          borderRadius:"10px"
        },
      }}
    >
      <Box display="flex" justifyContent="center">
        {Icon && <Icon className={isLargeScreen ? styles.icon : undefined} />}
      </Box>
      <Box display="flex" justifyContent="center">
        {label && (
          <label className={classNames(styles.label, { [styles.BiggerScreenLabel]: isLargeScreen })}>
            {label}
          </label>
        )}
      </Box>
      {subLabel && (
        <label className={classNames(styles.subLabel, { [styles.BiggerScreenSubLabel]: isLargeScreen })}>
          {subLabel}
        </label>
      )}
      <div className={styles.buttonContainer}>
        {actions?.map((item, index) => (
          <CustomButton
            key={index}
            label={item.label}
            buttonClass={item.style}
            onClick={item.action}
          />
        ))}
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
