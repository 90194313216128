import React, { useState } from "react";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";
import ServiceFilter from "shared/components/filters/serviceFilter";
import StatusFilter from "shared/components/filters/statusFilter";
import DateFilter from "shared/components/filters/dateFilter";
import PriorityFilter from "shared/components/filters/priorityFilter";

function AddTasks({ setAddTask }: any) {
  const [serviceSelection, setServiceSelection] = useState<any>(null);
  const [statusSelection, setStatusSelection] = useState<any>(null);
  const [dueDateSelection, setDueDateSelection] = useState<any>(null);
  const [prioritySelection, setPrioritySelection] = useState<any>(null);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.inputContainer}>
        <input placeholder="Task Name" />
      </div>
      <div className={styles.textareaContainer}>
        <textarea placeholder="Description" />
      </div>
      <div className={styles.filterContainer}>
        <ServiceFilter
          serviceSelection={serviceSelection}
          setServiceSelection={setServiceSelection}
          icon
        />
        <StatusFilter
          statusSelection={statusSelection}
          setStatusSelection={setStatusSelection}
          icon
        />
        <DateFilter
          dueDateSelection={dueDateSelection}
          setDueDateSelection={setDueDateSelection}
          icon
        />
        <PriorityFilter
          prioritySelection={prioritySelection}
          setPrioritySelection={setPrioritySelection}
          icon
        />
      </div>
      <div className={styles.footerContainer}>
        <label className={styles.footerLabel}>#ProjectName</label>
        <div className={styles.btnContainer}>
          <CustomButton
            label={"Cancel"}
            buttonClass={styles.cancel}
            onClick={() => {
              setAddTask(false);
            }}
          />
          <CustomButton label={"Add task"} buttonClass={styles.addTask} />
        </div>
      </div>
    </div>
  );
}

export default AddTasks;
