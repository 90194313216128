import React from "react";
import styles from "./style.module.scss";
import { Tooltip } from "@mui/material";

function TableMembers({ members }: any) {
  return (
    <div>
      {members?.map((item: any, index: any, array: any) => {
        return (
          <Tooltip title={`${item?.name}`} placement="top-start">
            <img
              src={item?.profile_picture}
              className={styles.profile_pic}
              style={{ zIndex: array?.length - index }}
            />
          </Tooltip>
        );
      })}
    </div>
  );
}

export default TableMembers;
