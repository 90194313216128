import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  AccountIcon,
  ChevronDownIcon,
  DeleteIcon,
  DoneIcon,
  MessageIcon,
  PlusIcon,
  SingleMemberIcon,
  StatusIcon,
  WarningIcon,
} from "assets/icons";
import classNames from "classnames";
import { useState } from "react";
import CustomButton from "shared/components/customButton";
import ConfirmationModal from "shared/modals/confirmation";
import InviteMembers from "shared/modals/inviteMembers";
import { employee_roles_enums, roles_enums } from "shared/utils/enum";
import GetStatus from "../getStatus";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";

function ChannelCard({ index, channel }: any) {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [openInvite, setOpenInvite] = useState<any>(null);
  const [selectedMember, setSelectedMember] = useState<any>(null);
  const [members, setMembers] = useState<any>(
    channel?.members ? channel?.members : []
  );
  const [deletedMemberBool, setDeletedMemberBool] = useState<any>(false);

  const headers = [
    { label: "Members", icon: <SingleMemberIcon /> },
    { label: "Account", icon: <AccountIcon /> },
    { label: "Status", icon: <StatusIcon /> },
    { label: "Action" },
  ];

  const handleDeleteMember = () => {
    let temp = members?.filter((item: any) => item?.id !== selectedMember?.id);
    setMembers(temp);
    setDeletedMemberBool(true);
  };

  return (
    <>
      <div
        className={index % 2 !== 0 ? styles.paddingLeft : styles.paddingRight}
      >
        <div className={classNames(styles.upperMostContainer)}>
          <div className={styles.upperContainer}>
            <Box
              display={"flex"}
              alignItems={"center"}
              className={styles.topActionContainer}
            >
              <label className={styles.projectTitle}>{channel?.title}</label>
              <div style={{ display: "flex" }}>
                <div className={styles.tagContainer}>
                  <label className={styles.labelTag}>
                    {members?.length} Members
                  </label>
                </div>
                <div className={styles.tagContainer}>
                  <label className={styles.labelTag}>03 Projects</label>
                </div>
              </div>
            </Box>
            <Box display={"flex"} alignItems={"center"} position={"relative"}>
              <MessageIcon
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(routeConstant.requests.path.replace(":id", "1"))
                }
              />
              <div className={styles.newDotContainer}>
                <div className={styles.newDot} />
              </div>
            </Box>
          </div>
          <TableContainer
            component={Paper}
            className={styles.table}
            sx={{ boxShadow: "none" }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  className={classNames(styles.tableRow, styles.tableHead)}
                >
                  {headers?.map((item, index, array) => {
                    return (
                      <TableCell
                        className={classNames(
                          styles.tableCell,
                          index === 0 && styles.firstCell,
                          index === array.length - 1 && styles.lastCell
                        )}
                        sx={{
                          width: index === array.length - 1 ? "13%" : "29%",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"}>
                          <span>{item?.icon}</span>
                          <label style={{ marginLeft: "5px" }}>
                            {" "}
                            {item?.label}
                          </label>
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {members?.map((itm: any, index: any, array: any) => {
                  return (
                    <TableRow
                      id={index !== array.length - 1 ? styles.tableRowId : ""}
                      className={styles.tableRow}
                    >
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.tableBorderRight}
                        sx={{
                          width: "29%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            alt="profile_pic"
                            src={itm?.profile_picture}
                            className={styles.profile_pic}
                          />
                          <label style={{ marginLeft: "5%" }}>
                            {itm?.name}
                          </label>
                        </div>
                      </TableCell>
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.tableBorderRight}
                        sx={{ width: "29%" }}
                      >
                        {itm?.role === roles_enums.admin
                          ? "Admin"
                          : itm?.role === roles_enums.client
                          ? "Client"
                          : itm?.role === roles_enums.employee
                          ? itm?.emp_role === employee_roles_enums.designer
                            ? "Designer"
                            : itm?.emp_role === employee_roles_enums.developer
                            ? "Developer"
                            : ""
                          : ""}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.tableBorderRight}
                        sx={{ width: "29%" }}
                      >
                        <GetStatus status={itm?.status} />
                      </TableCell>
                      <TableCell
                        className={styles.tableCell2}
                        sx={{ width: "13%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Tooltip title="Delete" arrow>
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setDeleteModal(true);
                                setSelectedMember(itm);
                              }}
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div className={styles.btnContainer}>
        <CustomButton
          buttonClass={styles.btn}
          label={"Invite new member"}
          labelClass={styles.label}
          IconRightSvg={PlusIcon}
          iconStyle={styles.icon}
          onClick={() => {
            setOpenInvite(true);
          }}
        />
        <CustomButton
          buttonClass={styles.btn}
          label={"New requests"}
          labelClass={styles.label}
          IconRightSvg={ChevronDownIcon}
          iconStyle={styles.icon}
          alert={true}
          onClick={() =>
            navigate(routeConstant.requests.path.replace(":id", "1"))
          }
        />
      </div>
      <ConfirmationModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        Icon={deletedMemberBool ? DoneIcon : WarningIcon}
        label={deletedMemberBool ? "Action Completed" : "Confirm Action"}
        subLabel={
          deletedMemberBool
            ? `${selectedMember?.name} has been removed from channel`
            : `Are you sure you want to remove this ${selectedMember?.name} from ${channel?.title}`
        }
        actions={
          deletedMemberBool
            ? [
                {
                  label: "Go To Channels.",
                  action: () => {
                    setDeletedMemberBool(false);
                    setDeleteModal(false);
                    setSelectedMember(null);
                  },
                  style: styles.confirm,
                },
              ]
            : [
                {
                  label: "Cancel",
                  action: () => {
                    setDeleteModal(false);
                    setSelectedMember(null);
                  },
                  style: styles.cancel,
                },
                {
                  label: "Yes, Delete.",
                  action: () => {
                    handleDeleteMember();
                  },
                  style: styles.confirm,
                },
              ]
        }
      />
      <InviteMembers
        selectedChannel={channel}
        open={openInvite}
        handleClose={() => {
          setOpenInvite(false);
        }}
      />
    </>
  );
}

export default ChannelCard;
