import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { FolderIcon, MouseIcon, OverdueIcon, ShareIcon } from "assets/icons";
import { Grid, Skeleton, useMediaQuery } from "@mui/material";

function StatsTab() {
  const [loader, setLoader] = useState<boolean>(false);
  const isScreenSmall = useMediaQuery("(max-width:700px)");

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);
  let stats = [
    {
      label: "Active Projects",
      Icon: <FolderIcon />,
      stats: "12",
    },
    {
      label: "Active Tasks",
      Icon: <ShareIcon />,
      stats: "100",
    },
    {
      label: "Overdue Tasks",
      Icon: <OverdueIcon />,
      stats: "78",
    },
    {
      label: "Active Clients",
      Icon: <MouseIcon />,
      stats: "3",
    },
  ];

  return (
    // <div className={styles.mainContainer}>
      <Grid container className={styles.mainGrid}>
        {stats?.map((item, index) => {
          return (
            <Grid
              item
              xs={isScreenSmall ? 12  : 3}
              className={styles.statsContainer}

            >
              <div className={styles.heading}>{item?.label}</div>
              <div className={styles.icon}>{item?.Icon}</div>
              {loader ? (
                <Skeleton
                  variant="rounded"
                  width={40}
                  height={40}
                  className={styles.stats}
                />
              ) : (
                <div className={styles.stats}>{item?.stats}</div>
              )}
            </Grid>
          );
        })}
      </Grid>
    // </div>
  );
}

export default StatsTab;
