import React from "react";
import styles from "./style.module.scss";
import { GoogleCalendarIcon } from "assets/icons";
import CustomButton from "shared/components/customButton";

function IntegrationsSettings() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <label className={styles.heading}>Inetgration</label>
      </div>
      <div className={styles.container}>
        <div>
          <GoogleCalendarIcon className={styles.icon} />
        </div>
        <div className={styles.headingsContainer}>
          <label className={styles.label}>Google Calendar</label>
          <label className={styles.description}>
            Enjoy a 2-way sync between your scheduled WP tasks and your Google
            Calendar.
          </label>
        </div>
      </div>{" "}
      <CustomButton label={"Connect Google"} buttonClass={styles.btn} />
    </div>
  );
}

export default IntegrationsSettings;
