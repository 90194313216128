// src/components/SearchBar.js
import React, { useState, useRef, useEffect } from "react";
import styles from "./style.module.scss";
import classNames from "classnames";
import { SearchIcon } from "assets/icons";

const SearchBar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const inputRef: any = useRef(null);

  const handleClickOutside = (event: any) => {
    if (inputRef.current && !inputRef.current?.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.container} onClick={() => setIsExpanded(true)}>
      <SearchIcon className={isExpanded ? styles.iconExpanded : styles.icon} />
      <input
        ref={inputRef}
        className={classNames(styles.input, isExpanded && styles.expanded)}
        autoFocus={isExpanded}
      />
    </div>
  );
};

export default SearchBar;
