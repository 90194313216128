import { BackButtonIcon } from "assets/icons";
import React from "react";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

interface BackButtonProps {
  label: string;
  navigate: any;
  BackIcon?: any;
}

function BackButton({ label, navigate, BackIcon }: BackButtonProps) {
  const navigation = useNavigate();

  return (
    <div className={styles.container} onClick={() => navigation(navigate)}>
      {BackIcon ? (
        <BackIcon className={styles.icon2} />
      ) : (
        <BackButtonIcon className={styles.icon} />
      )}
      <label className={styles.label}>{label}</label>
    </div>
  );
}

export default BackButton;
