import { Avatar, Box } from "@mui/material";
import React, { useState } from "react";
import { DefaultUserIcon, SendIcon } from "assets/icons";
import { Menu, MenuItem } from "@mui/material";
import { DropDownIcon } from "assets/icons";
import CustomButton from "shared/components/customButton";
import styles from "./style.module.scss";
import classNames from "classnames";
import { toastMessage } from "shared/components/toast";

function InviteCard({ member, index, array }: any) {
  const [selectedRoleInvite, setSelectedRoleInvite] = useState<any>(null);
  return (
    <div
      className={classNames(
        styles.outerContainer,
        array?.length - 1 !== index && styles.borderBottom
      )}
    >
      <Box display={"flex"} alignItems={"center"}>
        {member.profile_pic ? (
          <Avatar
            variant="square"
            src={member.profile_pic}
            className={styles.profile_pic}
          />
        ) : (
          <DefaultUserIcon />
        )}

        <div style={{ marginLeft: "10px" }}>
          <div className={styles.memberName}>{member?.name}</div>
          <div className={styles.memberEmail}>{member?.email}</div>
        </div>
      </Box>
      <div>
        <InviteButton
          selection={selectedRoleInvite}
          setSelection={setSelectedRoleInvite}
          member={member}
        />
      </div>
    </div>
  );
}

export default InviteCard;

function InviteButton({ selection, setSelection, member }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { label: "Invite as Client", id: 1 },
    { label: "Invite as Employee", id: 2 },
    { label: "Invite as Guest", id: 3 },
  ];

  const handleSelection = (item: any) => {
    setSelection(item);
    toastMessage("success", `${member?.name} Invited`);
    handleClose();
  };

  return (
    <div>
      <CustomButton
        label={
          selection
            ? `Invited as ${
                selection?.id === 1
                  ? "a client"
                  : selection?.id === 2
                  ? "an employee"
                  : selection?.id === 3
                  ? "a guest"
                  : ""
              }`
            : menuItems[0].label
        }
        IconRightSvg={selection ? SendIcon : DropDownIcon}
        iconStyle={styles.icon}
        buttonClass={
          selection ? classNames(styles.btn, styles.btnSelected) : styles.btn
        }
        onClick={!selection ? handleClick : () => {}}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid #e2e2e2",
            padding: "0px 10px",
          },
        }}
      >
        {menuItems?.map((item: any) => {
          return (
            <MenuItem
              onClick={() => handleSelection(item)}
            >
              {item?.label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
