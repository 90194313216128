import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DropDownIcon, MembersIcon, StatusIcon } from "assets/icons";
import * as React from "react";
import styles from "../style.module.scss";
import GetStatus from "shared/components/getStatus";

export default function AddStatus() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let status_enums = {
    planning: 1,
    done: 2,
    inprogress: 3,
    paused: 4,
    cancelled: 5,
  };

  const menuItems = [
    {
      label: "Planning",
      id: status_enums.planning,
    },
    {
      label: "Done",
      id: status_enums.done,
    },
    {
      label: "In Progress",
      id: status_enums.inprogress,
    },
    {
      label: "Paused",
      id: status_enums.paused,
    },
    {
      label: "Cancelled",
      id: status_enums.cancelled,
    },
  ];

  return (
    <div>
      <button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={styles.membersBtn}
      >
        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <StatusIcon /> <label className={styles.memberLabel}>Status</label>
          </div>
          <div>
            <DropDownIcon />
          </div>
        </div>
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuItems?.map((item) => {
          return (
            <MenuItem onClick={handleClose}>
              <GetStatus status={item?.id} />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
