import { Grid } from "@mui/material";
import {
  AccountSettingsIcon,
  GeneralSettingsIcon,
  IntegrationSettingsIcon,
  NotificationSettingsIcon,
  SidebarSettingsIcon,
} from "assets/icons";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import AccountSettings from "shared/components/pages/settings/accountSettings";
import GeneralSettings from "shared/components/pages/settings/generalSettings";
import SidebarSettings from "shared/components/pages/settings/sideBar";
import { routeConstant } from "shared/routes/routesConstant";
import styles from "./style.module.scss";
import NotificationSettings from "shared/components/pages/settings/notificationSettings";
import IntegrationsSettings from "shared/components/pages/settings/integrationSettings";

function Settings() {
  const navigate = useNavigate();
  const params = useParams();
  const keyword = params?.keyword;
  const menuItems = [
    {
      label: "Account",
      icon: <AccountSettingsIcon />,
      navigation: routeConstant?.settings.path.replace(":keyword", "account"),
      keyword: "account",
    },
    {
      label: "General",
      icon: <GeneralSettingsIcon />,
      navigation: routeConstant?.settings.path.replace(":keyword", "general"),
      keyword: "general",
    },
    {
      label: "Sidebar",
      icon: <SidebarSettingsIcon />,
      navigation: routeConstant?.settings.path.replace(":keyword", "sidebar"),
      keyword: "sidebar",
    },
    {
      label: "Notifications",
      icon: <NotificationSettingsIcon />,
      navigation: routeConstant?.settings.path.replace(
        ":keyword",
        "notifications"
      ),
      keyword: "notifications",
    },
    {
      label: "Integrations",
      icon: <IntegrationSettingsIcon />,
      navigation: routeConstant?.settings.path.replace(
        ":keyword",
        "integration"
      ),
      keyword: "integration",
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <div className={styles.navigationItems}>
            {menuItems?.map((item) => {
              return (
                <div
                  className={classNames(
                    styles.singleSettingOption,
                    item?.keyword === keyword && styles.active
                  )}
                  onClick={() => {
                    navigate(item?.navigation);
                  }}
                >
                  {item?.icon} <label>{item?.label}</label>
                </div>
              );
            })}
          </div>
        </Grid>
        <Grid item xs={12} lg={10}>
          <div className={styles.settingsContainer}>
            {keyword === "account" ? (
              <AccountSettings />
            ) : keyword === "general" ? (
              <GeneralSettings />
            ) : keyword === "sidebar" ? (
              <SidebarSettings />
            ) : keyword === "notifications" ? (
              <NotificationSettings />
            ) : (
              <IntegrationsSettings />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Settings;
