import { BackButtonIcon2 } from "assets/icons";
import BackButton from "shared/components/backButton";
import DesignService from "shared/components/pages/editService/designService/index";
import DevelopmentService from "shared/components/pages/editService/developmentService";
import InfoSection from "shared/components/pages/editService/InfoSection";
import { routeConstant } from "shared/routes/routesConstant";

function EditService() {
  return (
    <div>
      <BackButton
        label={"Content Edit"}
        navigate={routeConstant.services.path}
        BackIcon={BackButtonIcon2}
      />
      <DesignService />
      <DevelopmentService />
      <InfoSection />
    </div>
  );
}

export default EditService;
