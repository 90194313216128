import { PlusIcon } from "assets/icons";
import { useState } from "react";
import CustomButton from "shared/components/customButton";
import DateFilter from "shared/components/filters/dateFilter";
import MembersFilter from "shared/components/filters/membersFilter";
import StatusFilter from "shared/components/filters/statusFilter";
import FilterTab from "shared/components/pages/tracker/filterTab";
import TrackerCard from "shared/components/pages/tracker/trackerCard";
import SearchBar from "shared/components/searchBar";
import {
  billConstants,
  membersConstants,
  tasksSelections,
  projectsConstants,
} from "./constants";
import styles from "./style.module.scss";
import { Sample1, Sample2, Sample3 } from "assets/sample";
import AddManualTime from "shared/modals/addManualTime";

function Tracker() {
  const [projectSelection, setProjectSelection] = useState<any>(null);
  const [billabilitySelection, setBillabilitySelection] = useState<any>(null);
  const [tasksSelection, setTasksSelection] = useState<any>(null);
  const [membersSelection, setMembersSelection] = useState<any>(null);
  const [dueDateSelection, setDueDateSelection] = useState<any>(null);
  const [statusSelection, setStatusSelection] = useState<any>(null);
  const [openManualTime, setOpenManualTime] = useState<boolean>(false);

  const resetFilters = () => {
    setProjectSelection(null);
    setBillabilitySelection(null);
    setTasksSelection(null);
    setMembersSelection(null);
    setDueDateSelection(null);
    setStatusSelection(null);
  };

  let projects = [
    {
      name: "Fatfish",
      number_of_tasks: 3,
      tasks: [
        {
          name: "Redesign Home UI",
          created_on: new Date("2024-06-05"),
          service: 1,
          status: 2,
          priority: 1,
          billability: 0,
          due_date: new Date("2024-06-21"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample2 },
            { name: "Jake", profile_picture: Sample3 },
          ],
        },
        {
          name: "CRM Development",
          created_on: new Date("2024-06-03"),
          service: 2,
          status: 3,
          priority: 2,
          billabilty: 1,
          due_date: new Date("2024-07-15"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample2 },
            { name: "Jake", profile_picture: Sample3 },
          ],
        },
        {
          name: "New logo design",
          created_on: new Date("2024-06-04"),
          service: 3,
          status: 1,
          priority: 3,
          billabilty: 0,
          due_date: new Date("2024-06-25"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample2 },
            { name: "Jake", profile_picture: Sample3 },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.headingContainer}>
          <div className={styles.headingContainer2}>
            <div>
              <div className={styles.heading}>Time Tracker</div>
              <div className={styles.subHeading}>Time Tracking Management</div>
            </div>
          </div>
          <CustomButton
            buttonClass={styles.btn}
            label={"Manual Time"}
            labelClass={styles.label}
            IconRightSvg={PlusIcon}
            containerClass={styles.btnContainer}
            onClick={() => setOpenManualTime(true)}
          />
        </div>
        <div className={styles.filterMainContainer}>
          <div className={styles.filterContainer}>
            <FilterTab
              selection={projectSelection}
              setSelection={setProjectSelection}
              label={"Project"}
              menuItems={projectsConstants}
            />
            <div>
              <FilterTab
                selection={billabilitySelection}
                setSelection={setBillabilitySelection}
                label={"Billability"}
                menuItems={billConstants}
              />
            </div>
            <div>
              <FilterTab
                selection={tasksSelection}
                setSelection={setTasksSelection}
                label={"Tasks"}
                menuItems={tasksSelections}
              />
            </div>
            <div>
              <StatusFilter
                statusSelection={statusSelection}
                setStatusSelection={setStatusSelection}
              />
            </div>
            <div>
              <MembersFilter
                membersSelection={membersSelection}
                setMembersSelection={setMembersSelection}
                members={membersConstants}
              />
            </div>
            <div>
              <DateFilter
                dueDateSelection={dueDateSelection}
                setDueDateSelection={setDueDateSelection}
              />
            </div>
            <div className={styles.resetBtn} onClick={() => resetFilters()}>
              Reset
            </div>
          </div>
          <div className={styles.searchBarContainer}>
            <SearchBar />
          </div>
        </div>

        <div>
          {projects?.map((item: any) => {
            return <TrackerCard item={item} />;
          })}
        </div>
      </div>

      <AddManualTime
        open={openManualTime}
        handleClose={() => {
          setOpenManualTime(false);
        }}
      />
    </>
  );
}

export default Tracker;
