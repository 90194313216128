import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { CrossIcon, DropDownIcon } from "assets/icons";
import React, { useState } from "react";
import styles from "./style.module.scss";
import { useFormik } from "formik";
import { AddMessageValidation } from "shared/utils/validation";
import CustomSelection from "shared/components/customSelection";
import classNames from "classnames";
import { Sample1, Sample2 } from "assets/sample";
import CustomButton from "shared/components/customButton";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AddMessageProps {
  open: boolean;
  handleClose: any;
}

interface InitialValues {
  contact: string;
  message: string;
}

const users = [
  {
    name: "Jane Doe",
    profile_picture: Sample1,
  },
  {
    name: "Killmar",
    profile_picture: Sample2,
  },
];

export default function AddMessage({ open, handleClose }: AddMessageProps) {
  const [usersOpen, setUsersOpen] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<any>(null);
  const initialValues: InitialValues = {
    contact: "",
    message: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddMessageValidation,
    onSubmit: (value) => {
      console.log("value", value);
    },
  });

  // Destructuring formik methods and values
  const { handleSubmit, values, touched, errors, setFieldValue } = formik;

  const onClose = () => {
    handleClose();
    formik.resetForm();
    setUserSelected(null);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            padding: "24px",
            width: "100%",
            justifyContent: "center",
            maxWidth: (theme) => theme.spacing(60),
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent>
          <div className={styles.mainContainer}>
            <div className={styles.upperActionContainer}>
              <div className={styles.label}>Start New Chat</div>
              <div onClick={() => onClose()}>
                <CrossIcon className={styles.crossIcon} />
              </div>
            </div>

            <div>
              <div style={{ position: "relative" }}>
                <label className={styles.inputLabel}>
                  General Availability
                </label>

                <div
                  className={classNames(styles.selectionContainer)}
                  onClick={(e: any) => {
                    setUsersOpen(true);
                  }}
                >
                  {userSelected ? (
                    <label style={{ cursor: "pointer" }}>
                      {userSelected?.name}
                    </label>
                  ) : (
                    <label style={{ cursor: "pointer" }}>Select User</label>
                  )}

                  <DropDownIcon />
                </div>
                <CustomSelection
                  openSelection={usersOpen}
                  setOpenSelection={setUsersOpen}
                  optionsArray={users}
                  setSelected={(item: any) => {
                    setUserSelected(item);
                    setFieldValue("contact", item);
                  }}
                  height="auto"
                  overflow="auto"
                />
                {touched.contact && errors.contact
                  ? errors.contact && (
                      <div className={styles.error}>{errors.contact}</div>
                    )
                  : ""}
              </div>

              <div style={{ marginTop: "10px" }}>
                <textarea
                  name="message"
                  placeholder="Message"
                  onChange={(e) => {
                    setFieldValue("message", e.target.value);
                  }}
                  className={classNames(
                    styles.description,
                    touched.message && errors.message && styles.errorBorder
                  )}
                  value={values.message}
                />
                {touched.message && errors.message
                  ? errors.message && (
                      <div className={styles.error}>{errors.message}</div>
                    )
                  : ""}
              </div>

              <div className={styles.btnContainer}>
                <CustomButton
                  label={"Cancel"}
                  buttonClass={styles.cancel}
                  onClick={() => onClose()}
                />
                <CustomButton
                  label={"Send"}
                  buttonClass={styles.send}
                  onClick={() => handleSubmit()}
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
