import { CalendarIcon2 } from "assets/icons";
import classNames from "classnames";
import { useFormik } from "formik";
import moment from "moment";
import PhoneInput from "react-phone-number-input";
import CustomButton from "shared/components/customButton";
import CustomInput from "shared/components/customInput";
import { EditClientValidation } from "shared/utils/validation";
import styles from "./style.module.scss";

interface InitialValues {
  first_name: string;
  last_name: string;
  role: string;
  member_id: string;
  email: string;
  company_name: string;
  phone: string;
}

function GeneralSettings() {
  const initialValues: InitialValues = {
    first_name: "Elisa",
    last_name: "Feliz",
    role: "Client",
    member_id: "CLT2204",
    email: "Elisafeliz@gmail.com",
    company_name: "Web Pioneer",
    phone: "+923054063572",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: EditClientValidation,
    onSubmit: (value) => {
      console.log("value", value);
    },
  });

  // Destructuring formik methods and values
  const { handleChange, handleSubmit, values, touched, errors, setFieldValue } =
    formik;

  return (
    <div style={{ marginTop: "24px" }}>
      <div className={styles.singleInputContainer}>
        <CustomInput
          placeholder={"member id"}
          heading="Member ID"
          type={"text"}
          disabled={true}
          value={values.member_id}
          onChange={handleChange("member_id")}
          onKeyDown={handleSubmit}
          stylesContainer={{ width: "100%" }}
          error={touched.member_id && errors.member_id ? errors.member_id : ""}
          inputId={styles.inputStyle}
        />
        <CustomInput
          placeholder={"role"}
          heading="Role"
          type={"text"}
          disabled={true}
          value={values.role}
          onChange={handleChange("role")}
          onKeyDown={handleSubmit}
          stylesContainer={{ width: "100%" }}
          error={touched.role && errors.role ? errors.role : ""}
          inputId={styles.inputStyle}
        />
      </div>
      <div className={styles.singleInputContainer}>
        <CustomInput
          placeholder={"Enter first name"}
          heading="First name"
          type={"text"}
          value={values.first_name}
          onChange={handleChange("first_name")}
          onKeyDown={handleSubmit}
          stylesContainer={{ width: "100%" }}
          error={
            touched.first_name && errors.first_name ? errors.first_name : ""
          }
          inputId={styles.inputStyle}
        />
        <CustomInput
          placeholder={"Enter last name"}
          heading="Last name"
          type={"text"}
          value={values.last_name}
          onChange={handleChange("last_name")}
          onKeyDown={handleSubmit}
          stylesContainer={{ width: "100%" }}
          error={touched.last_name && errors.last_name ? errors.last_name : ""}
          inputId={styles.inputStyle}
        />
      </div>
      <div className={styles.singleInputContainer}>
        <div className={styles.customInputContainer}>
          <div className={styles.inputLabel}>Phone</div>
          <div
            className={
              touched.phone && errors.phone
                ? classNames(styles.phoneInput, styles.errorBorder)
                : classNames(styles.phoneInput)
            }
          >
            <PhoneInput
              placeholder="Enter phone number"
              value={values.phone}
              onChange={(e: any) => {
                handleChange("phone");
                setFieldValue("phone", e);
              }}
            />
          </div>
          {touched.phone && errors.phone
            ? errors.phone && <div className={styles.error}>{errors.phone}</div>
            : ""}
        </div>
        <CustomInput
          placeholder={"Enter email"}
          heading="Email"
          type={"email"}
          value={values.email}
          onChange={handleChange("email")}
          onKeyDown={handleSubmit}
          stylesContainer={{ width: "100%" }}
          error={touched.email && errors.email ? errors.email : ""}
          inputId={styles.inputStyle}
        />
      </div>
      <div className={styles.singleInputContainer}>
        <CustomInput
          placeholder={"Enter company name"}
          heading="Company name"
          type={"text"}
          value={values.company_name}
          onChange={handleChange("company_name")}
          onKeyDown={handleSubmit}
          stylesContainer={{ width: "100%" }}
          error={
            touched.company_name && errors.company_name
              ? errors.company_name
              : ""
          }
          inputId={styles.inputStyle}
        />
        <div className={styles.customInputContainer}>
          <div className={styles.inputLabel}>Joining Date</div>
          <div
            style={{ justifyContent: "space-between" }}
            className={
              touched.phone && errors.phone
                ? classNames(styles.phoneInput, styles.errorBorder)
                : classNames(styles.phoneInput)
            }
          >
            {moment(new Date()).format("MMM DD, YYYY")}
            <CalendarIcon2 style={{ marginRight: "25px" }} />
          </div>
        </div>
      </div>
      <div className={styles.btnContainer}>
        <CustomButton label="Cancel" buttonClass={styles.cancel} />
        <CustomButton
          label="Save"
          buttonClass={styles.save}
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  );
}

export default GeneralSettings;
