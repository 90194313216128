import { Box, Tab, Tabs } from "@mui/material";
import { PlusIcon } from "assets/icons";
import { Sample1, Sample2, Sample3, Sample4 } from "assets/sample";
import classNames from "classnames";
import React, { useState } from "react";
import CustomButton from "shared/components/customButton";
import styles from "./style.module.scss";
import ProjectCard from "shared/components/pages/projects/projectCard";
import ServiceFilter from "shared/components/filters/serviceFilter";
import StatusFilter from "shared/components/filters/statusFilter";
import DateFilter from "shared/components/filters/dateFilter";
import MembersFilter from "shared/components/filters/membersFilter";
import PriorityFilter from "shared/components/filters/priorityFilter";
import SearchBar from "shared/components/searchBar";
import TasksCard from "shared/components/pages/projects/tasksCard";
import AddProject from "shared/modals/addProject";

function Projects() {
  const [value, setValue] = useState<any>(0);
  const [serviceSelection, setServiceSelection] = useState<any>(null);
  const [statusSelection, setStatusSelection] = useState<any>(null);
  const [dueDateSelection, setDueDateSelection] = useState<any>(null);
  const [prioritySelection, setPrioritySelection] = useState<any>(null);
  const [membersSelection, setMembersSelection] = useState<any>(null);
  const [openProject, setOpenProject] = useState<boolean>(false);

  const tabs = [
    {
      value: 0,
      label: "By  Project",
    },
    {
      value: 1,
      label: "All Tasks",
    },
  ];

  const tabsEnum = {
    project: 0,
    task: 1,
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  let dummy = [
    {
      name: "Fatfish",
      number_of_tasks: 3,
      tasks: [
        {
          name: "Redesign Home UI",
          created_on: new Date("2024-06-05"),
          service: 1,
          status: 2,
          priority: 1,
          due_date: new Date("2024-06-21"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample2 },
            { name: "Jake", profile_picture: Sample3 },
          ],
        },
        {
          name: "CRM Development",
          created_on: new Date("2024-06-03"),
          service: 2,
          status: 3,
          priority: 2,
          due_date: new Date("2024-07-15"),
          members: [
            { name: "Jake", profile_picture: Sample2 },
            { name: "Holt Raymond", profile_picture: Sample4 },
          ],
        },
        {
          name: "New logo design",
          created_on: new Date("2024-06-04"),
          service: 3,
          status: 1,
          priority: 3,
          due_date: new Date("2024-06-25"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample2 },
            { name: "Jake", profile_picture: Sample3 },
          ],
        },
        {
          name: "Brand guidelines",
          created_on: new Date("2024-06-04"),
          service: 1,
          status: 4,
          priority: 1,
          due_date: new Date("2024-06-25"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample3 },
          ],
        },
        {
          name: "Store setup shopify",
          created_on: new Date("2024-06-04"),
          service: 2,
          status: 5,
          priority: 2,
          due_date: new Date("2024-06-25"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample2 },
            { name: "Jake", profile_picture: Sample3 },
          ],
        },
      ],
    },
    {
      name: "Tronis",
      number_of_tasks: 3,
      tasks: [
        {
          name: "Redesign Home UI",
          created_on: new Date("2024-06-05"),
          service: 1,
          status: 2,
          priority: 1,
          due_date: new Date("2024-06-21"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample2 },
            { name: "Jake", profile_picture: Sample3 },
          ],
        },
        {
          name: "CRM Development",
          created_on: new Date("2024-06-03"),
          service: 2,
          status: 3,
          priority: 2,
          due_date: new Date("2024-07-15"),
          members: [
            { name: "Jake", profile_picture: Sample2 },
            { name: "Holt Raymond", profile_picture: Sample4 },
          ],
        },
        {
          name: "New logo design",
          created_on: new Date("2024-06-04"),
          service: 3,
          status: 1,
          priority: 3,
          due_date: new Date("2024-06-25"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample2 },
            { name: "Jake", profile_picture: Sample3 },
          ],
        },
        {
          name: "Brand guidelines",
          created_on: new Date("2024-06-04"),
          service: 1,
          status: 4,
          priority: 1,
          due_date: new Date("2024-06-25"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample3 },
          ],
        },
        {
          name: "Store setup shopify",
          created_on: new Date("2024-06-04"),
          service: 2,
          status: 5,
          priority: 2,
          due_date: new Date("2024-06-25"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample2 },
            { name: "Jake", profile_picture: Sample3 },
          ],
        },
      ],
    },
    {
      name: "Multiverse X",
      number_of_tasks: 3,
      tasks: [
        {
          name: "Redesign Home UI",
          created_on: new Date("2024-06-05"),
          service: 1,
          status: 2,
          priority: 1,
          due_date: new Date("2024-06-21"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample2 },
            { name: "Jake", profile_picture: Sample3 },
          ],
        },
        {
          name: "CRM Development",
          created_on: new Date("2024-06-03"),
          service: 2,
          status: 3,
          priority: 2,
          due_date: new Date("2024-07-15"),
          members: [
            { name: "Jake", profile_picture: Sample2 },
            { name: "Holt Raymond", profile_picture: Sample4 },
          ],
        },
        {
          name: "New logo design",
          created_on: new Date("2024-06-04"),
          service: 3,
          status: 1,
          priority: 3,
          due_date: new Date("2024-06-25"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample2 },
            { name: "Jake", profile_picture: Sample3 },
          ],
        },
        {
          name: "Brand guidelines",
          created_on: new Date("2024-06-04"),
          service: 1,
          status: 4,
          priority: 1,
          due_date: new Date("2024-06-25"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample3 },
          ],
        },
        {
          name: "Store setup shopify",
          created_on: new Date("2024-06-04"),
          service: 2,
          status: 5,
          priority: 2,
          due_date: new Date("2024-06-25"),
          members: [
            { name: "Jacob Janson", profile_picture: Sample1 },
            { name: "Kate Wilson", profile_picture: Sample2 },
            { name: "Jake", profile_picture: Sample3 },
          ],
        },
      ],
    },
  ];

  let dummyMembers = [
    { name: "Jacob Janson", profile_picture: Sample1 },
    { name: "Kate Wilson", profile_picture: Sample2 },
    { name: "Jake", profile_picture: Sample2 },
    { name: "Holt Raymond", profile_picture: Sample4 },
  ];

  let dummyTasks = [
    {
      name: "Redesign Home UI",
      created_on: new Date("2024-06-05"),
      service: 1,
      status: 2,
      priority: 1,
      due_date: new Date("2024-06-21"),
      members: [
        { name: "Jacob Janson", profile_picture: Sample1 },
        { name: "Kate Wilson", profile_picture: Sample2 },
        { name: "Jake", profile_picture: Sample3 },
      ],
    },
    {
      name: "CRM Development",
      created_on: new Date("2024-06-03"),
      service: 2,
      status: 3,
      priority: 2,
      due_date: new Date("2024-07-15"),
      members: [
        { name: "Jake", profile_picture: Sample2 },
        { name: "Holt Raymond", profile_picture: Sample4 },
      ],
    },
    {
      name: "New logo design",
      created_on: new Date("2024-06-04"),
      service: 3,
      status: 1,
      priority: 3,
      due_date: new Date("2024-06-25"),
      members: [
        { name: "Jacob Janson", profile_picture: Sample1 },
        { name: "Kate Wilson", profile_picture: Sample2 },
        { name: "Jake", profile_picture: Sample3 },
      ],
    },
    {
      name: "Brand guidelines",
      created_on: new Date("2024-06-04"),
      service: 1,
      status: 4,
      priority: 1,
      due_date: new Date("2024-06-25"),
      members: [
        { name: "Jacob Janson", profile_picture: Sample1 },
        { name: "Kate Wilson", profile_picture: Sample3 },
      ],
    },
    {
      name: "Store setup shopify",
      created_on: new Date("2024-06-04"),
      service: 2,
      status: 5,
      priority: 2,
      due_date: new Date("2024-06-25"),
      members: [
        { name: "Jacob Janson", profile_picture: Sample1 },
        { name: "Kate Wilson", profile_picture: Sample2 },
        { name: "Jake", profile_picture: Sample3 },
      ],
    },
  ];

  const resetFilters = () => {
    setStatusSelection(null);
    setDueDateSelection(null);
    setPrioritySelection(null);
    setMembersSelection(null);
    setServiceSelection(null);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.headingContainer2}>
          <div>
            <div className={styles.heading}>Project Status Overview</div>
            <div className={styles.subHeading}>
              Team projects sorted by priority
            </div>
          </div>
          <div className={styles.tagContainer}>
            <label className={styles.labelTag}>03 Projects</label>
          </div>
        </div>
        <CustomButton
          buttonClass={styles.btn}
          label={"New Project"}
          labelClass={styles.label}
          IconRightSvg={PlusIcon}
          containerClass={styles.btnContainer}
          onClick={() => setOpenProject(true)}
        />
      </div>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        className={styles.tabsContainer}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          className={styles.tab}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#FF2626",
            },
          }}
        >
          {tabs?.map((item: any, index: any) => {
            return (
              <Tab
                key={index}
                label={item?.label}
                className={classNames(
                  styles.labelTab,
                  value === item?.value && styles.active
                )}
              />
            );
          })}
        </Tabs>
      </Box>
      <div className={styles.filterMainContainer}>
        <div className={styles.filterContainer}>
          <ServiceFilter
            serviceSelection={serviceSelection}
            setServiceSelection={setServiceSelection}
          />
          <StatusFilter
            statusSelection={statusSelection}
            setStatusSelection={setStatusSelection}
          />
          <DateFilter
            dueDateSelection={dueDateSelection}
            setDueDateSelection={setDueDateSelection}
          />
          <PriorityFilter
            prioritySelection={prioritySelection}
            setPrioritySelection={setPrioritySelection}
          />
          <MembersFilter
            membersSelection={membersSelection}
            setMembersSelection={setMembersSelection}
            members={dummyMembers}
          />
          <div className={styles.resetBtn} onClick={() => resetFilters()}>
            Reset
          </div>
        </div>
        <div className={styles.searchBarContainer}>
          <SearchBar />
        </div>
      </div>
      <div className={styles.projectContainer}>
        {value === tabsEnum?.project ? (
          dummy?.map((item) => {
            return <ProjectCard project={item} />;
          })
        ) : (
          <TasksCard tasks={dummyTasks} />
        )}
      </div>

      <AddProject
        open={openProject}
        handleClose={() => {
          setOpenProject(false);
        }}
      />
    </div>
  );
}

export default Projects;
