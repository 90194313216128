import React, { useEffect, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import styles from "./style.module.scss";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resetUser } from "shared/redux/reducers/userSlice";
import classNames from "classnames";
import useDebounce from "shared/customHooks/useDebounce";
import {
  DropDownIcon,
  LogoutIcon,
  SearchIcon,
  SettingsIcon,
  SyncIcon,
  WarningIcon,
} from "assets/icons";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import { routeConstant } from "shared/routes/routesConstant";
import ConfirmationModal from "shared/modals/confirmation";

// Define the interface for Header component props
interface HeaderProps {
  setToggle: any;
  toggle: boolean;
  setCollapse: any;
  collapse: boolean;
}

// Header component
function Header({
  toggle,
  setToggle,
  setCollapse,
  collapse,
}: Partial<HeaderProps>) {
  const { user } = useSelector((state: any) => state.root); // Get user state from Redux store
  const location = useLocation(); // to check active urls
  const dispatch = useDispatch(); // Initialize dispatch for Redux actions
  const navigate = useNavigate(); // Initialize navigate for routing
  const screenLg = useMediaQuery("(max-width:992px)"); // Media query for screen size
  const [search, setSearch] = useState<string>(""); // State for search input
  const [searchVal, setSearchVal] = useState<string>(""); // State for search input
  const [isDropdownVisible, setIsDropdownVisible] = useState(true); // State for dropdown visibility
  const resultsContainerRef = useRef<HTMLDivElement | null>(null); // Ref for search results container
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null); // State for user menu anchor element
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false); // State for asking for logout confirmation
  const [notificationsActive, setNotificationActive] = useState<boolean>(false); // State to mark notification button active

  // Function to handle user logout
  const handleLogout = () => {
    dispatch(resetUser());
  };

  // Function to handle opening user menu
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  // Function to handle closing user menu
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // Settings for user menu
  const settings = [
    {
      label: "Sync",
      action: () => {},
      icon: SyncIcon,
    },
    {
      label: "Settings",
      action: () => {
        navigate(routeConstant.settings.path);
        handleCloseUserMenu();
      },
      icon: SettingsIcon,
    },
    {
      label: "Logout",
      action: () => {
        setOpenConfirmation(true);
        handleCloseUserMenu();
        // handleLogout();
      },
      icon: LogoutIcon,
    },
  ];

  // Debounce hook for search input
  useDebounce(
    () => {
      setSearchVal(search);
    },
    [search],
    800
  );

  // Function to handle clicks outside of the search results container
  const handleOutsideClick = (event: any) => {
    if (
      resultsContainerRef.current &&
      !resultsContainerRef.current.contains(event.target as Node)
    ) {
      setIsDropdownVisible(false);
    }
  };

  // Add event listener for outside clicks
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // navigate to search page for search text
  useEffect(() => {
    if (searchVal?.length) {
      navigate(routeConstant.search.path + "?keyword=" + searchVal);
    }
  }, [searchVal]);

  // check if notification tab is active or not
  useEffect(() => {
    if (location?.pathname === routeConstant.notification.path) {
      setNotificationActive(true);
    } else {
      setNotificationActive(false);
    }
  }, [location]);

  return (
    <AppBar position="static" className={styles.headerMainContainer}>
      <Container
        maxWidth="xl"
        className={classNames(
          !screenLg ? styles.headerItems : "",
          styles.topLevelContainer
        )}
      >
        <Toolbar
          disableGutters
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: { xl: "flex", justifyContent: "space-between" },
            }}
          >
            {screenLg ? (
              // Menu icon for small screens
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setToggle(!toggle)}
                color="inherit"
              >
                <MenuIcon sx={{ color: "black" }} />
              </IconButton>
            ) : (
              // Search
              <div className={styles.searchContainer}>
                <SearchIcon />
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search anything"
                />
              </div>
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Box
              sx={{
                flexGrow: 0,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className={styles.notificationBtnContainer}>
                {/* Notification button */}
                <button
                  className={classNames(
                    styles.notificationBtn,
                    notificationsActive && styles.notificationTabActive
                  )}
                  onClick={() => navigate(routeConstant.notification.path)}
                >
                  <div className={styles.alert}>
                    <div className={styles.redDot} />
                  </div>
                  <PanoramaFishEyeIcon
                    className={styles.notificationIconStyle}
                  />
                </button>
              </div>
              <Tooltip title="Account">
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0, width: "200px" }}
                  className={styles.buttonInfo}
                >
                  <div className={styles.dropdownUserinfo}>
                    <div>
                      <Avatar
                        variant="square"
                        sx={{
                          marginRight: "12px",
                          width: "36px",
                          height: "36px",
                        }}
                      />
                    </div>
                    <div className={styles.introContainer}>
                      <div className={styles.name}>{"Jane Doe"}</div>
                      <div className={styles.role}>{"Signed in as Admin"}</div>
                    </div>
                    <DropDownIcon />
                  </div>
                </IconButton>
              </Tooltip>

              {/* User menu */}
              <Menu
                sx={{ mt: "45px", padding: "14px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                className={styles.settingsContainer}
              >
                {settings.map((setting: any, index: number) => (
                  <MenuItem key={index} onClick={setting.action}>
                    <setting.icon />
                    <Typography
                      textAlign="center"
                      className={styles.settingsLabel}
                    >
                      {setting.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>

      <ConfirmationModal
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        Icon={WarningIcon}
        label={"Confirm Action"}
        subLabel={"Are you sure you want to logout?"}
        actions={[
          {
            label: "Cancel",
            action: () => setOpenConfirmation(false),
            style: styles.cancel,
          },
          {
            label: "Yes, Logout.",
            action: () => handleLogout(),
            style: styles.confirm,
          },
        ]}
      />
    </AppBar>
  );
}

export default Header;
