import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  user_info: Record<string, any>;
  isLoggedIn: boolean;
  token: string | null;
  role: Record<string, any>;
}

const initialState: UserState = {
  user_info: {},
  isLoggedIn: false,
  token: null,
  role: {},
  };

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      return { ...state, ...action.payload };
    },
    resetUser: () => initialState,
  },
});

export const { setUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
