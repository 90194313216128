import { Box, Grid } from "@mui/material";
import React from "react";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";
import { EditIcon, EmailIcon, WelcomeTabIcon2 } from "assets/icons";
import { DesignService, DevelopmentService } from "assets/icons";
import DoneIcon from "@mui/icons-material/Done";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";

function Services() {
  const navigate = useNavigate();
  let services = [
    {
      label: "UI UX",
      description:
        "Fuel your design journey with the freedom to customize as per your needs.",
      amount: "1500",
      icon: DesignService,
      points: [
        { label: "Unlimited design requests(2 at a time)" },
        { label: "Unlimited revisions to ensure perfection" },
        { label: "Invite your team to submit requests" },
        { label: "Fast delivery: 3-5 business days on average" },
        { label: "Up to 40 hours of dedicated design work" },
        { label: "Add up to 2 projects simultaneously" },
        { label: "Optimized designs for conversions" },
        { label: "Purposeful designs for better UX" },
      ],
    },
    {
      label: "Development",
      description:
        "Empower your online presence with a site developed to your specifications.",
      amount: "2500",
      icon: DevelopmentService,
      points: [
        { label: "Quick turnaround to meet your deadlines" },
        { label: "Streamlined process completion" },
        { label: "Collaborate with experts to realize vision" },
        { label: "Average 1-week rapid deployment" },
        { label: "WordPress setup for easy management" },
        { label: "CRO A/B testing for better performance" },
        { label: "Custom plugins for added functionality" },
        { label: "Comprehensive analytics for decisions" },
      ],
    },
  ];
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8.5}>
          <div className={styles.mainContainer}>
            <div className={styles.headingContainer}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                <label className={styles.heading}>Services</label>
                <label className={styles.subHeading}>
                  This is the services view your clients will see.
                </label>
              </Box>
              <CustomButton
                buttonClass={classNames(styles.btn, styles.btnEdit)}
                label={"Edit content"}
                labelClass={styles.label}
                IconRightSvg={EditIcon}
                containerClass={styles.btnContainer}
                onClick={() => navigate(routeConstant.editService.path)}
              />
            </div>

            <div className={styles.serviceContainer}>
              {services?.map((item) => {
                return (
                  <div className={styles.singleServiceContainer}>
                    <div className={styles.topServiceContainer}>
                      <div className={styles.serviceHeadingContainer}>
                        <label className={styles.serviceName}>
                          {item?.label}
                        </label>
                        <label className={styles.serviceDescription}>
                          {item?.description}
                        </label>
                      </div>
                      <item.icon className={styles.serviceIcon} />
                    </div>
                    <div>
                      <label className={styles.serviceAmount}>
                        ${item?.amount}
                        <label>/month</label>
                      </label>
                    </div>
                    <div className={styles.serviceSubHeadingContainer}>
                      <label className={styles.serviceSubHeading}>
                        Paid monthly.
                      </label>
                      <label className={styles.serviceSubHeading}>
                        Pause or cancel anytime.
                      </label>
                    </div>
                    <CustomButton
                      label={"Subscribe Now"}
                      buttonClass={styles.btn}
                    />
                    <div className={styles.pointContainer}>
                      {item?.points?.map((itm: any) => {
                        return (
                          <div className={styles.singleServicePoint}>
                            <DoneIcon
                              sx={{
                                color: "#808080",
                                fontSize: "16px",
                                marginRight: "6px",
                              }}
                            />
                            {itm?.label}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={3.5}>
          <div className={styles.infoGrid}>
            <div className={styles.singleInfoContainer}>
              <label className={styles.heading}>Discovery Call</label>
              <label className={styles.subHeading}>
                Not sure where to start? Let's discuss your project in detail
                and explore the best options.
              </label>
              <CustomButton
                label={"Schedule a Call"}
                buttonClass={styles.contactBtn}
              />
            </div>

            <div className={styles.singleInfoContainer}>
              <label className={styles.heading}>Contact Us Directly</label>
              <label className={styles.subHeading}>
                We're here to help! Reach out directly via phone or email for
                immediate assistance.
              </label>
              <label className={styles.contactInfo}>🇺🇸 +1 (631) 530-2666</label>
              <label className={styles.contactInfo}>🇵🇰 +92 305 406 3 572</label>
              <label className={styles.contactInfo}>
                <EmailIcon style={{ marginRight: "6px" }} />
                business@webpioneers.com
              </label>
              <WelcomeTabIcon2
                className={classNames(styles.icon, styles.spin)}
              />
              <div style={{ paddingBottom: "60px" }} />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Services;
