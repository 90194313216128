import styles from "pages/editService/style.module.scss";
import { Grid } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import { EditInfoValidation } from "shared/utils/validation";
import CustomInput from "shared/components/customInput";
import classNames from "classnames";
import PhoneInput from "react-phone-number-input";
import CustomButton from "shared/components/customButton";
import { EmailIcon, PlusIcon, WelcomeTabIcon2 } from "assets/icons";

interface InitialValues {
  callTitle: string;
  callCTA: string;
  callHeadline: string;
  helpTitle: string;
  helpCTA: string;
  helpHeadline: string;
  infoTitle: string;
  email: string;
  infoHeadline: string;
  phone1: string;
  phone2: string;
}

function InfoSection() {
  const initialValues: InitialValues = {
    callTitle: "Discovery Call",
    callCTA: "Schedule a Call ",
    callHeadline:
      "Not sure where to start? Let's discuss your project in detail and explore the best options.",
    helpTitle: "Get Help with Your Custom Project",
    helpCTA: "Request a Custom Quote",
    helpHeadline:
      "Have a Unique Idea? Let's Bring It to Life with a Tailored Solution Just for You",
    infoTitle: "Contact Us Directly",
    email: "business@webpioneers.com",
    infoHeadline:
      "We're here to help! Reach out directly via phone or email for immediate assistance.",
    phone1: "+1 631 530-2666",
    phone2: "+92 305 406 3 572",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: EditInfoValidation,
    onSubmit: (value) => {
      console.log("value", value);
    },
  });

  const { handleChange, handleSubmit, values, touched, errors, setFieldValue } =
    formik;

  return (
    <Grid container spacing={2} marginTop={"24px"}>
      <Grid item xs={12} md={7.5}>
        <div className={styles.mainContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Title"}
                heading="Title"
                type={"text"}
                value={values.callTitle}
                onChange={handleChange("callTitle")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.callTitle && errors.callTitle ? errors.callTitle : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter CTA"}
                heading="CTA"
                type={"text"}
                value={values.callCTA}
                onChange={handleChange("callCTA")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={touched.callCTA && errors.callCTA ? errors.callCTA : ""}
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                placeholder={"Headline"}
                heading="Headline"
                type={"text"}
                value={values.callHeadline}
                onChange={handleChange("callHeadline")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.callHeadline && errors.callHeadline
                    ? errors.callHeadline
                    : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Title"}
                heading="Title"
                type={"text"}
                value={values.helpTitle}
                onChange={handleChange("helpTitle")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.helpTitle && errors.helpTitle ? errors.helpTitle : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter CTA"}
                heading="CTA"
                type={"text"}
                value={values.helpCTA}
                onChange={handleChange("helpCTA")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={touched.helpCTA && errors.helpCTA ? errors.helpCTA : ""}
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                placeholder={"Headline"}
                heading="Headline"
                type={"text"}
                value={values.helpHeadline}
                onChange={handleChange("helpHeadline")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.helpHeadline && errors.helpHeadline
                    ? errors.helpHeadline
                    : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Title"}
                heading="Title"
                type={"text"}
                value={values.infoTitle}
                onChange={handleChange("infoTitle")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.infoTitle && errors.infoTitle ? errors.infoTitle : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                placeholder={"Enter Email"}
                heading="Email"
                type={"text"}
                value={values.email}
                onChange={handleChange("email")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={touched.email && errors.email ? errors.email : ""}
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                placeholder={"Headline"}
                heading="Headline"
                type={"text"}
                value={values.infoHeadline}
                onChange={handleChange("infoHeadline")}
                onKeyDown={handleSubmit}
                stylesContainer={{ width: "100%" }}
                error={
                  touched.infoHeadline && errors.infoHeadline
                    ? errors.infoHeadline
                    : ""
                }
                inputId={styles.inputStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label className={styles.inputLabel}>Phone 01</label>
              <div
                className={
                  touched.phone1 && errors.phone1
                    ? classNames(styles.phoneNumber, styles.errorBorder)
                    : classNames(styles.phoneNumber)
                }
              >
                <PhoneInput
                  placeholder="Enter phone number"
                  value={values.phone1}
                  defaultCountry="US"
                  countries={["US"]}
                  onChange={(e: any) => {
                    handleChange("phone1");
                    setFieldValue("phone1", e);
                  }}
                />
              </div>
              {touched.phone1 && errors.phone1
                ? errors.phone1 && (
                    <div className={styles.error}>{errors.phone1}</div>
                  )
                : ""}
            </Grid>
            <Grid item xs={12} md={6}>
              <label className={styles.inputLabel}>Phone 02</label>
              <div
                className={
                  touched.phone2 && errors.phone2
                    ? classNames(styles.phoneNumber, styles.errorBorder)
                    : classNames(styles.phoneNumber)
                }
              >
                <PhoneInput
                  placeholder="Enter phone number"
                  value={values.phone2}
                  defaultCountry="PK"
                  countries={["PK"]}
                  onChange={(e: any) => {
                    handleChange("phone2");
                    setFieldValue("phone2", e);
                  }}
                />
              </div>
              {touched.phone2 && errors.phone2
                ? errors.phone2 && (
                    <div className={styles.error}>{errors.phone2}</div>
                  )
                : ""}
            </Grid>
          </Grid>
          <div className={styles.btnContainer}>
            <CustomButton
              label={"Update Service"}
              buttonClass={styles.updateBtn}
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={4.5}>
        <div className={styles.infoContainers}>
          <div className={styles.infoSingleContainer}>
            <label className={styles.infoHeading}>{values?.callTitle}</label>
            <label className={styles.infoDescription}>
              {values?.callHeadline}
            </label>
            <CustomButton
              label={values?.callCTA}
              buttonClass={styles.contactBtn}
              IconRightSvg={PlusIcon}
              iconStyle={styles.contactIcon}
            />
          </div>
          <div className={styles.infoSingleContainer}>
            <label className={styles.infoHeading}>{values?.helpTitle}</label>
            <label className={styles.infoDescription}>
              {values?.helpHeadline}
            </label>
            <CustomButton
              label={values?.helpCTA}
              buttonClass={styles.contactBtn}
              IconRightSvg={PlusIcon}
              iconStyle={styles.contactIcon}
            />
          </div>
          <div className={styles.infoSingleContainer}>
            <label className={styles.infoHeading}>{values?.infoTitle}</label>
            <label className={styles.infoDescription}>
              {values?.infoHeadline}
            </label>
            <label className={styles.contactInfo}>🇺🇸 {values?.phone1}</label>
            <label className={styles.contactInfo}>🇵🇰 {values?.phone2}</label>
            <label className={styles.contactInfo}>
              <EmailIcon style={{ marginRight: "6px" }} />
              {values?.email}
            </label>
            <WelcomeTabIcon2
              className={classNames(styles.iconSpin, styles.spin)}
            />
            <div style={{ paddingBottom: "30px" }} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default InfoSection;
