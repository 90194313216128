import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import styles from "./style.module.scss"
import classNames from "classnames";
import { CalendarIcon2, MembersIcon, PriorityIcon, ServiceIcon, StatusIcon, TasksIcon, TimeIcon } from "assets/icons";
import GetStatus from "shared/components/getStatus";
import moment from "moment";
import GetPriority from "shared/components/getPriority";
import GetPriorty from "shared/components/getPriority";
import TableMembers from "shared/components/tableMembers";
import { service_enums } from "shared/utils/enum";


function TasksCard({ tasks }: any) {

    const headers = [
        { label: "Tasks", icon: <TasksIcon /> },
        { label: "Created On", icon: <TimeIcon /> },
        { label: "Service", icon: <ServiceIcon /> },
        { label: "Status", icon: <StatusIcon /> },
        { label: "Due Date", icon: <CalendarIcon2 /> },
        { label: "Priority", icon: <PriorityIcon /> },
        { label: "Members", icon: <MembersIcon /> },
      ];

  return (
    <div>
      <TableContainer component={Paper} className={styles.table}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={classNames(styles.tableRow, styles.tableHead)}>
              {headers?.map((item, index, array) => {
                return (
                  <TableCell
                    className={classNames(
                      styles.tableCell,
                      index === 0 && styles.firstCell,
                      index === array.length - 1 && styles.lastCell
                    )}
                  >
                    <Box display={"flex"} alignItems={"center"}>
                      <span>{item?.icon}</span>
                      <label style={{ marginLeft: "5px" }}>
                        {" "}
                        {item?.label}
                      </label>
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks?.map((item: any, index: any, array: any) => {
              return (
                <TableRow
                  id={index !== array.length - 1 ? styles.tableRowId : ""}
                  className={styles.tableRow}
                >
                  <TableCell
                    className={styles.tableCell2}
                    id={styles.tableBorderRight}
                  >
                    {item?.name}
                  </TableCell>
                  <TableCell
                    className={styles.tableCell2}
                    id={styles.tableBorderRight}
                  >
                    {moment(item?.created_on).format("ddd, MMMM DD, YYYY")}
                  </TableCell>
                  <TableCell
                    className={styles.tableCell2}
                    id={styles.tableBorderRight}
                  >
                    {item?.service === service_enums.uiux
                      ? "UI/UX"
                      : item?.service === service_enums.development
                      ? "Development"
                      : "Custom"}
                  </TableCell>
                  <TableCell
                    className={styles.tableCell2}
                    id={styles.tableBorderRight}
                  >
                    <GetStatus status={item?.status} />
                  </TableCell>
                  <TableCell
                    className={styles.tableCell2}
                    id={styles.tableBorderRight}
                  >
                    {moment(item?.due_date).format("ddd, MMMM DD, YYYY")}
                  </TableCell>
                  <TableCell
                    className={styles.tableCell2}
                    id={styles.tableBorderRight}
                  >
                    <GetPriorty priority={item?.priority} />
                  </TableCell>
                  <TableCell className={styles.tableCell2}>
                    <TableMembers members={item?.members} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default TasksCard;
