import React, { useState } from "react";
import styles from "./style.module.scss";
import { Box, Tab, Tabs } from "@mui/material";
import classNames from "classnames";
import GeneralSettings from "../generalSettings";

function EmployeeSettings() {
  const [activeTab, setActiveTab] = useState<any>(0);
  const [showTab, setShowTab] = useState<boolean>(false);
  const tabs = [
    {
      value: 0,
      label: "General Info",
    },
    {
      value: 1,
      label: "Billing Overview",
    },
    {
      value: 2,
      label: "Projects",
    },
    {
      value: 3,
      label: "Reviews",
    },
  ];

  const activeTabEnums = {
    general: 0,
    billing: 1,
    projects: 2,
    reviews: 3,
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <div className={styles.mainContainer}>
      <GeneralSettings/>
    </div>
  );
}

export default EmployeeSettings;
