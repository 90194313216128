import * as yup from "yup";
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

const LoginValidation = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .label("email")
    .email("Invalid Email"),
  password: yup.string().required("Password is required").label("password"),
});

const SignUpValidation = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .label("email")
    .email("Invalid Email"),
  password: yup
    .string()
    .required("Password is required")
    .label("password")
    .min(8, "Password must be 8 Characters long")
    .matches(
      passwordRegExp,
      "Password must contain at least One Upper Case Character, One Lower Case Character and One Number"
    ),
  timeZone: yup.string().required("Time Zone is required"),
});

const ForgetPasswordValidation = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .label("email")
    .email("Invalid Email"),
});

const UpdatePasswordValidation = yup.object().shape({
  password: yup.string().required("Password is Required"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is Required")
    .oneOf([yup.ref("password"), ""], "Passwords must match"),
});

const SingleEmailValidation = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .label("email")
    .email("Invalid Email"),
});

const AddMemberValidation = yup.object().shape({
  profile_picture: yup.mixed().required("Profile Picture is required"),
  memberId: yup.string().required("Member id is required"),
  email: yup.string().required("Email is required").email("Invalid Email"),
  company: yup.string().required("Company name is required"),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  phone: yup.string().required("Phone no. is required"),
  role: yup.string().required("Role is required"),
});

const EditClientValidation = yup.object().shape({
  memberId: yup.string().required("Member id is required"),
  role: yup.string().required("role is required"),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  company_name: yup.string().required("Company name is required"),
  email: yup.string().required("Email is required").email("Invalid Email"),
  phone: yup.string().required("Phone no. is required"),
});

const SettingsValidation = yup.object().shape({
  profile_picture: yup.mixed().required("Profile Picture is required"),
  email: yup.string().required("Email is required").email("Invalid Email"),
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
});

const ChangePasswordValidation = yup.object().shape({
  current_password: yup.string().required("Current Password is Required"),
  new_password: yup.string().required("New Password is Required"),
  confirm_password: yup
    .string()
    .required("Confirm Password is Required")
    .oneOf([yup.ref("password"), ""], "Passwords must match"),
});

const EditServiceValidation = yup.object().shape({
  profile_picture: yup.mixed().required("Icon is required"),
  title: yup.string().required("Title is required"),
  headline: yup.string().required("Headline is required"),
  keyPoint1: yup.string().required("Key note is required"),
  keyPoint2: yup.string().required("Key note is required"),
  amount: yup.string().required("Amount is required"),
  CTA: yup.string().required("CTA is required"),
  icon: yup.string().required("CTA is required"),
  highlight1: yup.string().required("Highlight is required"),
  highlight2: yup.string().required("Highlight is required"),
  highlight3: yup.string().required("Highlight is required"),
  highlight4: yup.string().required("Highlight is required"),
  highlight5: yup.string().required("Highlight is required"),
  highlight6: yup.string().required("Highlight is required"),
  highlight7: yup.string().required("Highlight is required"),
  highlight8: yup.string().required("Highlight is required"),
});

const EditInfoValidation = yup.object().shape({
  callTitle: yup.string().required("Title is required"),
  callCTA: yup.string().required("CTA is required"),
  callHeadline: yup.string().required("Headline is required"),
  helpTitle: yup.string().required("Title is required"),
  helpCTA: yup.string().required("CTA is required"),
  helpHeadline: yup.string().required("Headline is required"),
  infoTitle: yup.string().required("Title is required"),
  email: yup
    .string()
    .required("Email is required")
    .label("email")
    .email("Invalid Email"),
  infoHeadline: yup.string().required("Headline is required"),
  phone1: yup.string().required("Phone number is required"),
  phone2: yup.string().required("Phone number is required"),
});

const BookAnIntroValidation = yup.object().shape({
  full_name: yup.string().required("Full Name is required"),
  service: yup.string().required("Service is required"),
  email: yup
    .string()
    .required("Email is required")
    .label("email")
    .email("Invalid Email"),
  description: yup.string().required("Description is required"),
  phone: yup.string().required("Phone number is required"),
});

const AddMessageValidation = yup.object().shape({
  contact: yup.object().required("Select Contact to continue."),
  message: yup.string().required("Message is required"),
});

const AddManualTimeValidation = yup.object().shape({
  project: yup.object().required("Project is required"),
  task: yup.object().required("Task is required"),
  description: yup.string().required("Description is required"),
  hours: yup
    .string()
    .required("Hours is required")
    .max(60, "Can't be more than 60")
    .min(1, "Can't be less than 1"),
  mins: yup
    .string()
    .required("Minutes is required")
    .max(60, "Can't be more than 60")
    .min(1, "Can't be less than 1"),
  seconds: yup
    .string()
    .required("Seconds is required")
    .max(60, "Can't be more than 60")
    .min(1, "Can't be less than 1"),
  date: yup.string().required("Date is required"),
});

const AddProjectValidation = yup.object().shape({
  name: yup.string().required("Project Name is requried."),
  description: yup.string().required("Description is required"),
});

export {
  AddMemberValidation,
  EditClientValidation,
  ForgetPasswordValidation,
  LoginValidation,
  SettingsValidation,
  SignUpValidation,
  SingleEmailValidation,
  UpdatePasswordValidation,
  ChangePasswordValidation,
  EditServiceValidation,
  EditInfoValidation,
  BookAnIntroValidation,
  AddMessageValidation,
  AddManualTimeValidation,
  AddProjectValidation
};
