import Grid from "@mui/material/Grid";
import { DoneIcon, GoogleIcon } from "assets/icons";
import { AuthBanner, FullImageLogoSvg } from "assets/images";
import classnames from "classnames";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import { IResolveParams, LoginSocialGoogle } from "reactjs-social-login";
import CustomButton from "shared/components/customButton";
import CustomInput from "shared/components/customInput";
import CustomSelection from "shared/components/customSelection";
import Footer from "shared/components/footer";
import { routeConstant } from "shared/routes/routesConstant";
import { SignUpValidation } from "shared/utils/validation";
import styles from "./style.module.scss";
import BookAnIntro from "shared/modals/bookAnIntro";
import ConfirmationModal from "shared/modals/confirmation";

// Interface for initial form values
interface InitialValues {
  name: string;
  email: string;
  password: string;
  timeZone: string;
}

const labelStyle = "original";

function SignUp() {
  // For Navigation
  const navigate = useNavigate();

  // For timezone array
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    ...allTimezones,
  });
  /* ------------------------------------State variables------------------------------------- */
  const [googleLoading, setGoogleloading] = useState<boolean>(false);
  const [allTimeZones, setAllTimeZones] = useState<any>(options ? options : []);
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState<any>(null);
  const [openBooking, setOpenBooking] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  /* ---------------------------------------------------------------------------------------- */

  // Initial form values
  const initialValues: InitialValues = {
    name: "",
    email: "",
    password: "",
    timeZone: "",
  };

  /* ------------------------------------Formik handling------------------------------------- */

  // Formik hook for form handling
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: SignUpValidation,
    onSubmit: (value) => {
      console.log("value", value);
    },
  });

  // Destructuring formik methods and values
  const { handleChange, handleSubmit, values, touched, errors, setFieldValue } =
    formik;

  /* ---------------------------------------------------------------------------------------- */

  // Callback function for social login success
  const onSuccess = (data: any, provider: any) => {
    console.log("data", data);
    console.log("provider", provider);
  };

  /* ------------------------------------useEffects------------------------------------- */
  useEffect(() => {
    if (selectedTimeZone) {
      setFieldValue("timeZone", selectedTimeZone?.label);
    }
  }, [selectedTimeZone]);

  /* ---------------------------------------------------------------------------------------- */

  useEffect(() => {
    document.title = "Signup |  Web Pioneer";
  }, []);

  return (
    <div className={styles.background}>
      <Grid container className={styles.outerMostGrid}>
        <Grid item xs={12} md={7} className={styles.welcomeGrid}>
          <div>
            {/* <img src={FullImageLogo} alt="Logo" className={styles.logo} /> */}
            <FullImageLogoSvg />

            <div className={styles.welcomeLabelContainer}>
              <div className={styles.welcomeLabel}>Ready to Join</div>
              <div className={styles.welcomeLabel}>Web Pioneers?</div>
              <div className={styles.welcomeDescription}>
                Let's Get Started.
              </div>
            </div>
            <div className={styles.bannerContainer}>
              <AuthBanner className={styles.authBanner} />
            </div>
          </div>
        </Grid>

        {/* ------------------------------------Sign Up Grid------------------------------------- */}

        <Grid
          item
          xs={12}
          md={5}
          display={"flex"}
          alignItems={"center"}
          className={styles.formContainer}
        >
          <div className={classnames(styles.authContainer)}>
            <h1 className={styles.heading}>Sign Up</h1>
            <div className={styles.inputContainer}>
              <CustomInput
                placeholder={"Enter your name..."}
                heading="Name"
                isRequired
                type={"text"}
                value={values.name}
                onChange={handleChange("name")}
                onKeyDown={handleSubmit}
                error={touched.name && errors.name ? errors.name : ""}
              />
              <CustomInput
                placeholder={"Enter your email..."}
                heading="Email Address"
                isRequired
                type={"text"}
                value={values.email}
                onChange={handleChange("email")}
                onKeyDown={handleSubmit}
                error={touched.email && errors.email ? errors.email : ""}
              />
              <CustomInput
                placeholder={"Enter your password..."}
                heading="Password"
                isRequired
                type={"password"}
                showEye
                value={values.password}
                onKeyDown={handleSubmit}
                onChange={handleChange("password")}
                error={
                  touched.password && errors.password ? errors.password : ""
                }
              />

              <div style={{ position: "relative" }}>
                <div className={styles.selectionLabel}>
                  Time Zone <span>*</span>
                </div>
                <div
                  className={classnames(
                    errors.timeZone && styles.error,
                    styles.selectionContainer
                  )}
                  onClick={(e: any) => {
                    setOpenSelection(true);
                  }}
                >
                  {selectedTimeZone ? (
                    <label style={{ cursor: "pointer" }}>
                      {selectedTimeZone?.label}
                    </label>
                  ) : (
                    <label style={{ cursor: "pointer" }}>
                      Select your time zone...
                    </label>
                  )}
                </div>
                <CustomSelection
                  openSelection={openSelection}
                  setOpenSelection={setOpenSelection}
                  optionsArray={allTimeZones}
                  setSelected={setSelectedTimeZone}
                />
                <div>
                  {touched.timeZone && errors.timeZone ? (
                    <label className={styles.errorLabel}>
                      {errors?.timeZone}
                    </label>
                  ) : null}
                </div>
              </div>
            </div>

            <CustomButton
              label="Sign Up"
              containerClass={styles.loginBtn}
              labelStyle={{ cursor: "pointer" }}
              onClick={() => {
                handleSubmit();
              }}
            />
            <div className={styles.linesContainer}>
              <div className={styles.lines} />
              <div className={styles.registerLabel}>Or register with</div>
              <div className={styles.lines} />
            </div>
            <LoginSocialGoogle
              client_id={
                "620806407500-e5rn11mb39hq1km6eire8ftagellhrfm.apps.googleusercontent.com"
              }
              scope="openid profile email"
              discoveryDocs="claims_supported"
              cookie_policy="single_host_origin"
              onResolve={({ provider, data }: IResolveParams) => {
                onSuccess(data, provider);
              }}
              className="mb-3"
              onReject={(err) => {
                setGoogleloading(false);
              }}
            >
              <CustomButton
                label="Google"
                buttonClass={styles.googleBtn}
                labelStyle={{ marginLeft: "10px", cursor: "pointer" }}
                // iconLeft={GoogleIcons}
                iconStyle={styles.googleIcon}
                IconLeftSvg={GoogleIcon}
              />
            </LoginSocialGoogle>

            <label className={styles.accountCreateLabel}>
              Already have an account?
              <span onClick={() => navigate(routeConstant.login.path)}>
                Login Here
              </span>
            </label>

            <CustomButton
              label="Book an Intro Call"
              buttonClass={styles.introBtn}
              onClick={() => setOpenBooking(true)}
            />
          </div>
        </Grid>
      </Grid>
      <Footer containerStyle={styles.footer} />

      <BookAnIntro
        open={openBooking}
        setOpenConfirmation={setOpenConfirmation}
        handleClose={() => {
          setOpenBooking(false);
        }}
      />

      <ConfirmationModal
        open={openConfirmation}
        handleClose={() => {
          setOpenConfirmation(false);
        }}
        Icon={DoneIcon}
        label={"Meeting Booked Successfully!"}
        subLabel={`Your meeting confirmation and Google Meet link will be sent to your email shortly.`}
        actions={[
          {
            label: "Close",
            action: () => {
              setOpenConfirmation(false);
            },
            style: styles.close,
          },
        ]}
      />
    </div>
  );
}

export default SignUp;
