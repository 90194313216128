import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import {
  CalendarIcon2,
  DoneIcon,
  MembersIcon,
  PriorityIcon,
  ServiceIcon,
  StatusIcon,
  TasksIcon,
  TimeIcon,
  WarningIcon,
} from "assets/icons";
import classNames from "classnames";
import moment from "moment";
import { useState } from "react";
import CheckBox from "shared/components/customCheckbox";
import GetPriorty from "shared/components/getPriority";
import GetStatus from "shared/components/getStatus";
import TableMembers from "shared/components/tableMembers";
import ConfirmationModal from "shared/modals/confirmation";
import { service_enums } from "shared/utils/enum";
import styles from "./style.module.scss";
import { routeConstant } from "shared/routes/routesConstant";
import { useNavigate } from "react-router-dom";

function ProjectCard({ project }: any) {
  const navigate = useNavigate();
  const smScreen = useMediaQuery("(max-width:600px)"); // Media query for screen size
  const [checkBox, setCheckbox] = useState<boolean>(false);
  const [item, setItem] = useState<any>(project ? project : null);
  const [completed, setCompleted] = useState<boolean>(false);

  const headers = [
    { label: "Tasks", icon: <TasksIcon /> },
    { label: "Created On", icon: <TimeIcon /> },
    { label: "Service", icon: <ServiceIcon /> },
    { label: "Status", icon: <StatusIcon /> },
    { label: "Due Date", icon: <CalendarIcon2 /> },
    { label: "Priority", icon: <PriorityIcon /> },
    { label: "Members", icon: <MembersIcon /> },
  ];

  return (
    <div>
      <Accordion
        // expanded={expand}
        // onChange={() => {
        //   setExpand(!expand);
        // }}
        className={styles.accordian}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={styles.accordingSummary}
        >
          <Box
            display={completed && smScreen ? "block" : "flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"98%"}
          >
            <div style={{ display: "flex" }}>
              <CheckBox
                type={"circle"}
                checkBox={completed ? completed : checkBox}
                handleCallback={(e: any) => {
                  e.stopPropagation();
                  if (!completed) {
                    setCheckbox(!checkBox);
                  }
                }}
              />
              <label
                className={styles.heading}
                onClick={() => {
                  navigate(routeConstant.project.path);
                }}
              >
                {project?.name}
              </label>
              <div
                className={styles.tagContainer}
                style={completed || checkBox ? { background: "#dbeddb" } : {}}
              >
                <label
                  style={completed || checkBox ? { color: "#1c3829" } : {}}
                  className={styles.labelTag}
                >
                  03 Tasks
                </label>
              </div>
            </div>
            <div>
              {completed && (
                <label className={styles.completedLabel}>
                  Completed on {moment(new Date()).format("ddd, DD MMMM, YYYY")}
                </label>
              )}
            </div>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} className={styles.table}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  className={classNames(styles.tableRow, styles.tableHead)}
                >
                  {headers?.map((item, index, array) => {
                    return (
                      <TableCell
                        className={classNames(
                          styles.tableCell,
                          index === 0 && styles.firstCell,
                          index === array.length - 1 && styles.lastCell
                        )}
                      >
                        <Box display={"flex"} alignItems={"center"}>
                          <span>{item?.icon}</span>
                          <label style={{ marginLeft: "5px" }}>
                            {" "}
                            {item?.label}
                          </label>
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {item?.tasks?.map((item: any, index: any, array: any) => {
                  return (
                    <TableRow
                      id={index !== array.length - 1 ? styles.tableRowId : ""}
                      className={styles.tableRow}
                    >
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.tableBorderRight}
                      >
                        {item?.name}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.tableBorderRight}
                      >
                        {moment(item?.created_on).format("ddd, MMMM DD, YYYY")}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.tableBorderRight}
                      >
                        {item?.service === service_enums.uiux
                          ? "UI/UX"
                          : item?.service === service_enums.development
                          ? "Development"
                          : "Custom"}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.tableBorderRight}
                      >
                        <GetStatus status={item?.status} />
                      </TableCell>
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.tableBorderRight}
                      >
                        {moment(item?.due_date).format("ddd, MMMM DD, YYYY")}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.tableBorderRight}
                      >
                        <GetPriorty priority={item?.priority} />
                      </TableCell>
                      <TableCell className={styles.tableCell2}>
                        <TableMembers members={item?.members} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      <ConfirmationModal
        open={checkBox}
        handleClose={() => {
          setCheckbox(false);
        }}
        Icon={completed ? DoneIcon : WarningIcon}
        label={completed ? "Action Completed" : "Confirm Action"}
        subLabel={
          completed
            ? `“${item?.name}” has been marked as done.`
            : `Are you sure you want to mark “${item?.name}” as done?`
        }
        actions={
          completed
            ? [
                {
                  label: "Go To Projects",
                  action: () => setCheckbox(false),
                  style: styles.confirm,
                },
              ]
            : [
                {
                  label: "Cancel",
                  action: () => setCheckbox(false),
                  style: styles.cancel,
                },
                {
                  label: "Yes, Confirm.",
                  action: () => {
                    setCompleted(true);
                  },
                  style: styles.confirm,
                },
              ]
        }
      />
    </div>
  );
}

export default ProjectCard;
