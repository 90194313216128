import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { AddIcon, CrossIcon, DefaultUploadPictureIcon } from "assets/icons";
import classNames from "classnames";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CustomButton from "shared/components/customButton";
import CustomInput from "shared/components/customInput";
import { roles_enums } from "shared/utils/enum";
import { AddMemberValidation } from "shared/utils/validation";
import styles from "./style.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AddMemberProps {
  open: boolean;
  handleClose: any;
  isClient?: boolean;
  isEmployee?: boolean;
  handleCallback: any;
}

interface InitialValues {
  role: string;
  profile_picture: string;
  memberId: string;
  email: string;
  company: string;
  first_name: string;
  last_name: string;
  phone: string;
}

export default function AddMember({
  open,
  handleClose,
  isClient,
  isEmployee,
  handleCallback,
}: AddMemberProps) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<any>({
    raw: "",
    preview: "",
  });

  // const roles = [
  //   {
  //     label: "Admin",
  //     value: roles_enums.admin,
  //   },
  //   {
  //     label: "Client",
  //     value: roles_enums.client,
  //   },
  //   {
  //     label: "Employee",
  //     value: roles_enums.employee,
  //   },
  // ];

  const onClose = () => {
    handleClose();
    formik.resetForm();
    setImage({
      raw: "",
      preview: "",
    });
  };

  const initialValues: InitialValues = {
    role: isClient ? roles_enums.client.toString() : "",
    profile_picture: "",
    memberId: "",
    email: "",
    company: "",
    first_name: "",
    last_name: "",
    phone: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddMemberValidation,
    onSubmit: (value) => {
      handleCallback(value);
    },
  });

  const { handleChange, handleSubmit, values, touched, errors, setFieldValue } =
    formik;

  const handleButtonClick = () => {
    if (image?.raw) {
      setImage({
        raw: "",
        preview: "",
      });
      setFieldValue("profile_picture", "");
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    }
  };

  const handleImageChange = (e: any) => {
    let url = URL.createObjectURL(e.target.files[0]);
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setImage({
          preview: e.target.result as string,
          raw: file,
        });
        setFieldValue("profile_picture", e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          sx: {
            padding: "24px",
            maxWidth: "712px",
            width: "100%",
            justifyContent: "center",
            borderRadius: "10px",
          },
        }}
      >
        <div className={styles.upperActionContainer}>
          <div className={styles.label}>
            {isClient ? "Add New Client" : isEmployee ? "Add New Employee" : ""}
          </div>
          <div onClick={() => onClose()}>
            <CrossIcon className={styles.crossIcon} />
          </div>
        </div>

        <div className={styles.gridContainer}>
          <Grid container>
            <Grid xs={12} md={6}>
              <div className={styles.photoContainer}>
                {image?.raw ? (
                  <img
                    alt="uploaded"
                    src={image?.preview}
                    className={styles.profile_picture}
                  />
                ) : (
                  <DefaultUploadPictureIcon
                    className={styles.profile_picture}
                  />
                )}
                <button onClick={handleButtonClick} className={styles.addBtn}>
                  {image?.raw ? <CrossIcon /> : <AddIcon />}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                </button>
              </div>
              {touched.profile_picture && errors.profile_picture
                ? errors.profile_picture && (
                    <div className={styles.error}>{errors.profile_picture}</div>
                  )
                : ""}
            </Grid>
            <Grid
              xs={12}
              md={6}
              className={classNames(styles.upperRightGrid, styles.rightGrid)}
            >
              <CustomInput
                placeholder={"Enter member id"}
                heading="Member ID"
                type={"text"}
                isRequired
                value={values.memberId}
                onChange={handleChange("memberId")}
                onKeyDown={handleSubmit}
                error={
                  touched.memberId && errors.memberId ? errors.memberId : ""
                }
                stylesContainer={{ width: "100%" }}
              />
            </Grid>
            <Grid xs={12} md={6} className={styles.leftGrid}>
              <CustomInput
                placeholder={"Enter role"}
                heading="Role"
                type={"text"}
                isRequired
                disabled={isClient ? true : false}
                value={isClient ? "Client" : ""}
                onChange={() => {}}
                onKeyDown={handleSubmit}
                error={touched.role && errors.role ? errors.role : ""}
              />
            </Grid>

            <Grid xs={12} md={6} className={styles.rightGrid}>
              <CustomInput
                placeholder={"Enter company name"}
                heading="Company Name"
                type={"text"}
                isRequired
                value={values.company}
                onChange={handleChange("company")}
                onKeyDown={handleSubmit}
                error={touched.company && errors.company ? errors.company : ""}
              />
            </Grid>
            <Grid xs={12} md={6} className={styles.leftGrid}>
              <CustomInput
                placeholder={"Enter first name"}
                heading="First Name"
                type={"text"}
                isRequired
                value={values.first_name}
                onChange={handleChange("first_name")}
                onKeyDown={handleSubmit}
                error={
                  touched.first_name && errors.first_name
                    ? errors.first_name
                    : ""
                }
              />
            </Grid>
            <Grid xs={12} md={6} className={styles.rightGrid}>
              <CustomInput
                placeholder={"Enter last name"}
                heading="Last Name"
                type={"text"}
                isRequired
                value={values.last_name}
                onChange={handleChange("last_name")}
                onKeyDown={handleSubmit}
                error={
                  touched.last_name && errors.last_name ? errors.last_name : ""
                }
              />
            </Grid>
            <Grid xs={12} md={6} className={styles.leftGrid}>
              <CustomInput
                placeholder={"Enter email"}
                heading="Email Address"
                isRequired
                type={"text"}
                value={values.email}
                onChange={handleChange("email")}
                onKeyDown={handleSubmit}
                error={touched.email && errors.email ? errors.email : ""}
              />
            </Grid>
            <Grid xs={12} md={6} className={styles.rightGrid}>
              <label className={styles.inputLabel}>
                Phone 
              </label>
              <div
                className={
                  touched.phone && errors.phone
                    ? classNames(styles.phoneInput, styles.errorBorder)
                    : classNames(styles.phoneInput)
                }
              >
                <PhoneInput
                  placeholder="Enter phone number"
                  value={values.phone}
                  onChange={(e: any) => {
                    handleChange("phone");
                    setFieldValue("phone", e);
                  }}
                />
              </div>
              {touched.phone && errors.phone
                ? errors.phone && (
                    <div className={styles.error}>{errors.phone}</div>
                  )
                : ""}
            </Grid>
          </Grid>
          <div className={styles.buttonContainer}>
            <CustomButton
              label={"Cancel"}
              onClick={onClose}
              buttonClass={classNames(styles.cancel, styles.cancelEdit)}
            />
            <CustomButton
              label={"Save"}
              onClick={() => {
                handleSubmit();
              }}
              buttonClass={classNames(styles.confirm, styles.saveEdit)}
            />
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
