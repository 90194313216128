import { Box, Skeleton } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  DeleteIcon,
  EmailIcon,
  MemberTableIcon,
  MessageIcon,
  PhoneIcon,
  PlusIcon,
  ProjectTableIcon,
  SingleMemberIcon,
  StatusIcon,
  WarningIcon,
} from "assets/icons";
import { Sample1, Sample2, Sample3, Sample4 } from "assets/sample";
import classNames from "classnames";
import { useEffect, useState } from "react";
import CustomButton from "shared/components/customButton";
import CustomFilter from "shared/components/customFilterTab";
import UsersAction from "shared/components/pages/clients/userActions";
import SearchBar from "shared/components/searchBar";
import AddMember from "shared/modals/addMember";
import ConfirmationModal from "shared/modals/confirmation";
import { user_status_enums } from "shared/utils/enum";
import styles from "./style.module.scss";
import SendInvite from "shared/modals/sendInvite";
import { routeConstant } from "shared/routes/routesConstant";
import { useNavigate } from "react-router-dom";

const clientsDummy = [
  {
    id: 1,
    name: "Justin Trevor",
    profile_picture: Sample1,
    email: "Justintrevor@gmail.com",
    phone: "+921234765572",
    project: 1,
    memberId: "CLT2201",
    status: 1,
  },
  {
    id: 2,
    name: "Jacob Peralta",
    profile_picture: Sample2,
    email: "jake1@gmail.com",
    phone: "+921233435572",
    project: 1,
    memberId: "CLT2204",
    status: 1,
  },
  {
    id: 3,
    name: "Rosa Diaz",
    profile_picture: Sample3,
    email: "diaz1@gmail.com",
    phone: "+9212334321272",
    project: 1,
    memberId: "CLT2202",
    status: 0,
  },
  {
    id: 4,
    name: "Charles Xaviar",
    profile_picture: Sample4,
    email: "xcharles@gmail.com",
    phone: "+9212334321172",
    project: 1,
    memberId: "CLT2211",
    status: 0,
  },
];

function Clients() {
  const navigate = useNavigate();
  const [statusSelection, setStatusSelection] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [addMemberOpen, setAddMemberOpen] = useState<boolean>(false);
  const [clients, setClients] = useState<any>(clientsDummy);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const [openInvite, setOpenInvite] = useState<boolean>(false);

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  const menuItems = [
    {
      label: "• Active",
      id: 1,
      value: user_status_enums.active,
      styles: styles.active,
    },
    {
      label: "• Inactive",
      id: 2,
      value: user_status_enums.inactive,
      styles: styles.inactive,
    },
  ];

  const headers = [
    { label: "Clients", icon: <SingleMemberIcon /> },
    { label: "Email", icon: <EmailIcon /> },
    { label: "Phone", icon: <PhoneIcon /> },
    { label: "Projects", icon: <ProjectTableIcon /> },
    { label: "Member ID", icon: <MemberTableIcon /> },
    { label: "Status", icon: <StatusIcon /> },
    { label: "Action" },
  ];

  const handleAddMember = (member: any) => {
    let temp = [...clients];
    temp.push({
      ...member,
      status: 0,
      name: member.first_name + " " + member?.last_name,
      project: 0,
      id: clients?.length + 1,
    });
    setClients(temp);
    setAddMemberOpen(false);
    setOpenInvite(true);
    setSelected({
      ...member,
      name: member.first_name + " " + member?.last_name,
    });
  };

  const handleDelete = (item: any) => {
    let temp = clients.filter((itm: any) => item?.id !== itm?.id);
    setClients(temp);
    setOpenConfirmation(false);
    setSelected(null);
  };

  const actionItems: any = [
    {
      label: "Chat",
      icon: <MessageIcon />,
      styles: styles.menuItemChat,
      action: () => {},
    },
    {
      label: "Delete",
      icon: <DeleteIcon />,
      styles: styles.menuItemDelete,
      action: (item: any) => {
        setOpenConfirmation(true);
        setSelected(item);
      },
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.headingContainer2}>
          <div>
            <div className={styles.heading}>Clients</div>
            <div className={styles.subHeading}>Manage your clients</div>
          </div>
        </div>
        <CustomButton
          buttonClass={styles.btn}
          label={"New Client"}
          labelClass={styles.label}
          IconRightSvg={PlusIcon}
          containerClass={styles.btnContainer}
          onClick={() => {
            setAddMemberOpen(true);
          }}
        />
      </div>
      <div className={styles.actionContainer}>
        <div style={{ display: "flex" }}>
          <CustomFilter
            selection={statusSelection}
            setSelection={setStatusSelection}
            selectionLabel={statusSelection ? statusSelection?.label : null}
            label={"Status"}
            menuItems={menuItems}
          />
          <div
            className={styles.resetBtn}
            onClick={() => setStatusSelection(null)}
          >
            Reset
          </div>
        </div>
        <div className={styles.searchBarContainer}>
          <SearchBar />
        </div>
      </div>
      <div className={styles.tableMainContainer}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none" }}
          className={styles.table}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow
                className={classNames(styles.tableRow, styles.tableHead)}
              >
                {headers?.map((item, index, array) => {
                  return (
                    <TableCell
                      sx={
                        index === array.length - 1
                          ? {
                              display: "flex",
                              justifyContent: "center",
                              borderBottom: "none",
                            }
                          : {
                              borderBottom: "none",
                            }
                      }
                      className={classNames(
                        styles.tableCell,
                        index === 0 && styles.firstCell,
                        index === array.length - 1 && styles.lastCell
                      )}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <span style={{ display: "flex" }}>{item?.icon}</span>
                        <label style={{ marginLeft: "5px" }}>
                          {" "}
                          {item?.label}
                        </label>
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <Loader />
              ) : (
                clients?.map((itm: any, index: any, array: any) => {
                  return (
                    <TableRow
                      id={index !== array.length - 1 ? styles.tableRowId : ""}
                      className={styles.tableRow}
                    >
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.tableBorderRight}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            routeConstant.client.path.replace(":id", "1")
                          )
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            alt="profile_pic"
                            src={itm?.profile_picture}
                            className={styles.profile_pic}
                          />
                          <label
                            style={{ marginLeft: "5%", cursor: "pointer" }}
                          >
                            {itm?.name}
                          </label>
                        </div>
                      </TableCell>
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.tableBorderRight}
                      >
                        {itm?.email}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.tableBorderRight}
                      >
                        {itm?.phone}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.tableBorderRight}
                      >
                        {itm?.project}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.tableBorderRight}
                      >
                        {itm?.memberId}
                      </TableCell>

                      <TableCell
                        className={classNames(styles.tableCell2)}
                        id={styles.tableBorderRight}
                      >
                        <div
                          className={
                            user_status_enums.active === itm?.status
                              ? styles.active
                              : user_status_enums.inactive === itm?.status
                              ? styles.deactive
                              : ""
                          }
                        >
                          {user_status_enums.active === itm?.status
                            ? "Active"
                            : user_status_enums.inactive === itm?.status
                            ? "Inactive"
                            : ""}
                        </div>
                      </TableCell>
                      <TableCell
                        className={styles.tableCell2}
                        id={styles.lastCell}
                      >
                        <UsersAction item={itm} actionItems={actionItems} />
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {addMemberOpen && (
        <AddMember
          open={addMemberOpen}
          handleClose={() => {
            setAddMemberOpen(false);
          }}
          isClient={true}
          handleCallback={handleAddMember}
        />
      )}
      <ConfirmationModal
        open={openConfirmation}
        handleClose={() => {
          setOpenConfirmation(false);
          setSelected(null);
        }}
        Icon={WarningIcon}
        label={"Confirm Action"}
        subLabel={`Are you sure you want to delete ${selected?.name}?`}
        actions={[
          {
            label: "Cancel",
            action: () => {
              setOpenConfirmation(false);
              setSelected(null);
            },
            style: styles.cancel,
          },
          {
            label: "Yes, Delete.",
            action: () => handleDelete(selected),
            style: styles.confirm,
          },
        ]}
      />
      <SendInvite
        open={openInvite}
        handleClose={() => {
          setOpenInvite(false);
          setSelected(null);
        }}
        name={selected?.name}
        role={"Client"}
        email={selected?.email}
      />
    </div>
  );
}

export default Clients;

const Loader = () => {
  return (
    <>
      {Array.from(Array(5).keys()).map((_, index, array) => (
        <TableRow
          key={index}
          id={index !== array.length - 1 ? styles.tableRowId : ""}
          className={styles.tableRow}
        >
          <TableCell className={styles.tableCell2} id={styles.tableBorderRight}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Skeleton variant="circular" width={30} height={30} />
              <label style={{ marginLeft: "5%" }}>
                <Skeleton variant="rectangular" width={130} height={25} />
              </label>
            </div>
          </TableCell>
          <TableCell className={styles.tableCell2} id={styles.tableBorderRight}>
            <Skeleton variant="rectangular" width={80} height={25} />
          </TableCell>
          <TableCell className={styles.tableCell2} id={styles.tableBorderRight}>
            <Skeleton variant="rectangular" width={80} height={25} />
          </TableCell>
          <TableCell className={styles.tableCell2} id={styles.tableBorderRight}>
            <Skeleton variant="rectangular" width={50} height={25} />
          </TableCell>
          <TableCell className={styles.tableCell2} id={styles.tableBorderRight}>
            <Skeleton variant="rectangular" width={50} height={25} />
          </TableCell>
          <TableCell className={styles.tableCell2} id={styles.tableBorderRight}>
            <Skeleton variant="rectangular" width={50} height={25} />
          </TableCell>
          <TableCell
            className={styles.tableCell2}
            id={styles.lastCell}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Skeleton variant="rectangular" width={50} height={25} />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
