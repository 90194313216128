import { Grid } from "@mui/material";
import { Sample1, Sample2, Sample3 } from "assets/sample";
import { useState } from "react";
import ChannelCard from "shared/components/pages/channel/channelCard";
import styles from "./styles.module.scss";

const dummy: any = [
  {
    title: "Fatfish",
    members: [
      {
        id: 1,
        name: "Jacob Janson",
        profile_picture: Sample1,
        role: 1,
        status: 1,
      },
      {
        id: 2,
        name: "Kate Wilson",
        profile_picture: Sample2,
        role: 2,
        emp_role: 2,
        status: 2,
      },
      { id: 3, name: "Jake", profile_picture: Sample3, role: 3, status: 1 },
    ],
  },
  {
    title: "Multiverse X",
    members: [
      {
        id: 1,
        name: "Jacob Janson",
        profile_picture: Sample1,
        role: 1,
        status: 1,
      },
      {
        id: 2,
        name: "Kate Wilson",
        profile_picture: Sample2,
        role: 2,
        emp_role: 2,
        status: 2,
      },
      { id: 3, name: "Jake", profile_picture: Sample3, role: 3, status: 1 },
    ],
  },
  {
    title: "Tronis",
    members: [
      {
        id: 1,
        name: "Jacob Janson",
        profile_picture: Sample1,
        role: 1,
        status: 1,
      },
      {
        id: 2,
        name: "Kate Wilson",
        profile_picture: Sample2,
        role: 2,
        emp_role: 1,
        status: 2,
      },
      { id: 3, name: "Jake", profile_picture: Sample3, role: 3, status: 1 },
    ],
  },
];

function Channels() {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [openInvite, setOpenInvite] = useState<any>(null);
  const [selectedChannel, setSelectedChannel] = useState<any>(null);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.headingContainer2}>
          <div>
            <div className={styles.heading}>Channels</div>
            <div className={styles.subHeading}>Project-Specific Channels</div>
          </div>
        </div>
        <div />
      </div>

      <Grid container className={styles.projectsContainer}>
        {dummy?.map((item: any, index: any) => {
          return (
            <Grid item xs={12} md={6}>
              <ChannelCard index={index} channel={item} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default Channels;
