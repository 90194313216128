import React, { useState } from "react";
import styles from "./style.module.scss";
import { FullImageLogo } from "assets/images";
import CustomInput from "shared/components/customInput";
import { useFormik } from "formik";
import { ForgetPasswordValidation } from "shared/utils/validation";
import CustomButton from "shared/components/customButton";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import Footer from "shared/components/footer";
import { CircleTick } from "assets/icons";

// Interface for initial form values
interface InitialValues {
  email: string;
}

function ForgetPassword() {
  const navigate = useNavigate();
  /* ------------------------------------State variables------------------------------------- */
  const [linkSent, setLinkSent] = useState<boolean>(false);
  /* ---------------------------------------------------------------------------------------- */

  /* ------------------------------------Formik handling------------------------------------- */

  const initialValues: InitialValues = {
    email: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: ForgetPasswordValidation,
    onSubmit: (value) => {
      console.log("value", value);
      setLinkSent(true);
    },
  });

  const { handleChange, handleSubmit, values, touched, errors, setFieldValue } =
    formik;

  /* ---------------------------------------------------------------------------------------- */

  return (
    <>
      <div className={styles.outerMostContainer}>
        <img src={FullImageLogo} alt="Logo" className={styles.logo} />
        <div className={styles.mainContainer}>
          {!linkSent ? (
            <>
              <div className={styles.heading}>Reset Your Password</div>
              <div className={styles.subHeading}>
                Enter your registered email, and we'll send you a reset link.
              </div>
              <CustomInput
                placeholder={"Enter your email..."}
                heading="Email Address"
                isRequired
                type={"text"}
                value={values.email}
                onChange={handleChange("email")}
                error={touched.email && errors.email ? errors.email : ""}
                onKeyDown={handleSubmit}
              />
              <CustomButton
                label="Send Reset Link"
                containerClass={styles.btn}
                labelStyle={{ cursor: "pointer" }}
                onClick={() => {
                  handleSubmit();
                }}
              />
              <CustomButton
                label="Back To Login"
                buttonClass={styles.backBtn}
                labelStyle={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(routeConstant.login.path);
                }}
              />
            </>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={CircleTick}
                  className={styles.circledTick}
                  alt="success"
                />
              </div>
              <div className={styles.heading}>Reset Link Sent Successfully</div>
              <div className={styles.subHeading}>
                Check your inbox for further instructions.
              </div>
              <CustomButton
                label="Back To Login"
                buttonClass={styles.backBtn}
                labelStyle={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(routeConstant.login.path);
                }}
              />
            </>
          )}
        </div>
      </div>
      <Footer containerStyle={styles.footer} />
    </>
  );
}

export default ForgetPassword;
