import { Box, Menu, MenuItem } from "@mui/material";
import {
  DownloadIcon,
  DropDownPointerIcon,
  FileIcon,
  PaperClipIcon,
  SendIcon,
} from "assets/icons";
import classNames from "classnames";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import { Sample2 } from "assets/sample";

function MessagesList({ messages, height }: any) {
  const [newMessage, setNewMessage] = useState<any>("");
  const [allMessages, setAllMessages] = useState<any>(messages);
  const endOfMessagesRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    ShowLastMessage();
  }, [allMessages]);

  const sendNewMessage = () => {
    let temp = [...allMessages];
    temp.push({
      create_at: new Date(),
      sender_id: 2,
      user: {
        profile_picture: Sample2,
        name: "Zoheb",
      },
      message: newMessage,
    });
    setAllMessages(temp);
    ShowLastMessage();
    setNewMessage("");
  };

  const ShowLastMessage = () => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView();
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <div className={styles.name}>Robert Andreson</div>
        <div className={styles.lastSeen}>Last Active 09:40</div>
      </div>

      <div
        className={styles.messagesContainer}
        // style={{
        //   height: `${getHeight()}px`,
        // }}
      >
        {allMessages?.map((item: any, inx: any, arr: any) => {
          const currentDate = moment(item.create_at).format("DD MMM YYYY");
          const prevMsgDate =
            inx - 1 >= 0
              ? moment(arr[inx - 1].create_at).format("DD MMM YYYY")
              : null;
          const showDate = currentDate !== prevMsgDate;

          return (
            <div>
              {showDate && (
                <div className={styles.dateStamp}>
                  <label>
                    {moment(item?.create_at).format("dddd, MMM DD, YYYY")}
                  </label>
                  <div className={styles.line} />
                </div>
              )}

              <Box className={styles.innerContainer}>
                <Box display={"flex"}>
                  <img
                    src={item?.user?.profile_picture}
                    alt="profile_pic"
                    className={styles.profile_pic}
                  />

                  <div className={styles.contentContainer}>
                    <label className={styles.name}>{item?.user?.name}</label>
                    <span
                      className={classNames(styles.timeStamp)}
                      style={{ margin: "0 5px" }}
                    >
                      •
                    </span>
                    <label className={classNames(styles.timeStamp)}>
                      1:40 PM
                    </label>
                    <div className={styles.message}>
                      {item?.message
                        ?.split(/\r\n|\r|\n/)
                        .map((itm: any, index: any) => (
                          <Fragment key={index}>
                            {itm}
                            <br />
                          </Fragment>
                        ))}
                    </div>

                    {item?.file && (
                      <div className={styles.file}>
                        <div className={styles.fileInnerContainer}>
                          <div className={styles.fileIconContainer}>
                            <FileIcon />
                          </div>
                          <div style={{ marginLeft: "10px", width: "100%" }}>
                            <div className={styles.name}>
                              {item?.file_info?.file_name}.
                              {item?.file_info?.file_type}
                            </div>
                            <div className={styles.size}>25 MB</div>
                          </div>
                          <div>
                            <button
                              id="basic-button"
                              aria-controls={open ? "basic-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={handleClick}
                              className={styles.fileOptionsBtn}
                            >
                              <DropDownPointerIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    <div ref={endOfMessagesRef} />
                  </div>
                </Box>
              </Box>
            </div>
          );
        })}
      </div>

      <div className={styles.mainSendContainer}>
        <div className={styles.sendContainer}>
          <input
            className={styles.sendInput}
            placeholder="Type something..."
            value={newMessage}
            onChange={(e: any) => {
              setNewMessage(e.target.value);
            }}
            onKeyDown={(e: any) => {
              if (e.key === "Enter" && newMessage?.length) sendNewMessage();
            }}
          />
          <PaperClipIcon />
          <button
            className={styles.sendIconBtn}
            onClick={() => sendNewMessage()}
          >
            <SendIcon />
          </button>
        </div>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid #e2e2e2",
            padding: "0px 10px",
          },
        }}
      >
        <MenuItem onClick={handleClose}>
          <DownloadIcon style={{ marginRight: "10px" }} />
          Download
        </MenuItem>
      </Menu>
    </div>
  );
}

export default MessagesList;
