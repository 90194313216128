import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import BackButton from "shared/components/backButton";
import { routeConstant } from "shared/routes/routesConstant";
import ProfileCard from "shared/components/profileCard";
import styles from "./style.module.scss";
import EmployeeSettings from "shared/components/pages/employee/employeeSettings";

function Employee() {
  const smallScreen = useMediaQuery("(max-width:940px)"); // Media query for screen size

  return (
    <>
      <BackButton
        label={"Profile Details"}
        navigate={routeConstant.clients.path}
      />
      <Grid container height={"90%"} marginBottom={"120px"}>
        <Grid
          item
          xs={smallScreen ? 12 : 3}
          className={styles.profileCard}
        >
          <ProfileCard />
        </Grid>
        <Grid item xs={smallScreen ? 12 : 9} className={styles.gridCard}>
          <EmployeeSettings />
        </Grid>
      </Grid>
    </>
  );
}

export default Employee;
