import { Box, Tab, Tabs } from "@mui/material";
import { SearchIcon } from "assets/icons";
import { Sample1, Sample2, Sample3, Sample4 } from "assets/sample";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChatListRowLoader from "shared/components/pages/dashboard/chatListRowLoader";
import useDebounce from "shared/customHooks/useDebounce";
import { routeConstant } from "shared/routes/routesConstant";
import styles from "./style.module.scss";

function MessagesTab() {
  return (
    <div className={styles.mainContainer}>
      <MessagesList />
    </div>
  );
}

export default MessagesTab;

const MessagesList = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
    // eslint-disable-next-line
  const [searchVal, setSearchVal] = useState<string>("");
  const [value, setValue] = useState<any>(0);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useDebounce(
    () => {
      setSearchVal(search);
    },
    [search],
    800
  );

  const tabs = [
    {
      value: 0,
      label: "All",
    },
    {
      value: 1,
      label: "Unread",
    },
  ];

  const dummy = [
    {
      profilePic: Sample1,
      name: "Emma Olivia",
      lastMsg:
        "I'll do that task now, you can check it later on the live site by noon",
      newMsgCounter: 2,
      time: new Date(),
      online: true,
    },
    {
      profilePic: Sample2,
      name: "Robert Anderson",
      lastMsg: "Here is UX research Document, you were asking for.",
      newMsgCounter: 0,
      time: new Date(),
      online: false,
    },
    {
      profilePic: Sample3,
      name: "Davis Rosser",
      lastMsg: "If you complete Webdesign for the page you were asking for",
      newMsgCounter: 1,
      time: new Date(),
      online: false,
    },
    {
      profilePic: Sample4,
      name: "Emerson Levin",
      lastMsg: "Find out who is in charge of this and get back to me soon.",
      newMsgCounter: 0,
      time: new Date(),
      online: true,
    },
  ];

  return (
    <>
      <div className={styles.heading}>Messages</div>
      <div className={styles.subHeading}>Team and client messages</div>

      <div className={styles.searchContainer}>
        <SearchIcon />
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search anything"
        />
      </div>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          className={styles.tab}
          TabIndicatorProps={{
            style: {
              backgroundColor: "black",
            },
          }}
        >
          {tabs?.map((item: any, index: any) => {
            return (
              <Tab
                label={item?.label}
                className={classNames(
                  styles.labelTab,
                  value === item?.value && styles.active
                )}
              />
            );
          })}
        </Tabs>
      </Box>

      <Box marginTop={"16px"} className={styles.chatList}>
        {/* {Array.from(Array(4).keys()).map((itm, inx) => { */}
        {loader ? (
          <ChatListRowLoader />
        ) : (
          dummy.map((itm, inx) => {
            return (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                className={styles.singleChatContainer}
                onClick={() => {
                  navigate(routeConstant?.messages?.path);
                }}
              >
                <Box display={"flex"}>
                  <div
                    style={{ position: "relative" }}
                    className={styles.imgContainer}
                  >
                    <img
                      src={itm?.profilePic}
                      className={styles.profileListPic}
                      alt="profile"
                    />
                    {itm?.online && (
                      <div className={styles.onlineChat}>
                        <div className={styles.online} />
                      </div>
                    )}
                  </div>
                  <div>
                    <div className={styles.nameList}>
                      {itm?.name}
                      <span className={styles.timeList}>• Just now</span>
                    </div>
                    <div className={styles.lastMsg}>{itm?.lastMsg}</div>
                  </div>
                </Box>

                {itm?.newMsgCounter ? (
                  <div className={styles.newMsgCounter}>
                    {itm?.newMsgCounter}
                  </div>
                ) : null}
              </Box>
            );
          })
        )}
      </Box>
    </>
  );
};
