import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import {
  CalendarIcon2,
  DeleteIcon2,
  DropDownIcon,
  EditIcon,
  MessageIcon,
} from "assets/icons";
import classNames from "classnames";
import moment from "moment";
import { useState } from "react";
import styles from "./style.module.scss";

function TasksContainer() {
  const [expanded, setExpanded] = useState<boolean | false>(true);

  const tasks = [
    {
      label: "Redesign Home UI",
      created: new Date(),
    },
    {
      label: "Custom About Page",
      created: new Date(),
    },
  ];

  return (
    <div>
      <Accordion
        className={styles.accordian}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary className={styles.accordianSummay}>
          <div
            className={classNames(
              styles.expandIconWrapper,
              styles.accordianSummayContainer
            )}
          >
            <DropDownIcon
              className={
                expanded
                  ? classNames(styles.icon, styles.iconReverse)
                  : classNames(styles.icon)
              }
            />
            <label className={styles.tasksHeading}>Tasks</label>
            <div className={styles.tagContainer}>
              <label className={styles.labelTag}>02</label>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {tasks?.map((item) => {
            return <TaskCard item={item} />;
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default TasksContainer;

const TaskCard = ({ item }: any) => {
  const [expanded, setExpanded] = useState<boolean | false>(false);

  return (
    <Accordion
      className={classNames(styles.accordian, styles.taskAccordian)}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary>
        <div
          className={classNames(styles.expandIconWrapper)}
          style={{ width: "100%" }}
        >
          <DropDownIcon
            className={
              expanded
                ? classNames(styles.icon, styles.iconReverse)
                : classNames(styles.icon)
            }
          />
          <div className={styles.singleTaskContainer}>
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "4px",
                flexDirection: "column",
              }}
            >
              <label className={styles.taskName}>{item?.label}</label>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <CalendarIcon2 />{" "}
                <label className={styles.taskDate}>
                  Due Date: {moment(item?.created).format("MMM D, YYYY")}
                </label>
              </div>
            </div>
            <div className={styles.iconsContainer}>
              <EditIcon />
              <DeleteIcon2 />
              <MessageIcon />
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.taskDescription}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
