import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  CalendarIcon2,
  DeleteIcon,
  DescriptionTableIcon,
  DollarIcon2,
  DollarIconFilled,
  SingleMemberIcon,
  TagIcon,
  TimerIcon,
} from "assets/icons";
import classNames from "classnames";
import moment from "moment";
import { useState } from "react";
import TableMembers from "shared/components/tableMembers";
import { service_enums } from "shared/utils/enum";
import styles from "./style.module.scss";

function TrackerCard({ item }: any) {
  const [expanded, setExpanded] = useState<boolean>(true);
  return (
    <Accordion
      className={styles.mainAccordianContainer}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        className={styles.accordianSummary}
        id="panel1-header"
        sx={{
          "& .MuiAccordionSummary-content": {
            display: "flex",
            alignItems: "center",
            margin: "0px !important",
            padding: "18px",
            borderBottom: expanded ? "1px solid #eeeeee" : "none",
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            position: "absolute",
            left: "15px",
          },
          "& .MuiAccordionSummary-root ": {
            padding: "0px !important",
          },
        }}
      >
        <div className={styles.accordianInnerContainer}>
          <div className={styles.accordianNameContainer}>
            <label className={styles.projectName}>{item?.name}</label>
            <div className={styles.tagContainer}>
              <label className={styles.labelTag}>
                {item?.number_of_tasks} Tasks
              </label>
            </div>
          </div>
          <div>
            <label className={styles.smallHeading}>Total:</label>
            <label className={styles.totalTime}>07:20:00</label>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0px" }}>
        {item?.tasks?.map((itm: any, inx: any, array: any) => {
          return <TaskCard itm={itm} index={inx} array={array} />;
        })}
      </AccordionDetails>
    </Accordion>
  );
}

export default TrackerCard;

const TaskCard = ({ itm, index, array }: any) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const headers = [
    { label: "Description", icon: <DescriptionTableIcon /> },
    { label: "Members", icon: <SingleMemberIcon /> },
    { label: "Date", icon: <CalendarIcon2 /> },
    { label: "Billability" },
    { label: "Time", icon: <TimerIcon /> },
    { label: "Action" },
  ];

  return (
    <Accordion
      className={classNames(
        styles.taskAccordianContainer,
        index !== 0 && expanded && styles.topBorder
      )}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        className={styles.accordianSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          "& .MuiAccordionSummary-content": {
            display: "flex",
            alignItems: "center",
            margin: "0px",
            padding: "18px",
            borderBottom: expanded ? "1px solid #eeeeee" : "none",
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            position: "absolute",
            left: "15px",
          },
          "& .MuiAccordionSummary-root ": {
            padding: "0px !important",
          },
        }}
      >
        <div className={styles.accordianInnerContainer}>
          <div className={styles.accordianNameContainer}>
            <label className={styles.taskName}>{itm?.name}</label>
            <div className={styles.iconContainer}>
              <TagIcon />{" "}
              <label className={styles.serviceName}>
                {itm?.service === service_enums?.development
                  ? "Development"
                  : itm?.service === service_enums?.uiux
                  ? "UI/UX"
                  : itm?.service === service_enums?.custom
                  ? "Custom"
                  : ""}
              </label>
            </div>
          </div>
          <div>
            <label className={styles.smallHeading}>Total:</label>
            <label className={styles.totalTaskTime}>07:20:00</label>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0px" }}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none" }}
          className={styles.table}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers?.map((item, index, array) => {
                  return (
                    <TableCell
                      sx={
                        index === array.length - 1
                          ? {
                              display: "flex",
                              justifyContent: "center",
                              borderBottom: "none",
                            }
                          : {
                              borderBottom: "none",
                            }
                      }
                      className={classNames(
                        styles.tableCell,
                        index === 0 && styles.firstCell,
                        index === array.length - 1 && styles.lastCell
                      )}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        sx={!item?.icon ? { justifyContent: "center" } : {}}
                      >
                        <span style={{ display: "flex" }}>{item?.icon}</span>
                        <label style={{ marginLeft: "5px" }}>
                          {" "}
                          {item?.label}
                        </label>
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                id={index !== array.length - 1 ? styles.tableRowId : ""}
                className={styles.tableRow}
              >
                <TableCell
                  className={styles.tableCell2}
                  id={styles.tableBorderRight}
                  style={{ cursor: "pointer" }}
                >
                  {itm?.name}
                </TableCell>
                <TableCell
                  className={styles.tableCell2}
                  id={styles.tableBorderRight}
                >
                  <TableMembers members={itm?.members} />
                </TableCell>
                <TableCell
                  className={styles.tableCell2}
                  id={styles.tableBorderRight}
                >
                  {moment(itm?.created_on).format("ddd, MMMM DD, YYYY")}
                </TableCell>
                <TableCell
                  className={styles.tableCell2}
                  id={styles.tableBorderRight}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {itm?.billabilty ? <DollarIcon2 /> : <DollarIconFilled />}
                  </Box>
                </TableCell>
                <TableCell
                  className={styles.tableCell2}
                  id={styles.tableBorderRight}
                >
                  {moment(itm?.due_date).format("ddd, MMMM DD, YYYY")}
                </TableCell>

                <TableCell
                  className={classNames(styles.tableCell2)}
                  id={styles.tableBorderRight}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <DeleteIcon />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};
