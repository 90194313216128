import { Grid } from "@mui/material";
import styles from "./style.module.scss";
import classNames from "classnames";

function Footer({ containerStyle }: any) {
  return (
    <Grid
      color="primary"
      sx={{ top: "auto", background: "#FAFAFA" }}
      container
      className={classNames(
        styles.mainContainer,
        containerStyle && containerStyle
      )}
    >
      <div className={styles.footerCopyright}>
        © 2024 Web Pioneers . All rights reserved.
      </div>
      <div className={styles.footerLabel}>Terms & Conditions</div>
      <div className={styles.footerLabel}>Privacy Policy</div>
    </Grid>
  );
}

export default Footer;
