import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DropDownIcon, PlayCircleIcon } from "assets/icons";
import * as React from "react";
import styles from "./../style.module.scss";

export default function AddTimeTracker() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={styles.timeLabel}
      >
        <div
          style={{
            display: "flex",
            gap: "14px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "14px",
              alignItems: "center",
            }}
          >
            <PlayCircleIcon /> <div className={styles.paddingBorder} />{" "}
            <label>00:00:00</label>
          </div>
          <div>
            <DropDownIcon />
          </div>
        </div>
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </div>
  );
}
