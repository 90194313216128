import React from "react";
import styles from "./style.module.scss";
import { priority_enums } from "shared/utils/enum";

function GetPriority({ priority }: any) {
  return (
    <div
      id={
        priority_enums.high === priority
          ? styles.high
          : priority_enums.low === priority
          ? styles.low
          : priority_enums.medium === priority
          ? styles.medium
          : ""
      }
    >
      {priority_enums?.high === priority ? (
        <span>High</span>
      ) : priority_enums?.low === priority ? (
        <span>Low</span>
      ) : priority_enums.medium === priority ? (
        <span>Medium</span>
      ) : null}
    </div>
  );
}

export default GetPriority;
