import { Menu, MenuItem } from "@mui/material";
import { DropDownIcon } from "assets/icons";
import React from "react";
import CustomButton from "shared/components/customButton";
import styles from "./style.module.scss";
import classNames from "classnames";

interface CustomFilterProps {
  selection: any;
  setSelection: any;
  label: any;
  menuItems: any;
}

function FilterTab({
  selection,
  setSelection,
  label,
  menuItems,
}: CustomFilterProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <CustomButton
        label={selection ? selection?.label : label}
        IconLeftSvg={selection ? selection?.icon : null}
        IconRightSvg={DropDownIcon}
        iconLeftStyle={{ marginRight: "10px" }}
        iconStyle={styles.icon}
        buttonClass={styles.btn}
        onClick={handleClick}
        labelClass={selection ? selection?.styles : ""}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid #e2e2e2",
            padding: "0px 10px",
          },
        }}
      >
        {menuItems?.map((item: any) => {
          const Icon = item?.icon;

          return (
            <MenuItem
              onClick={() => {
                handleClose();
                setSelection(item);
              }}
            >
              <div
                className={
                  item?.styles
                    ? classNames(item?.styles, styles?.menuItems)
                    : classNames(styles?.menuItems)
                }
              >
                {Icon && <Icon style={{ marginRight: "10px" }} />} {item?.label}
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

export default FilterTab;
