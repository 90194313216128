const routeConstant = {
  login: {
    path: "/",
    title: "Login",
  },
  signUp: {
    path: "/register",
    title: "Register",
  },
  forgetPassword: {
    path: "/forget-password",
    title: "Forgot Password",
  },
  updatePassword: {
    path: "/update-password",
    title: "Update Password",
  },
  dashboard: {
    path: "/",
    title: "Dashboard",
  },
  projects: {
    path: "/projects",
    title: "Projects",
  },
  channels: {
    path: "/channels",
    title: "Channels",
  },
  clients: {
    path: "/clients",
    title: "Clients",
  },
  messages: {
    path: "/messages",
    title: "Messages",
  },
  services: {
    path: "/services",
    title: "Services",
  },
  employees: {
    path: "/employees",
    title: "Employees",
  },
  timeTracker: {
    path: "/time-tracker",
    title: "Time Tracker",
  },
  meetings: {
    path: "/meetings",
    title: "Mettings",
  },
  settings: {
    path: "/settings/:keyword",
    title: "Settings",
  },
  notification: {
    path: "/notifications",
    title: "Notifications",
  },
  search: {
    path: "/search",
    title: "Search",
  },
  requests: {
    path: "/requests/:id",
    title: "Requests",
  },
  client: {
    path: "/client/:id",
    title: "Client",
  },
  employee: {
    path: "/employee/:id",
    title: "Employee",
  },
  editService: {
    path: "/editservice",
    title: "Service",
  },
  project: {
    path: "/project",
    title: "Project",
  },
  task: {
    path: "/task",
    title: "Task",
  },
};
export { routeConstant };
