import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { CrossIcon, EmailIcon, WelcomeTabIcon2 } from "assets/icons";
import classNames from "classnames";
import React, { useState } from "react";
import BookingTimings from "shared/components/pages/bookAnInto/bookingTiming";
import styles from "./style.module.scss";
import BookingForm from "shared/components/pages/bookAnInto/bookingForm";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface BookAnIntroProps {
  open: boolean;
  handleClose: any;
  setOpenConfirmation: any;
}

export default function BookAnIntro({
  open,
  handleClose,
  setOpenConfirmation,
}: BookAnIntroProps) {
  const [selectedTime, setSelectedTime] = useState<any>(null);

  const onClose = () => {
    handleClose();
    setSelectedTime(null);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            padding: "24px",
            width: "100%",
            justifyContent: "center",
            maxWidth: (theme) => theme.spacing(selectedTime ? 120 : 160),
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent className={styles.content}>
          {!selectedTime ? (
            <>
              <div className={styles.upperActionContainer}>
                <div className={styles.label}>Schedule Your Call</div>
                <div onClick={() => onClose()}>
                  <CrossIcon className={styles.crossIcon} />
                </div>
              </div>

              <Grid container spacing={2} sx={{ marginTop: "24px" }}>
                <Grid item xs={12} md={8}>
                  <BookingTimings
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <div>
                    <div className={styles.singleInfoContainer}>
                      <label className={styles.contactInfo}>
                        🇺🇸 +1 (631) 530-2666
                      </label>
                      <label className={styles.contactInfo}>
                        🇵🇰 +92 305 406 3 572
                      </label>
                      <label className={styles.contactInfo}>
                        <EmailIcon style={{ marginRight: "6px" }} />
                        business@webpioneers.com
                      </label>
                      <WelcomeTabIcon2
                        className={classNames(styles.icon, styles.spin)}
                      />
                      <div style={{ paddingBottom: "60px" }} />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <BookingForm
              onClose={onClose}
              selectedTime={selectedTime}
              setOpenConfirmation={setOpenConfirmation}
              setSelectedTime={setSelectedTime}
            />
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
