import { Grid } from "@mui/material";
import { DropDownIcon } from "assets/icons";
import classNames from "classnames";
import { useState } from "react";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import CustomSelection from "shared/components/customSelection";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";

const languages = [
  {
    label: "English",
  },
  {
    label: "Urdu",
  },
];

const timeFormat = [
  {
    label: "12 Hours",
  },
  {
    label: "24 Hours",
  },
];

const dateFormat = [
  {
    label: "DD-MM-YYYY",
  },
  {
    label: "MM-DD-YYYY",
  },
];

const labelStyle = "original";

function GeneralSettings() {
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    ...allTimezones,
  });

  const [allTimeZones, setAllTimeZones] = useState<any>(options ? options : []);
  const [languageSelectionOpen, setLanguageSelectionOpen] =
    useState<boolean>(false);
  const [languageSelected, setLanguageSelected] = useState<any>(languages[0]);

  const [timeFormatOpen, setTimeFormatOpen] = useState<boolean>(false);
  const [TimeFormatSelected, setTimeFormatSelected] = useState<any>(
    timeFormat[0]
  );

  const [timeZoneOpen, setTimeZoneOpen] = useState<boolean>(false);
  const [timeZoneSelected, setTimeZoneSelected] = useState<any>(options[0]);

  const [dateFormatOpen, setDateFormatOpen] = useState<boolean>(false);
  const [dateFormatSelected, setDateFormatSelected] = useState<any>(
    dateFormat[0]
  );

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <label className={styles.heading}>General</label>
      </div>

      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div style={{ position: "relative" }}>
              <label className={styles.inputLabel}>General Availability</label>

              <div
                className={classNames(styles.selectionContainer)}
                onClick={(e: any) => {
                  setLanguageSelectionOpen(true);
                }}
              >
                {languageSelected ? (
                  <label style={{ cursor: "pointer" }}>
                    {languageSelected?.label}
                  </label>
                ) : (
                  <label style={{ cursor: "pointer" }}>Select Language</label>
                )}

                <DropDownIcon />
              </div>
              <CustomSelection
                openSelection={languageSelectionOpen}
                setOpenSelection={setLanguageSelectionOpen}
                optionsArray={languages}
                setSelected={setLanguageSelected}
                height="auto"
                overflow="auto"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ position: "relative" }}>
              <label className={styles.inputLabel}>Time Zone</label>

              <div
                className={classNames(styles.selectionContainer)}
                onClick={(e: any) => {
                  setTimeZoneOpen(true);
                }}
              >
                {timeZoneSelected ? (
                  <label style={{ cursor: "pointer" }}>
                    {timeZoneSelected?.label}
                  </label>
                ) : (
                  <label style={{ cursor: "pointer" }}>Select Time Zone</label>
                )}

                <DropDownIcon />
              </div>
              <CustomSelection
                openSelection={timeZoneOpen}
                setOpenSelection={setTimeZoneOpen}
                optionsArray={allTimeZones}
                setSelected={setTimeZoneSelected}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ position: "relative" }}>
              <label className={styles.inputLabel}>Time Format</label>

              <div
                className={classNames(styles.selectionContainer)}
                onClick={(e: any) => {
                  setTimeFormatOpen(true);
                }}
              >
                {TimeFormatSelected ? (
                  <label style={{ cursor: "pointer" }}>
                    {TimeFormatSelected?.label}
                  </label>
                ) : (
                  <label style={{ cursor: "pointer" }}>Select Time Zone</label>
                )}

                <DropDownIcon />
              </div>
              <CustomSelection
                openSelection={timeFormatOpen}
                setOpenSelection={setTimeFormatOpen}
                optionsArray={timeFormat}
                setSelected={setTimeFormatSelected}
                height="auto"
                overflow="auto"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ position: "relative" }}>
              <label className={styles.inputLabel}>Date Format</label>

              <div
                className={classNames(styles.selectionContainer)}
                onClick={(e: any) => {
                  setDateFormatOpen(true);
                }}
              >
                {dateFormatSelected ? (
                  <label style={{ cursor: "pointer" }}>
                    {dateFormatSelected?.label}
                  </label>
                ) : (
                  <label style={{ cursor: "pointer" }}>
                    Select Date Format
                  </label>
                )}

                <DropDownIcon />
              </div>
              <CustomSelection
                openSelection={dateFormatOpen}
                setOpenSelection={setDateFormatOpen}
                optionsArray={dateFormat}
                setSelected={setDateFormatSelected}
                height="auto"
                overflow="auto"
              />
            </div>
          </Grid>
        </Grid>

        <div className={styles.buttonContainer}>
          <CustomButton
            label={"Cancel"}
            buttonClass={classNames(styles.cancel, styles.cancelEdit)}
          />
          <CustomButton
            label={"Save"}
            buttonClass={classNames(styles.confirm, styles.saveEdit)}
          />
        </div>
      </div>
    </div>
  );
}

export default GeneralSettings;
