import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { publicRoutes, rolesRoutes } from "./allRoutes";
import Login from "pages/login";
import Layout from "./layout";
import { useSelector } from "react-redux";

function AuthRoute() {
  const user = useSelector((root: any) => root?.root?.user);

  return (
    <>
      <Routes>
        {!user?.isLoggedIn
          ? publicRoutes.map((route, inx) => {
              return (
                <React.Fragment key={inx}>
                  <Route
                    path={route.path}
                    key={inx}
                    element={<route.Component />}
                  />
                  <Route path={"*"} element={<Navigate to="/" replace />} />
                </React.Fragment>
              );
            })
          : rolesRoutes
              .find((role) => role.role_id === Number(user?.role?.role_id))
              ?.routes.map((route: any, inx: any) => {
                return (
                  <React.Fragment key={inx}>
                    <Route
                      path={route.path}
                      key={inx}
                      element={<Layout {...route} />}
                    />
                    <Route path={"*"} element={<Navigate to="/" replace />} />
                  </React.Fragment>
                );
              })}
      </Routes>
    </>
  );
}

export default AuthRoute;
