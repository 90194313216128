import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { FullImageLogo } from "assets/images";
import CustomInput from "shared/components/customInput";
import { useFormik } from "formik";
import { UpdatePasswordValidation } from "shared/utils/validation";
import CustomButton from "shared/components/customButton";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import Footer from "shared/components/footer";
import {
  CircleTick,
  GreenFilledCircleIcon,
  GreenTickFilled,
  RedFilledCircleIcon,
  RedTickFilled,
} from "assets/icons";

// Interface for initial form values
interface InitialValues {
  password: string;
  confirmPassword: string;
}

const UpdatePassword = () => {
  // for navigation
  const navigate = useNavigate();

  /* ------------------------------------State variables------------------------------------- */

  const [passwordChanged, setPasswordChanged] = useState(false);

  // Password Conditions
  const [passwordConditions, setPasswordConditions] = useState({
    characters: false,
    number: false,
    upperCase: false,
    lowerCase: false,
  });
  /* ---------------------------------------------------------------------------------------- */

  /* ------------------------------------Formik handling------------------------------------- */

  const initialValues: InitialValues = {
    password: "",
    confirmPassword: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: UpdatePasswordValidation,
    onSubmit: (values) => {
      console.log("values", values);
      setPasswordChanged(true);
    },
  });

  const { handleChange, handleSubmit, values, touched, errors } = formik;

  /* ---------------------------------------------------------------------------------------- */

  /* ------------------------------------useEffects------------------------------------- */

  useEffect(() => {
    const password = formik.values.password;
    setPasswordConditions({
      characters: password.length >= 8,
      number: /\d/.test(password),
      upperCase: /[A-Z]/.test(password),
      lowerCase: /[a-z]/.test(password),
    });
  }, [formik.values.password]);

  /* ---------------------------------------------------------------------------------------- */

  /* ------------------------------------Password Condition Rendering--------------------------- */

  const renderCondition = (condition: boolean, label: string) => (
    <div style={{ display: "flex", alignItems: "center", marginBottom: "7px" }}>
      {/* <img
        src={condition ? GreenTickFilled : RedTickFilled}
        alt={condition ? "true" : "false"}
        className={styles.conditionIcon}
      /> */}
      {condition ? (
        <GreenFilledCircleIcon className={styles.conditionIcon} />
      ) : (
        <RedFilledCircleIcon className={styles.conditionIcon} />
      )}
      <label className={condition ? styles.conditionSuccess : ""}>
        {label}
      </label>
    </div>
  );
  /* ---------------------------------------------------------------------------------------- */

  return (
    <>
      <div className={styles.outerMostContainer}>
        <img src={FullImageLogo} alt="Logo" className={styles.logo} />
        <div className={styles.mainContainer}>
          {!passwordChanged ? (
            <>
              <div className={styles.heading}>Update Your Password</div>
              <div className={styles.subHeading}>
                Set your new password with minimum 8 characters with a
                combination of letters and numbers.
              </div>
              <CustomInput
                placeholder="Enter your new password..."
                heading="New Password"
                isRequired
                type="password"
                showEye
                value={values.password}
                onKeyDown={handleSubmit}
                onChange={handleChange("password")}
                error={
                  touched.password && errors.password ? errors.password : ""
                }
              />
              <div className={styles.conditionContainer}>
                <div style={{ width: "100%" }} className={styles.conditonLabel}>
                  {renderCondition(
                    passwordConditions.characters,
                    "8 characters"
                  )}
                  {renderCondition(
                    passwordConditions.upperCase,
                    "Uppercase letter (A-Z)"
                  )}
                </div>
                <div style={{ width: "100%" }} className={styles.conditonLabel}>
                  {renderCondition(passwordConditions.number, "Number (0-9)")}
                  {renderCondition(
                    passwordConditions.lowerCase,
                    "Lowercase letter (a-z)"
                  )}
                </div>
              </div>
              <CustomInput
                placeholder="Re-type your new password..."
                heading="Confirm New Password"
                isRequired
                type="password"
                showEye
                value={values.confirmPassword}
                onKeyDown={handleSubmit}
                onChange={handleChange("confirmPassword")}
                error={
                  touched.confirmPassword && errors.confirmPassword
                    ? errors.confirmPassword
                    : ""
                }
              />
              <CustomButton
                label="Submit"
                containerClass={styles.btn}
                labelStyle={{ cursor: "pointer" }}
                onClick={handleSubmit}
              />
            </>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={CircleTick}
                  className={styles.circledTick}
                  alt="success"
                />
              </div>
              <div className={styles.heading}>
                Password Updated Successfully
              </div>
              <div className={styles.subHeading}>
                Always remember the password for your account.
              </div>
              <CustomButton
                label="Back To Login"
                buttonClass={styles.backBtn}
                labelStyle={{ cursor: "pointer" }}
                onClick={() => navigate(routeConstant.login.path)}
              />
            </>
          )}
        </div>
      </div>
      <Footer containerStyle={styles.footer} />
    </>
  );
};

export default UpdatePassword;
