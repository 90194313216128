import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import {
  CalendarIcon2,
  DollarSignIcon,
  DropDownPointerIcon,
  EyeIcon,
  MilesStoneIcon,
  ReceiptIcon,
  RedCircleIcon,
  ServiceIcon,
  StatusIcon,
} from "assets/icons";
import classNames from "classnames";
import moment from "moment";
import CustomButton from "shared/components/customButton";
import CheckBox from "shared/components/customCheckbox";
import CustomFilter from "shared/components/customFilterTab";
import ServiceFilter from "shared/components/filters/serviceFilter";
import GetPayStatus from "shared/components/payStatus";
import { invoice_status_enums } from "shared/utils/enum";
import Invoice from "../invoice";
import styles from "./style.module.scss";

function BillingsSettings({ setShowTab }: any) {
  const [serviceSelection, setServiceSelection] = useState<any>(null);
  const [invoiceSelection, setInvoiceSelection] = useState<any>(null);
  const [selectedInvoice, setSelectedInvoice] = useState<any>(null);
  const mdScreen = useMediaQuery("(max-width:1150px)");

  const menuItems = [
    { label: "Paid", id: 1, value: 0 },
    { label: "Unpaid", id: 2, value: 1 },
    { label: "Withdrawn", id: 3, value: 2 },
  ];

  const headers = [
    { label: "Service", icon: <ServiceIcon /> },
    { label: "Amount", icon: <DollarSignIcon /> },
    { label: "Sent on", icon: <CalendarIcon2 /> },
    { label: "Paid on", icon: <CalendarIcon2 /> },
    { label: "Status", icon: <StatusIcon /> },
    { label: "Action" },
  ];

  const milestonesHeaders = [
    { label: "Service", icon: <ServiceIcon /> },
    { label: "Milestones", icon: <MilesStoneIcon /> },
    { label: "Sent on", icon: <CalendarIcon2 /> },
    { label: "Paid on", icon: <CalendarIcon2 /> },
    { label: "Status", icon: <StatusIcon /> },
    { label: "Action" },
  ];

  const invoices = [
    {
      invoiceTitle: "INC2204#D-01",
      id: 1,
      services: [
        {
          label: "Development",
          amount: "1500",
          sent: new Date(),
          paid: new Date(),
          status: invoice_status_enums.paid,
        },
      ],
    },
    {
      invoiceTitle: "INC2204#D-02",
      id: 1,
      services: [
        {
          label: "UI UX",
          amount: "1500",
          sent: new Date(),
          paid: new Date(),
          status: invoice_status_enums.withdrawn,
        },
        {
          label: "Custom",
          amount: "1500",
          sent: new Date(),
          paid: new Date(),
          status: invoice_status_enums.cancelled,
        },
      ],
    },
    {
      invoiceTitle: "INC2204#D-01",
      id: 1,
      milestones: {
        services: [
          {
            label: "Development",
            amount: "1500",
            sent: new Date(),
            paid: new Date(),
            status: invoice_status_enums.paid,
          },
          {
            amount: "1500",
            sent: new Date(),
            paid: new Date(),
            status: invoice_status_enums.unpaid,
          },
          {
            amount: "1500",
            sent: new Date(),
            paid: new Date(),
            status: invoice_status_enums.withdrawn,
          },
        ],
      },
    },
  ];

  const handleResetFilters = () => {
    setServiceSelection(null);
    setInvoiceSelection(null);
  };

  return (
    <div className={!selectedInvoice ? styles.mainContainer : ""}>
      {selectedInvoice ? (
        <Invoice setShowTab={setShowTab} setSelectedInvoice={setSelectedInvoice} selectedInvoice={selectedInvoice} />
      ) : (
        <>
          <div className={styles.actionContainer}>
            <div className={styles.filterContainer}>
              <CustomFilter
                selection={invoiceSelection}
                setSelection={setInvoiceSelection}
                selectionLabel={invoiceSelection?.label || null}
                label={"All Invoices"}
                menuItems={menuItems}
              />
              <ServiceFilter
                serviceSelection={serviceSelection}
                setServiceSelection={setServiceSelection}
              />
              <div className={styles.resetBtn} onClick={handleResetFilters}>
                Reset
              </div>
            </div>
            <div className={styles.tagContainer}>
              <label className={styles.labelTag}>
                <UpcomingTag />
              </label>
            </div>
          </div>

          {invoices.map((item: any) => {
            const activeHeader = item.milestones ? milestonesHeaders : headers;
            const data = item.services || item.milestones?.services || [];

            return (
              <div
                key={item.invoiceTitle}
                className={styles.tableMainContainer}
              >
                <div className={styles.tableHeading}>
                  <ReceiptIcon />{" "}
                  <span className={styles.invoiceHeading}>
                    {item.invoiceTitle}
                  </span>
                  <button
                    className={styles.btn}
                    onClick={() => {
                      setSelectedInvoice(item);
                      setShowTab(true);
                    }}
                  >
                    <EyeIcon className={styles.eyeIcon} />
                    <span>View Invoice</span>
                  </button>
                </div>
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                  }}
                >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow
                        className={classNames(
                          styles.tableRow,
                          styles.tableHead
                        )}
                      >
                        {activeHeader.map((header, idx, array) => (
                          <TableCell
                            key={idx}
                            sx={{
                              borderBottom:
                                idx !== array.length - 1 ? "none" : undefined,
                              maxWidth:
                                idx === array.length - 2 && mdScreen
                                  ? undefined
                                  : "70px !important",
                            }}
                            className={classNames(
                              styles.tableCell,
                              idx === 0 && styles.firstCell,
                              idx === array.length - 1 && styles.lastCell
                            )}
                          >
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={
                                idx === array.length - 1 ? "center" : undefined
                              }
                            >
                              <span style={{ display: "flex" }}>
                                {header.icon}
                              </span>
                              <label style={{ marginLeft: "5px" }}>
                                {header.label}
                              </label>
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((item: any, idx: any, arr: any) => (
                        <TableRow
                          key={idx}
                          className={styles.tableRow}
                          id={idx !== arr.length - 1 ? styles.tableRowId : ""}
                        >
                          <TableCell
                            className={styles.tableCell2}
                            id={styles.tableBorderRight}
                          >
                            {item.label}
                          </TableCell>
                          <TableCell
                            className={styles.tableCell2}
                            id={styles.tableBorderRight}
                          >
                            ${item.amount}
                          </TableCell>
                          <TableCell
                            className={styles.tableCell2}
                            id={styles.tableBorderRight}
                          >
                            {moment(item.sent).format("MMM DD, YYYY")}
                          </TableCell>
                          <TableCell
                            className={styles.tableCell2}
                            id={styles.tableBorderRight}
                          >
                            {moment(item.sent).format("MMM DD, YYYY")}
                          </TableCell>
                          <TableCell
                            className={styles.tableCell2}
                            id={styles.tableBorderRight}
                            sx={{
                              maxWidth: mdScreen
                                ? undefined
                                : "70px !important",
                            }}
                          >
                            <GetPayStatus status={item.status} />
                          </TableCell>
                          <TableCell
                            className={styles.tableCell2}
                            id={styles.lastCell}
                            sx={{
                              display: "flex !important",
                              justifyContent: "center !important",
                              alignItems: "center !important",
                              height: "4vh !important",
                            }}
                          >
                            <Action />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

export default BillingsSettings;

function Action() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <DropDownPointerIcon style={{ cursor: "pointer" }} />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid #e2e2e2",
            padding: "14px",
          },
        }}
      >
        <div>
          <div className={styles.withDrawMenuHeading}>
            Withdraw Invoice Offer
          </div>
          <div className={styles.withDrawSubMenuHeading}>
            Are you sure you want to withdraw this invoice offer?
          </div>
          <div className={styles.withDrawSubMenuDescription}>
            <div className={styles.icon}>
              <RedCircleIcon className={styles.redIcon} />
            </div>
            <label>
              If you withdraw this invoice, the client will no longer be able to
              purchase it and will receive a notification that the offer has
              been withdrawn.
            </label>
          </div>
          <div className={styles.btnMenuContainer}>
            <CustomButton
              label={"Cancel"}
              buttonClass={styles.cancel}
              containerStyle={{ width: "100%" }}
              onClick={handleClose}
            />
            <CustomButton
              label={"Save"}
              buttonClass={styles.save}
              containerStyle={{ width: "100%" }}
              onClick={handleClose}
            />
          </div>
        </div>
      </Menu>
    </div>
  );
}

function UpcomingTag() {
  const [checkBox, setCheckbox] = useState({
    endImmediately: false,
    endPeriod: true,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        $1500 USD upcoming payment on Jun 22, 2024
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid #e2e2e2",
            padding: "14px",
          },
        }}
      >
        <div>
          <div className={styles.withDrawMenuHeading}>Cancel Subscription</div>
          <div className={styles.withDrawSubMenuHeading}>
            Are you sure you want to cancel this subscription?
          </div>
          <div style={{ marginTop: "20px" }}>
            <div className={styles.checkbox}>
              <CheckBox
                color={"#FF2626"}
                type={"circle"}
                checkBox={checkBox.endPeriod}
                handleCallback={(e: any) => {
                  e.stopPropagation();
                  setCheckbox({ endImmediately: false, endPeriod: true });
                }}
              />
              <label
                className={
                  checkBox.endPeriod
                    ? styles.activeCheckbox
                    : styles.nonActiveCheckbox
                }
              >
                Cancel at period end
              </label>
            </div>
            <div className={styles.checkbox}>
              <CheckBox
                color={"#FF2626"}
                type={"circle"}
                checkBox={checkBox.endImmediately}
                handleCallback={(e: any) => {
                  e.stopPropagation();
                  setCheckbox({ endImmediately: true, endPeriod: false });
                }}
              />
              <label
                className={
                  checkBox.endImmediately
                    ? styles.activeCheckbox
                    : styles.nonActiveCheckbox
                }
              >
                Cancel immediately
              </label>
            </div>
          </div>
          <div className={styles.btnMenuContainer}>
            <CustomButton
              label={"Do Not Cancel"}
              buttonClass={styles.cancel}
              containerStyle={{ width: "100%" }}
              onClick={handleClose}
            />
            <CustomButton
              label={"Cancel Subscription"}
              buttonClass={styles.save}
              containerStyle={{ width: "100%" }}
              onClick={handleClose}
            />
          </div>
        </div>
      </Menu>
    </div>
  );
}
