import CustomButton from "shared/components/customButton";
import styles from "./style.module.scss";
import { PlusIcon } from "assets/icons";
import { Grid } from "@mui/material";
import ChatList from "shared/components/pages/messages/chatList";
import MessagesList from "shared/components/pages/messages/messagesList";
import { Sample1, Sample2 } from "assets/sample";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "shared/customHooks/useWindowSize";
import AddMessage from "shared/modals/addMessage";

function Messages() {
  const gridRef = useRef<any>(null);
  const width = useWindowSize();
  const [openMessage, setOpenMessage] = useState<boolean>(false);
  const [height, setHeight] = useState<any>(null);
  const messages = [
    {
      create_at: "Wed Jul 05 2024 23:56:49 GMT+0500",
      sender_id: 1,
      user: {
        profile_picture: Sample1,
        name: "Robert Anderson",
      },
      message: `I understand that you are looking for a preview of the homepage. 
    
    Here is the pdf file.`,
      file: true,
      file_info: {
        file_name: "Home",
        file_type: "pdf",
        file_size: "25MB",
      },
    },
    {
      create_at: "Wed Jul 05 2024 23:56:49 GMT+0500",
      sender_id: 2,
      user: {
        profile_picture: Sample2,
        name: "Zoheb",
      },
      message: `Just some small feedback from my side, before I show it to the client. `,
      file: false,
    },
    {
      create_at: "Wed Jul 06 2024 23:56:49 GMT+0500",
      sender_id: 1,
      user: {
        profile_picture: Sample1,
        name: "Robert Anderson",
      },
      message: "Okay got it.",
    },
    {
      user: {
        profile_picture: Sample2,
        name: "Zoheb",
      },
      create_at: "Wed Jul 06 2024 23:56:49 GMT+0500",
      sender_id: 2,
      message: `Appreciate your patience.`,
      file: true,
      file_info: {
        file_name: "Home",
        file_type: "pdf",
        file_size: "25MB",
      },
    },
  ];

  useEffect(() => {
    setHeight(gridRef?.current?.offsetHeight);
  }, [width]);
  return (
    <div className={styles.mainContainer} ref={gridRef}>
      <div className={styles.headingContainer}>
        <div className={styles.headingContainer2}>
          <div>
            <div className={styles.heading}>Messages</div>
            <div className={styles.subHeading}>Team and client messages</div>
          </div>
        </div>
        <CustomButton
          buttonClass={styles.btn}
          label={"New Chat"}
          labelClass={styles.label}
          IconRightSvg={PlusIcon}
          containerClass={styles.btnContainer}
          onClick={() => {
            setOpenMessage(true);
          }}
        />
      </div>
      <div className={styles.mainMessagesContainer}>
        <Grid container className={styles.grid}>
          <Grid
            item
            xs={4}
            className={styles.chatListGrid}
            sx={{ padding: "0px !important" }}
          >
            <ChatList />
          </Grid>
          <Grid item xs={8} className={styles.messageCardGrid}>
            <MessagesList messages={messages} height={height} />
          </Grid>
        </Grid>
      </div>

      <AddMessage
        open={openMessage}
        handleClose={() => {
          setOpenMessage(false);
        }}
      />
    </div>
  );
}

export default Messages;
