let service_enums = {
  uiux: 1,
  development: 2,
  custom: 3,
};

let status_enums = {
  planning: 1,
  done: 2,
  inprogress: 3,
  paused: 4,
  cancelled: 5,
};

let priority_enums = {
  high: 1,
  medium: 2,
  low: 3,
};

const roles_enums = {
  admin: 1,
  employee: 2,
  client: 3,
};

const employee_roles_enums = {
  designer: 1,
  developer: 2,
};

const invited_status_enums = {
  not_invited: 0,
  invited: 1,
  active: 2,
};

const user_status_enums = {
  active: 1,
  inactive: 0,
};

const invoice_status_enums = {
  unpaid: 1,
  withdrawn: 2,
  cancelled: 3,
  paid: 4
}

export {
  service_enums,
  status_enums,
  priority_enums,
  roles_enums,
  invited_status_enums,
  employee_roles_enums,
  user_status_enums,
  invoice_status_enums
};
