import { routeConstant } from "shared/routes/routesConstant";
import { createElement } from "react";
import { ChannelsIcon, ClientsIcon, EmployeesIcon, MeetingIcon, MessageIcon, ProjectsIcon, ServicesIcon, SettingsIcon, TimerIcon } from "assets/icons";


function getRoutes() {
  let admin = [
    {
      title: "Projects",
      path: routeConstant.projects.path,
      icon: createElement(ProjectsIcon)
    },
    {
      title: "Channels",
      path: routeConstant.channels.path,
      icon: createElement(ChannelsIcon)
    },
    {
      title: "Clients",
      path: routeConstant.clients.path,
      icon: createElement(ClientsIcon)
    },
    {
      title: "Messages",
      path: routeConstant.messages.path,
      icon: createElement(MessageIcon)
    },
    {
      title: "Services",
      path: routeConstant.services.path,
      icon: createElement(ServicesIcon)
    },
    {
      title: "Employees",
      path: routeConstant.employees.path,
      icon: createElement(EmployeesIcon)
    },
    {
      title: "Time Tracker",
      path: routeConstant.timeTracker.path,
      icon: createElement(TimerIcon)
    },
    {
      title: "Meetings",
      path: routeConstant.meetings.path,
      icon: createElement(MeetingIcon)
    },
    {
      title: "Settings",
      path: routeConstant.settings.path.replace(":keyword", "account"),
    icon: createElement(SettingsIcon)
    },
  ];

  let Routes: any = [
    {
      role_id: 1,
      title: "Admin",
      routes: admin,
    },
  ];

  return Routes;
}

export { getRoutes };
