import { Grid } from "@mui/material";
import { useState } from "react";
import CalendarGrid from "shared/components/pages/meetings/calendarGrid";
import MeetingGrid from "shared/components/pages/meetings/meetingsGrid";
import ScheduleGrid from "shared/components/pages/meetings/scheduleGrid";
import styles from "./style.module.scss";

function Meetings() {
  const [startDate, setStartDate] = useState<any>(new Date());

  return (
    <Grid
      container
      spacing={2}
      sx={{ marginBottom: "100px" }}
      className={styles.mainGrid}
    >
      <Grid item xs={12} lg={8}>
        <MeetingGrid startDate={startDate} setStartDate={setStartDate} />
      </Grid>
      <Grid item xs={12} lg={4}>
        <div className={styles.infoContainers}>
          <ScheduleGrid />
          <CalendarGrid startDate={startDate} setStartDate={setStartDate} />
        </div>
      </Grid>
    </Grid>
  );
}

export default Meetings;
