import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { Grid } from "@mui/material";
import CustomButton from "shared/components/customButton";
import { useFormik } from "formik";
import * as yup from "yup";
import classNames from "classnames";
import { toastMessage } from "shared/components/toast";
import moment from "moment";
import { GreatReviewIcon } from "assets/icons";

interface InitialValues {
  feedback: string;
}

let reviews = [
  {
    description:
      "“They consistently set and achieve clear goals with a strong work ethic, creativity, and skillfulness, while meeting deadlines and effectively solving problems.”",
    date: new Date(),
  },
];

function ReviewSettings() {
  const [allReviews, setAllReviews] = useState<any>(null);
  const [openEditor, setOpenEditor] = useState<any>(false);
  const [requestLoader, setRequestLoader] = useState<boolean>(false);
  const [feedbackPending, setFeedbackPending] = useState<boolean>(false);

  const initialValues: InitialValues = {
    feedback:
      "Hello, Elisa! We hope you're doing well. We value your opinion and would greatly appreciate your feedback on the services we provided. Thank you for taking the time!",
  };

  const validation = yup.object().shape({
    feedback: yup
      .string()
      .required("Write something to send request.")
      .label("password"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: (value) => {
      Submitted();
    },
  });

  const Submitted = () => {
    setRequestLoader(true);
    setTimeout(() => {
      setRequestLoader(false);
      toastMessage("success", "Request Sent!");
      setFeedbackPending(true);
      setOpenEditor(false);
    }, 2000);
  };

  useEffect(() => {
    if (feedbackPending) {
      setTimeout(() => {
        setFeedbackPending(false);
        setAllReviews(reviews)
      }, 5000);
    }
  }, [feedbackPending]);

  // Destructuring formik methods and values
  const { handleChange, handleSubmit, values, touched, errors, setFieldValue } =
    formik;

  return (
    <div className={styles.mainContainer}>
      <Grid container className={styles.mainGrid}>
        <Grid md={6} xs={12}>
          <div
            className={
              allReviews?.length > 0 ? styles.reviewContainer : styles.heading
            }
            id={styles.reviewContainer}
          >
            {allReviews?.length > 0 ? (
              <ReviewContainer allReviews={allReviews} />
            ) : (
              "No reviews found"
            )}
          </div>
        </Grid>
        <Grid md={6} xs={12}>
          <div className={styles.requestContainer}>
            <div className={styles.requestHeading}>Request Feedback</div>
            <div className={styles.requestSubHeading}>
              Know your client's experience
            </div>
            {openEditor && (
              <textarea
                className={styles.textarea}
                value={values.feedback}
                onChange={handleChange("feedback")}
              />
            )}
            {touched.feedback && errors.feedback
              ? errors.feedback && (
                  <div className={styles.error}>{errors.feedback}</div>
                )
              : ""}
            <div className={classNames(openEditor && styles.btnContainer)}>
              <CustomButton
                loading={requestLoader}
                loadingStyle={{
                  color: "white",
                  width: "20px !important",
                  height: "20px !important",
                }}
                label={feedbackPending ? "Feedback Pending" : "Send Request"}
                disabled={feedbackPending}
                buttonClass={classNames(
                  feedbackPending ? styles.feedbackbtn : "",
                  styles.btn,
                  touched.feedback && errors.feedback
                    ? errors.feedback
                      ? styles.errorBorder
                      : ""
                    : ""
                )}
                onClick={() => {
                  if (!openEditor) {
                    setOpenEditor(true);
                  } else {
                    handleSubmit();
                  }
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ReviewSettings;

const ReviewContainer = ({ allReviews }: any) => {
  return (
    <>
      <div className={styles.reviewtHeading}>{allReviews[0].description}</div>
      <div
        style={{
          marginTop: "25px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className={styles.reviewtSubHeading}>
          {moment(new Date()).format("MMM ddd, YYYY")}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <GreatReviewIcon />{" "}
          <span
            className={styles.reviewtHeading}
            style={{ marginLeft: "10px" }}
          >
            {" "}
            Great
          </span>
        </div>
      </div>
    </>
  );
};
