import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import styles from "./style.module.scss";
import { useState } from "react";
import { Grid } from "@mui/material";
import { EmailIcon, MobileIcon } from "assets/icons";
import CheckBox from "shared/components/customCheckbox";
import CustomButton from "shared/components/customButton";
import classNames from "classnames";

const initialProjectNotifications = [
  {
    label: "Comments for you",
    email: false,
    mobile: false,
  },
  {
    label: "Tasks assigned to you",
    email: false,
    mobile: false,
  },
  {
    label: "Tasks completed",
    email: false,
    mobile: false,
  },
  {
    label: "Project invite accepted",
    email: false,
    mobile: false,
  },
  {
    label: "Project invite declined",
    email: false,
    mobile: false,
  },
  {
    label: "Project collaborator left",
    email: false,
    mobile: false,
  },
  {
    label: "Project collaborator removed",
    email: false,
    mobile: false,
  },
];

const initialChannelNotifications = [
  {
    label: "Channel invite accepted",
    email: false,
    mobile: false,
  },
  {
    label: "Channel invite declined",
    email: false,
    mobile: false,
  },
  {
    label: "Removed from channel",
    email: false,
    mobile: false,
  },
  {
    label: "Channel deleted",
    email: false,
    mobile: false,
  },
];

function NotificationSettings() {
  const [notificationSettings, setNotificationSettings] =
    useState<boolean>(false);
  const [projectNotifications, setProjectNotifications] = useState(
    initialProjectNotifications
  );
  const [channelNotifications, setChannelNotifications] = useState(
    initialChannelNotifications
  );
  const [loginAlert, setLoginAlert] = useState<boolean>(false);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#6C9B7D" : "#6C9B7D",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark" ? "#808080" : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const handleChange = (event: any, login: boolean) => {
    setNotificationSettings(event.target.checked);
  };

  const handleChangeLogin = (event: any, login: boolean) => {
    setLoginAlert(event.target.checked);
  };

  const handleEmailChange = (index: number, type: string) => {
    const updatedNotifications = [
      ...(type === "project" ? projectNotifications : channelNotifications),
    ];
    updatedNotifications[index].email = !updatedNotifications[index].email;
    type === "project"
      ? setProjectNotifications(updatedNotifications)
      : setChannelNotifications(updatedNotifications);
  };

  const handleMobileChange = (index: number, type: string) => {
    const updatedNotifications = [
      ...(type === "project" ? projectNotifications : channelNotifications),
    ];
    updatedNotifications[index].mobile = !updatedNotifications[index].mobile;
    type === "project"
      ? setProjectNotifications(updatedNotifications)
      : setChannelNotifications(updatedNotifications);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <label className={styles.heading}>Notification</label>
      </div>

      <div>
        <label className={styles.heading} style={{ color: "#262626" }}>
          Desktop & Web Notifications
        </label>

        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ marginTop: "13px" }}
        >
          <AntSwitch
            checked={notificationSettings}
            inputProps={{ "aria-label": "ant design" }}
            onChange={handleChange}
          />
          <label className={styles.switchLabel}>
            {notificationSettings ? "On" : "Off"}
          </label>
        </Stack>

        <Grid container spacing={2} sx={{ marginTop: "24px" }}>
          <Grid item xs={8} className={styles.optionContainer}>
            <label className={styles.heading} style={{ color: "#262626" }}>
              Project Notifications
            </label>
          </Grid>
          <Grid
            item
            xs={2}
            className={styles.optionContainer}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EmailIcon /> <label className={styles.smallHeading}>Email</label>
          </Grid>
          <Grid
            item
            xs={2}
            className={styles.optionContainer}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MobileIcon /> <label className={styles.smallHeading}>Mobile</label>
          </Grid>
          {projectNotifications.map((item: any, index: number) => {
            return (
              <>
                <Grid item xs={8} className={styles.optionContainer}>
                  <label className={styles.notificationHeading}>
                    {item.label}
                  </label>
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={styles.optionContainer}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <CheckBox
                    checkBox={item.email}
                    handleCallback={() => handleEmailChange(index, "project")}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={styles.optionContainer}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <CheckBox
                    checkBox={item.mobile}
                    handleCallback={() => handleMobileChange(index, "project")}
                  />
                </Grid>
              </>
            );
          })}
          <Grid item xs={8} className={styles.optionContainer}>
            <label className={styles.heading} style={{ color: "#262626" }}>
              Channel Notifications
            </label>
          </Grid>
          <Grid
            className={styles.optionContainer}
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EmailIcon /> <label className={styles.smallHeading}>Email</label>
          </Grid>
          <Grid
            className={styles.optionContainer}
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MobileIcon /> <label className={styles.smallHeading}>Mobile</label>
          </Grid>
          {channelNotifications.map((item: any, index: number) => {
            return (
              <>
                <Grid item xs={8} className={styles.optionContainer}>
                  <label className={styles.notificationHeading}>
                    {item.label}
                  </label>
                </Grid>
                <Grid
                  className={styles.optionContainer}
                  item
                  xs={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <CheckBox
                    checkBox={item.email}
                    handleCallback={() => handleEmailChange(index, "channel")}
                  />
                </Grid>
                <Grid
                  item
                  className={styles.optionContainer}
                  xs={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <CheckBox
                    checkBox={item.mobile}
                    handleCallback={() => handleMobileChange(index, "channel")}
                  />
                </Grid>
              </>
            );
          })}
          <Grid item xs={8} className={styles.optionContainer}>
            <label className={styles.heading} style={{ color: "#262626" }}>
              Account & Update Emails
            </label>
          </Grid>
          <Grid
            className={styles.optionContainer}
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EmailIcon /> <label className={styles.smallHeading}>Email</label>
          </Grid>
          <Grid
            className={styles.optionContainer}
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MobileIcon /> <label className={styles.smallHeading}>Mobile</label>
          </Grid>
        </Grid>
        <div className={styles.optionContainer}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <AntSwitch
              checked={loginAlert}
              inputProps={{ "aria-label": "ant design" }}
              onChange={handleChangeLogin}
            />
            <label
              className={styles.switchLabel}
              style={{ marginLeft: "10px" }}
            >
              New Login Alert
            </label>
          </div>
          <label className={styles.note}>
            Get an email notification every time a new device is logged into
            your account. <span>Recommended.</span>
          </label>
        </div>
        <div className={styles.buttonContainer}>
          <CustomButton
            label={"Cancel"}
            buttonClass={classNames(styles.cancel, styles.cancelEdit)}
          />
          <CustomButton
            label={"Update"}
            buttonClass={classNames(styles.confirm, styles.saveEdit)}
          />
        </div>
      </div>
    </div>
  );
}

export default NotificationSettings;
