import { useState } from "react";
import CustomFilter from "shared/components/customFilterTab";
import ServiceFilter from "shared/components/filters/serviceFilter";
import styles from "./style.module.scss";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import classNames from "classnames";
import {
  EmailIcon,
  MemberTableIcon,
  PhoneIcon,
  ProjectTableIcon,
  ReceiptIcon,
  ServiceIcon,
  SingleMemberIcon,
  StatusIcon,
  TasksIcon,
  TimerIcon,
} from "assets/icons";
import CheckBox from "shared/components/customCheckbox";
import GetStatus from "shared/components/getStatus";
import { service_enums } from "shared/utils/enum";
import moment from "moment";

let services = [
  {
    projectName: "Fatfish",
    checkbox: false,
    projectTask: [
      {
        name: "Redesign Home UI",
        created_on: new Date("2024-06-05"),
        service: 1,
        status: 2,
        priority: 1,
        due_date: new Date("2024-06-21"),
      },
      {
        name: "CRM Development",
        created_on: new Date("2024-06-03"),
        service: 2,
        status: 3,
        priority: 2,
        due_date: new Date("2024-07-15"),
      },
    ],
  },

  {
    projectName: "Multiverse X",
    checkbox: false,
    projectTask: [
      {
        name: "New logo design",
        created_on: new Date("2024-06-04"),
        service: 3,
        status: 1,
        priority: 3,
        due_date: new Date("2024-06-25"),
      },
    ],
  },
];

function ClientProject() {
  const [projectStatus, setProjectStatus] = useState<any>(null);
  const [allServices, setAllServices] = useState<any>(services);

  const menuItems = [
    {
      label: "Active Projects",
      id: 1,
      value: 0,
    },
    {
      label: "Done Projects",
      id: 2,
      value: 1,
    },
    {
      label: "All Projects",
      id: 3,
      value: 2,
    },
  ];

  const headers = [
    { label: "Tasks", icon: <TasksIcon /> },
    { label: "Service", icon: <ServiceIcon /> },
    { label: "Status", icon: <StatusIcon /> },
    { label: "Time", icon: <TimerIcon /> },
    { label: "Action" },
  ];

  const handleCheckboxChange = (index: any) => {
    const updatedServices = allServices.map((item: any, i: any) => ({
      ...item,
      checkbox: i === index ? true : false,
    }));
    setAllServices(updatedServices);
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.actionContainer}>
        <div className={styles.filterContainer}>
          <CustomFilter
            selection={projectStatus}
            setSelection={setProjectStatus}
            selectionLabel={projectStatus ? projectStatus?.label : null}
            label={"Project Status"}
            menuItems={menuItems}
          />
          <div
            className={styles.resetBtn}
            onClick={() => {
              setProjectStatus(null);
            }}
          >
            Reset
          </div>
        </div>
        <div className={styles.tagContainer}>
          <label className={styles.labelTag}>2 Active Projects</label>
        </div>
      </div>

      {allServices?.map((item: any, index: any) => {
        return (
          <div className={styles.tableMainContainer}>
            <div className={styles.tableHeading}>
              <CheckBox
                color={"#FF2626"}
                type={"circle"}
                checkBox={item?.checkbox}
                handleCallback={(e: any) => {
                  e.stopPropagation();
                  handleCheckboxChange(index);
                }}
              />{" "}
              <span style={{ marginLeft: "10px" }}> {item?.projectName}</span>
              <div className={styles.tagContainer2}>
                <label className={styles.labelTag2}>
                  {item?.projectTask?.length} Task(s)
                </label>
              </div>
            </div>
            <TableContainer
              sx={{
                boxShadow: "none",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
              component={Paper}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    className={classNames(styles.tableRow, styles.tableHead)}
                  >
                    {headers?.map((item: any, index: any, array: any) => {
                      return (
                        <TableCell
                          sx={
                            index === array.length - 1
                              ? {
                                  display: "flex",
                                  justifyContent: "center",
                                  borderBottom: "none",
                                }
                              : {
                                  borderBottom: "none",
                                }
                          }
                          className={classNames(
                            styles.tableCell,
                            index === 0 && styles.firstCell,
                            index === array.length - 1 && styles.lastCell
                          )}
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <span style={{ display: "flex" }}>
                              {item?.icon}
                            </span>
                            <label style={{ marginLeft: "5px" }}>
                              {" "}
                              {item?.label}
                            </label>
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item?.projectTask?.map(
                    (itm: any, index: any, array: any) => {
                      return (
                        <TableRow
                          id={
                            index !== array.length - 1 ? styles.tableRowId : ""
                          }
                          className={styles.tableRow}
                        >
                          <TableCell
                            className={styles.tableCell2}
                            id={styles.tableBorderRight}
                          >
                            {itm?.name}
                          </TableCell>
                          <TableCell
                            className={styles.tableCell2}
                            id={styles.tableBorderRight}
                          >
                            {itm?.service === service_enums.uiux
                              ? "UI/UX"
                              : itm?.service === service_enums.development
                              ? "Development"
                              : "Custom"}
                          </TableCell>
                          <TableCell
                            className={styles.tableCell2}
                            id={styles.tableBorderRight}
                          >
                            <GetStatus status={itm?.status} />
                          </TableCell>

                          <TableCell
                            className={styles.tableCell2}
                            id={styles.tableBorderRight}
                          >
                            {"01:10:00"}
                          </TableCell>
                          <TableCell
                            className={styles.tableCell2}
                            id={styles.lastCell}
                          >
                            {moment(itm?.due_date).format(
                              "ddd, MMMM DD, YYYY"
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      })}
    </div>
  );
}

export default ClientProject;
