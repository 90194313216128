import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "shared/components/sidebar";
import { routeConstant } from "./routesConstant";

interface LayoutProps {
  path: string;
  title: string;
  Component: any;
}

function Layout({ title, Component, path }: Partial<LayoutProps>) {
  useEffect(() => {
    document.title = title + " |  Web Pioneer";
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Sidebar Component={Component} path={path} />
    </>
  );
}

export default Layout;
