import classNames from "classnames";
import { memo, useEffect, useState } from "react";
import styles from "./style.module.scss";

interface CustomSelectionProps {
  openSelection: boolean;
  setOpenSelection: (val: boolean) => void;
  optionsArray: any[];
  setSelected: any;
  overflow?: any;
  height?: any;
}

const CustomSelection = ({
  openSelection,
  setOpenSelection,
  optionsArray,
  setSelected,
  overflow,
  height,
}: CustomSelectionProps) => {
  const [options, setOptions] = useState<any>(optionsArray);
  function handleClick(e: any) {
    const elem: any = document.getElementById("CustomSelectionContainer");
    if (elem) {
      if (!elem?.contains(e.target)) {
        setOpenSelection?.(false);
      }
    }
  }

  useEffect(() => {
    document.body.addEventListener(
      "click",
      (event: any) => {
        handleClick(event);
      },
      true
    );

    return () => {
      document.body.removeEventListener(
        "click",
        (event: any) => {
          handleClick(event);
        },
        true
      );
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={classNames(
        styles.optionsContainer,
        openSelection && styles.openSelectionContainer
      )}
      id="CustomSelectionContainer"
      style={
        openSelection
          ? {
              height: height ? height : "300px",
              overflow: overflow ? overflow : "scroll",
            }
          : { display: "none" }
      }
    >
      {options?.map((item: any, inx: number) => {
        return (
          <div
            className={classNames(
              "align-items-center gap-2 py-3 px-3",
              styles.optionContainer,
              inx === options?.length - 1 && styles.btmradius,
              inx === 0 && styles.topradius
            )}
            style={inx === options?.length - 1 ? { borderBottom: "0px" } : {}}
            onClick={() => {
              setOpenSelection?.(false);
              setSelected(item);
            }}
            key={inx}
            role="button"
          >
            <div style={{ display: "flex", alignContent: "center" }}>
              <div className={styles.labelContainer}>
                {item?.profile_picture && (
                  <img
                    src={item?.profile_picture}
                    alt="profile_pic"
                    className={styles.profile_pic}
                  />
                )}
                <label className={classNames(styles.labelStyle)} role="button">
                  {item?.label ? item?.label : item?.name}
                </label>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(CustomSelection);
