import React from "react";
import styles from "./style.module.scss";
import CustomButton from "../../../customButton";
import { PlusIcon } from "assets/icons";

function AddProjectTab() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.heading}>Add Your Projects</div>
      <div className={styles.subHeading}>Kickstart Your New Project Today!</div>

      <CustomButton buttonClass={styles.btn} label={"Start a New Project"} labelClass={styles.label} IconRightSvg={PlusIcon}/>
    </div>
  );
}

export default AddProjectTab;
