import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { CrossIcon } from "assets/icons";
import classNames from "classnames";
import { useFormik } from "formik";
import * as React from "react";
import CustomInput from "shared/components/customInput";
import { AddProjectValidation } from "shared/utils/validation";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";
import { toastMessage } from "shared/components/toast";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AddProjectProps {
  open: boolean;
  handleClose: any;
}

interface InitialValues {
  name: string;
  description: string;
}

export default function AddProject({ open, handleClose }: AddProjectProps) {
  const onClose = () => {
    handleClose();
    formik.resetForm();
  };

  const initialValues: InitialValues = {
    name: "",
    description: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddProjectValidation,
    onSubmit: (value) => {
      onClose();
      toastMessage("success", "Project added succesfully.");
      formik.resetForm();
    },
  });

  const { handleChange, handleSubmit, values, touched, errors, setFieldValue } =
    formik;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            padding: "24px",
            width: "100%",
            justifyContent: "center",
            maxWidth: (theme) => theme.spacing(80),
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent>
          <div className={styles.upperActionContainer}>
            <div className={styles.label}>Add Project</div>
            <div onClick={() => onClose()}>
              <CrossIcon className={styles.crossIcon} />
            </div>
          </div>

          <div className={styles.inputContainer}>
            <CustomInput
              placeholder={"Project Name"}
              heading="Enter Project Name"
              isRequired
              inputId={styles.input}
              type={"text"}
              value={values.name}
              onChange={handleChange("name")}
              onKeyDown={handleSubmit}
              error={touched.name && errors.name ? errors.name : ""}
            />
            <div>
              <label className={styles.inputLabel}>
                Description <span>*</span>
              </label>
              <textarea
                name="description"
                placeholder="Description"
                onChange={(e) => {
                  setFieldValue("description", e.target.value);
                }}
                className={classNames(
                  styles.description,
                  touched.description &&
                    errors.description &&
                    styles.errorBorder
                )}
                value={values.description}
              />
              {touched.description && errors.description
                ? errors.description && (
                    <div className={styles.error}>{errors.description}</div>
                  )
                : ""}
            </div>
            <div className={styles.btnContainer}>
              <CustomButton
                label={"Cancel"}
                buttonClass={styles.cancel}
                onClick={() => onClose()}
              />
              <CustomButton
                label={"Add Project"}
                buttonClass={styles.addBtn}
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
