import React from "react";
import styles from "pages/project/style.module.scss";
import {
  DeleteIcon,
  DoneCheckIcon,
  DropDownPointerIcon,
  LinkIcon,
  ProjectTableIcon,
} from "assets/icons";
import { MenuItem, Menu } from "@mui/material";

function MenuDropDown() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={styles.btnDropDown}
      >
        <DropDownPointerIcon />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid #e2e2e2",
            padding: "4px 14px",
          },
        }}
      >
        <MenuItem onClick={handleClose}>
          <ProjectTableIcon />
          <label className={styles.menuItem}>Assets</label>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkIcon />
          <label className={styles.menuItem}>Copy Link</label>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <DoneCheckIcon />
          <label className={styles.menuItem}>Mark Done</label>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <DeleteIcon />
          <label className={styles.menuItem} style={{ color: "#ff2626" }}>
            Delete
          </label>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default MenuDropDown;
