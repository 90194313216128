import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import styles from "./style.module.scss";
import { InfoCircleGray, InvoiceLogo } from "assets/icons";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import classNames from "classnames";
import moment from "moment";

interface Props {
  setShowTab: any;
  setSelectedInvoice: any;
  selectedInvoice: any;
}
function Invoice({ setShowTab, setSelectedInvoice, selectedInvoice }: Props) {
  return (
    <div>
      <div>
        <div
          className={styles.backButton}
          onClick={() => {
            setShowTab(false);
            setSelectedInvoice(null);
          }}
        >
          <KeyboardArrowLeftIcon sx={{ color: "#808080" }} />{" "}
          <span>Billing Overview </span>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.tableHeading}>
            <div className={styles.invoiceHeading}>
              {selectedInvoice?.invoiceTitle}
            </div>
            <div>
              <InvoiceLogo />
            </div>
          </div>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableCell}>Billed to</TableCell>
                  <TableCell className={styles.tableCell}>Project</TableCell>
                  <TableCell className={styles.tableCell}>
                    Payment Type
                  </TableCell>
                  <TableCell className={styles.tableCell}>Max hrs/mo</TableCell>
                  <TableCell className={styles.tableCell}>
                    Max Projects
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={styles.tableCell2}>
                    Elisa Feliz
                  </TableCell>
                  <TableCell className={styles.tableCell2}>Custom </TableCell>
                  <TableCell className={styles.tableCell2}>One Time</TableCell>
                  <TableCell className={styles.tableCell2}>40</TableCell>
                  <TableCell className={styles.tableCell2}>02</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classNames(styles.tableCell, styles.tableCell3)}
                  >
                    Milestones
                  </TableCell>
                  <TableCell
                    className={classNames(styles.tableCell, styles.tableCell3)}
                  >
                    Titles
                  </TableCell>
                  <TableCell
                    className={classNames(styles.tableCell, styles.tableCell3)}
                  >
                    Sent on
                  </TableCell>
                  <TableCell
                    className={classNames(styles.tableCell, styles.tableCell3)}
                  >
                    Paid on
                  </TableCell>
                  <TableCell
                    className={classNames(styles.tableCell, styles.tableCell3)}
                  >
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={styles.tableCell2}>01</TableCell>
                  <TableCell className={styles.tableCell2}>
                    Cro of brand + site{" "}
                  </TableCell>
                  <TableCell className={styles.tableCell2}>
                    {moment(new Date()).format("MMM DD, YYYY")}
                  </TableCell>
                  <TableCell className={styles.tableCell2}>
                    {moment(new Date()).format("MMM DD, YYYY")}
                  </TableCell>
                  <TableCell className={styles.tableCell2}>
                    ${"500.00"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.finalContainer}>
            <div className={styles.descriptionHeading}>Description:</div>
            <div className={styles.description}>
              The goal is to create a modern, user-friendly, and responsive
              website. The project includes discovery and planning through
              stakeholder interviews, goal definition, timeline creation, and
              current site audit. The design phase will develop wireframes, a
              new layout, and ensure responsive design while incorporating UI/UX
              best practices.
              <br />
              The timeline includes 2 weeks for planning, 4 weeks for design, 6
              weeks for development, 2 weeks for content migration, 2 weeks for
              testing, and 1 week for deployment, with ongoing support. The team
              comprises a project manager, UX/UI designer, front-end developer,
              back-end developer, content strategist, and QA tester. The budget
              will be determined based on scope and requirements.
            </div>
          </div>
          <div className={styles.finalContainer}>
            <Grid container>
              <Grid xs={12} md={7}>
                <div className={styles.noteDescription}>
                  <InfoCircleGray style={{ marginRight: "10px" }} />{" "}
                  <label>
                    {" "}
                    <span style={{ color: "black" }}>Note: </span> This invoice
                    is system-generated and will be considered credible without
                    any signature.
                  </label>
                </div>
              </Grid>
              <Grid xs={12} md={5}>
                <div className={styles.amountContainer}>
                  <div
                    className={classNames(
                      styles.amountSingleContainer,
                      styles.noPaddingTop
                    )}
                  >
                    <div className={styles.amountHeading}>Subtotal</div>
                    <div className={styles.amountHeading2}>$1500.00</div>
                  </div>
                  <div className={styles.amountSingleContainer}>
                    <div className={styles.amountHeading}>Tax</div>
                    <div className={styles.amountHeading2}>$0.00</div>
                  </div>
                  <div className={styles.amountSingleContainer}>
                    <div className={styles.amountHeading}>Total (USD)</div>
                    <div className={styles.amountHeading2}>$1500.00</div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
