import classNames from "classnames";
import moment from "moment";
import styles from "./style.module.scss";

interface BookingTimingsProps {
  selectedTime: any;
  setSelectedTime: any;
}

function BookingTimings({
  selectedTime,
  setSelectedTime,
}: BookingTimingsProps) {
  function getNextSevenDays() {
    const daysArray = [];
    const today = new Date();
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    for (let i = 0; i < 7; i++) {
      const nextDay = new Date(today);
      nextDay.setUTCDate(today.getUTCDate() + i);

      const dayLabel = weekdays[nextDay.getUTCDay()];
      const date = nextDay.toISOString().split("T")[0];

      const times =
        i === 0
          ? ["09:30 AM", "10:00 AM", "10:30 AM"]
          : i === 1
          ? ["09:30 AM", "-", "-"]
          : i === 2
          ? ["09:30 AM", "10:00 AM", "10:30 AM"]
          : i === 3
          ? ["09:30 AM", "10:00 AM", "-"]
          : i === 4
          ? ["-", "-", "-"]
          : i === 5
          ? ["09:30 AM", "-", "-"]
          : i === 6
          ? ["09:30 AM", "10:00 AM", "10:30 AM"]
          : [""];

      const schedule = times.map((time, index) => ({
        id: i * 10 + index,
        time: time,
      }));

      daysArray.push({
        id: i,
        label: dayLabel,
        date: date,
        schedule: schedule,
      });
    }

    return daysArray;
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.upperActionContainer}>
        <label className={styles.label}>Book a time that works for you.</label>
        <label className={styles.label2}>
          (GMT+05:00) Pakistan Standard Time
        </label>
      </div>

      <div className={styles.forMobileContainer}>
        <div className={styles.dateContainer}>
          {getNextSevenDays()?.map((item) => {
            return (
              <div className={styles.dateHeadingContainer}>
                <label className={styles.day}>
                  {moment(item?.date).format("ddd")}
                </label>
                <label className={styles.date}>
                  {moment(item?.date).format("DD")}
                </label>

                <div className={styles.timeContainer}>
                  {item?.schedule?.map((itm: any) => {
                    return (
                      <div
                        className={classNames(
                          styles.timeStamp,
                          itm?.id === selectedTime?.id ? styles.active : ""
                        )}
                        onClick={() => {
                          if (itm?.id === selectedTime?.schedule?.id) {
                            setSelectedTime(null);
                          } else if (itm?.time !== "-") {
                            setSelectedTime({
                              date: item?.date,
                              id: item?.id,
                              label: "Thursday",
                              schedule: itm,
                            });
                          }
                        }}
                      >
                        {itm?.time}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BookingTimings;
