import { Box, Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import React, { useState } from "react";
import styles from "./style.module.scss";

import {
  ApproveIcon,
  DeleteIcon,
  DeleteIcon2,
  DownloadIcon,
  DropDownPointerIcon,
  EditIcon,
  FileIcon,
  WarningIcon,
} from "assets/icons";
import classNames from "classnames";
import CustomButton from "shared/components/customButton";
import ConfirmationModal from "shared/modals/confirmation";

interface RequestCardProps {
  item: any;
  handleApprove?: any;
  handleDelete?: any;
  handleEdit?: any;
  handleRemoveFile?: any;
  isApproved?: boolean;
}

function RequestCard({
  item,
  handleApprove,
  handleDelete,
  handleEdit,
  isApproved,
  handleRemoveFile,
}: RequestCardProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [approveItem, setApproveItem] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<boolean>(false);
  const [removeFile, setRemoveFile] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>(item?.message);

  return (
    <div className={styles.requestsCard}>
      <div className={styles.dateStamp}>
        <label>{moment(new Date()).format("dddd, MMM DD, YYYY")}</label>
        <div className={styles.line} />
      </div>
      <Box className={styles.innerContainer}>
        <Box display={"flex"}>
          <img
            src={item?.user?.profile_picture}
            alt="profile_pic"
            className={styles.profile_pic}
          />

          <div className={styles.contentContainer}>
            <label className={styles.name}>{item?.user?.name}</label>
            <span
              className={classNames(styles.timeStamp)}
              style={{ margin: "0 5px" }}
            >
              •
            </span>
            <label className={classNames(styles.timeStamp)}>1:40 PM</label>
            <div className={styles.description}>
              {item?.message}
              {item?.isEdit && <span className={styles.edited}>(Edited)</span>}
            </div>

            {item?.file && (
              <div className={styles.file}>
                <div className={styles.fileInnerContainer}>
                  <div className={styles.fileIconContainer}>
                    <FileIcon />
                  </div>
                  <div style={{ marginLeft: "10px", width: "100%" }}>
                    <div className={styles.name}>
                      {item?.file?.name}.{item?.file?.type}
                    </div>
                    <div className={styles.size}>25 MB</div>
                  </div>
                  <div>
                    <button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      className={styles.fileOptionsBtn}
                    >
                      <DropDownPointerIcon />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Box>

        {!isApproved && (
          <div className={styles.iconContainer}>
            <Tooltip title="Edit" arrow>
              <EditIcon onClick={() => setIsEdit(true)} />
            </Tooltip>
            <Tooltip title="Delete" arrow>
              <DeleteIcon2
                onClick={() => {
                  setOpenConfirm(true);
                  setDeleteItem(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Approve" arrow>
              <ApproveIcon
                onClick={() => {
                  setOpenConfirm(true);
                  setApproveItem(true);
                }}
              />
            </Tooltip>
          </div>
        )}
      </Box>

      {isEdit && (
        <div className={styles.editContainer}>
          <textarea
            className={styles.textarea}
            value={editValue}
            onChange={(e: any) => setEditValue(e.target.value)}
          />
          <div className={styles.buttonContainer}>
            <CustomButton
              label={"Cancel"}
              onClick={() => {
                setIsEdit(false);
                setEditValue(item?.message);
              }}
              buttonClass={classNames(styles.cancel, styles.cancelEdit)}
            />
            <CustomButton
              label={"Save"}
              onClick={() => {
                setIsEdit(false);
                handleEdit(item, editValue);
              }}
              buttonClass={classNames(styles.confirm, styles.saveEdit)}
            />
          </div>
        </div>
      )}

      <ConfirmationModal
        open={openConfirm}
        handleClose={() => {
          setApproveItem(false);
          setDeleteItem(false);
          setOpenConfirm(false);
        }}
        Icon={WarningIcon}
        label="Confirm Action"
        subLabel={
          approveItem
            ? "Are you sure you want to appove this request?"
            : deleteItem
            ? "Are you sure you want to delete this request?"
            : removeFile
            ? "Are you sure you want to remove this file from request?"
            : ""
        }
        actions={[
          {
            label: "Cancel",
            action: () => {
              setApproveItem(false);
              setDeleteItem(false);
              setOpenConfirm(false);
              setRemoveFile(false);
            },
            style: styles.cancel,
          },
          {
            label: approveItem
              ? "Yes, Approve."
              : deleteItem
              ? "Yes, Delete."
              : removeFile
              ? "Yes, Remove."
              : "",
            action: () => {
              if (approveItem) {
                handleApprove(item);
              } else if (deleteItem) {
                handleDelete(item);
              } else if (removeFile) {
                handleRemoveFile(item);
              }
              setOpenConfirm(false);
              setApproveItem(false);
              setDeleteItem(false);
              setRemoveFile(false);
            },

            style: styles.confirm,
          },
        ]}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid #e2e2e2",
            padding: "0px 10px",
          },
        }}
      >
        {!isApproved && (
          <MenuItem
            onClick={() => {
              setOpenConfirm(true);
              setRemoveFile (true);
              handleClose();
            }}
          >
            <DeleteIcon style={{ marginRight: "10px" }} />
            <label style={{ color: "#ff2626" }}> Remove</label>
          </MenuItem>
        )}
        <MenuItem onClick={handleClose}>
          <DownloadIcon style={{ marginRight: "10px" }} />
          Download
        </MenuItem>
      </Menu>
    </div>
  );
}

export default RequestCard;
