import { DollarIcon2, DollarIconFilled } from "assets/icons";
import { Sample1, Sample2, Sample3, Sample4 } from "assets/sample";

let projectsConstants = [
  {
    label: "Fatfish",
  },
  {
    label: "Multiverse X",
  },
  {
    label: "Custom",
  },
];

let billConstants = [
  {
    label: "Billable",
    icon: DollarIcon2,
  },
  {
    label: "Non-Billable",
    icon: DollarIconFilled,
  },
];

let tasksSelections = [
  {
    label: "UI/UX",
  },
  {
    label: "Development",
  },
  {
    label: "Custom",
  },
];

let membersConstants = [
  { name: "Jacob Janson", profile_picture: Sample1 },
  { name: "Kate Wilson", profile_picture: Sample2 },
  { name: "Jake", profile_picture: Sample3 },
  { name: "Holt Raymond", profile_picture: Sample4 },
];

export { projectsConstants, billConstants, tasksSelections, membersConstants };
