import { BackButtonIcon2 } from "assets/icons";
import { useNavigate } from "react-router-dom";
import ProjectMenuDropDown from "shared/components/pages/project/projectMenuDropDown";
import { routeConstant } from "shared/routes/routesConstant";
import styles from "./style.module.scss";
import ProjectDetailsCard from "shared/components/pages/project/projectDetailsCard";

function Project() {
  const navigate = useNavigate();
  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.headingContainer2}>
          <div
            className={styles.subHeading}
            onClick={() => navigate(routeConstant.projects.path)}
          >
            Projects
          </div>
          <div className={styles.iconContainer}>
            <BackButtonIcon2 className={styles.invertImg} />
          </div>
          <div className={styles.heading}>Project Name</div>
        </div>
        <div>
          <ProjectMenuDropDown />
        </div>
      </div>

      <ProjectDetailsCard />
    </div>
  );
}

export default Project;
