import React from "react";
import styles from "./style.module.scss";
import { CalendarIcon, WelcomeTabIcon, WelcomeTabIcon2 } from "assets/icons";
import { Avatar } from "@mui/material";
import moment from "moment";
import classNames from "classnames";

function WelcomeTab() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.profileContainer}>
        <Avatar variant="square" className={styles.avatar} />
        <div className={styles.headingContainer}>
          <div className={styles.heading}>Jane Doe</div>
          <div className={styles.subHeading}>What would you like to do?</div>
        </div>
      </div>

      <div className={styles.dateContainer}>
        <CalendarIcon />
        <label className={styles.dateHeading}>
          {moment(new Date()).format("ddd, MMMM DD, YYYY, h:mm a")}
        </label>
      </div>
      <WelcomeTabIcon2 className={classNames(styles.icon, styles.spin)} />
    </div>
  );
}

export default WelcomeTab;
