import Login from "pages/login";
import Signup from "pages/signUp";
import { routeConstant } from "./routesConstant";
import ForgetPassword from "pages/forgetPassword";
import UpdatePassword from "pages/updatePassword";
import Dashboard from "pages/dashboard";
import Projects from "pages/projects";
import Channels from "pages/channels";
import Clients from "pages/clients";
import Meetings from "pages/meetings";
import Messages from "pages/messages";
import Services from "pages/services";
import Employees from "pages/employees";
import Tracker from "pages/tracker";
import Settings from "pages/settings";
import Notification from "pages/notifcations";
import Search from "pages/search";
import Requests from "pages/requests";
import Client from "pages/client";
import Employee from "pages/employee";
import EditService from "pages/editService";
import Project from "pages/project";
import Task from "pages/task";

const publicRoutes = [
  {
    path: routeConstant.login.path,
    title: routeConstant.login.title,
    Component: Login,
  },
  {
    path: routeConstant.signUp.path,
    title: routeConstant.signUp.title,
    Component: Signup,
  },
  {
    path: routeConstant.forgetPassword.path,
    title: routeConstant.forgetPassword.title,
    Component: ForgetPassword,
  },
  {
    path: routeConstant.updatePassword.path,
    title: routeConstant.updatePassword.title,
    Component: UpdatePassword,
  },
];

const adminRoutes: any = [
  {
    path: routeConstant.dashboard.path,
    title: routeConstant.dashboard.title,
    Component: Dashboard,
  },
  {
    path: routeConstant.projects.path,
    title: routeConstant.projects.title,
    Component: Projects,
  },
  {
    path: routeConstant.channels.path,
    title: routeConstant.channels.title,
    Component: Channels,
  },
  {
    path: routeConstant.clients.path,
    title: routeConstant.clients.title,
    Component: Clients,
  },
  {
    path: routeConstant.meetings.path,
    title: routeConstant.meetings.title,
    Component: Meetings,
  },
  {
    path: routeConstant.messages.path,
    title: routeConstant.messages.title,
    Component: Messages,
  },
  {
    path: routeConstant.services.path,
    title: routeConstant.services.title,
    Component: Services,
  },
  {
    path: routeConstant.employees.path,
    title: routeConstant.employees.title,
    Component: Employees,
  },
  {
    path: routeConstant.timeTracker.path,
    title: routeConstant.timeTracker.title,
    Component: Tracker,
  },
  {
    path: routeConstant.settings.path,
    title: routeConstant.settings.title,
    Component: Settings,
  },
  {
    path: routeConstant.notification.path,
    title: routeConstant.notification.title,
    Component: Notification,
  },

  {
    path: routeConstant.search.path,
    title: routeConstant.search.title,
    Component: Search,
  },
  {
    path: routeConstant.requests.path,
    title: routeConstant.requests.title,
    Component: Requests,
  },
  {
    path: routeConstant.client.path,
    title: routeConstant.client.title,
    Component: Client,
  },
  {
    path: routeConstant.employee.path,
    title: routeConstant.employee.title,
    Component: Employee,
  },
  {
    path: routeConstant.editService.path,
    title: routeConstant.editService.title,
    Component: EditService,
  },
  {
    path: routeConstant.project.path,
    title: routeConstant.project.title,
    Component: Project,
  },
  {
    path: routeConstant.task.path,
    title: routeConstant.task.title,
    Component: Task,
  },
];

const employeeRoutes: any = [
  {
    path: routeConstant.login.path,
    title: routeConstant.login.title,
    Component: Login,
  },
];

const clientRoutes: any = [
  {
    path: routeConstant.login.path,
    title: routeConstant.login.title,
    Component: Login,
  },
];

let rolesRoutes = [
  {
    role_id: 1,
    title: "Admin",
    routes: adminRoutes,
  },
  {
    role_id: 2,
    title: "Employee",
    routes: employeeRoutes,
  },
  {
    role_id: 3,
    title: "Client",
    routes: clientRoutes,
  },
];

export { publicRoutes, rolesRoutes };
