import {
  ChannelsIcon,
  DollarIcon2,
  EmployeesIcon,
  MessageIcon,
  ProjectsIcon,
  ServicesIcon,
  TimerIcon,
} from "assets/icons";
import classNames from "classnames";
import { useState } from "react";
import CustomButton from "shared/components/customButton";
import CheckBox from "shared/components/customCheckbox";
import styles from "./style.module.scss";

let initialMenu = [
  {
    id: 1,
    label: "Projects",
    icon: <ProjectsIcon />,
    selected: true,
  },
  {
    id: 2,
    label: "Channels",
    icon: <ChannelsIcon />,
    selected: true,
  },
  {
    id: 3,
    label: "Clients",
    icon: <DollarIcon2 />,
    selected: true,
  },
  {
    id: 4,
    label: "Messages",
    icon: <MessageIcon />,
    selected: true,
  },
  {
    id: 5,
    label: "Services",
    icon: <ServicesIcon />,
    selected: true,
  },
  {
    id: 6,
    label: "Employees",
    icon: <EmployeesIcon />,
    selected: true,
  },
  {
    id: 7,
    label: "Time Tracker",
    icon: <TimerIcon />,
    selected: true,
  },
];

function SidebarSettings() {
  const [sidebarMenus, setSidebarMenus] = useState<any>(initialMenu);

  const handleCallback = (item: any) => {
    let temp: any = [...sidebarMenus];
    let index = temp.findIndex((itm: any) => itm.id === item?.id);

    if (index > -1) {
      temp[index] = { ...temp[index], selected: !temp[index].selected };
    }
    setSidebarMenus(temp);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <label className={styles.heading}>Sidebar</label>
      </div>

      <div>
        <label className={styles.heading}>Show In Sidebar</label>
      </div>

      <div className={styles.sidebarContainer}>
        {sidebarMenus?.map((item: any) => {
          return (
            <div key={item.id} className={styles.singleCheckbox}>
              <CheckBox
                checkBox={item?.selected}
                handleCallback={() => handleCallback(item)}
              />
              <div
                style={{
                  marginLeft: "16px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className={styles.icon}>{item?.icon}</div>
                <label className={styles.sidebarlabel}>{item?.label}</label>
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.buttonContainer}>
        <CustomButton
          label={"Cancel"}
          buttonClass={classNames(styles.cancel, styles.cancelEdit)}
        />
        <CustomButton
          label={"Update"}
          buttonClass={classNames(styles.confirm, styles.saveEdit)}
        />
      </div>
    </div>
  );
}

export default SidebarSettings;
