import { Grid } from "@mui/material";
import {
  AddIcon,
  BackButtonIcon2,
  CrossIcon,
  DefaultUploadPictureIcon,
  GoogleIcon,
  GreenFilledCircleIcon,
  RedFilledCircleIcon,
} from "assets/icons";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { IResolveParams, LoginSocialGoogle } from "reactjs-social-login";
import CustomButton from "shared/components/customButton";
import CustomInput from "shared/components/customInput";
import {
  ChangePasswordValidation,
  SettingsValidation,
} from "shared/utils/validation";
import styles from "./style.module.scss";
import { toastMessage } from "shared/components/toast";
import classNames from "classnames";

interface InitialValues {
  profile_picture: string;
  email: string;
  first_name: string;
  last_name: string;
}

interface InitialValuesForPassword {
  current_password: string;
  new_password: string;
  confirm_password: string;
}

function AccountSettings() {
  const [passwordConditions, setPasswordConditions] = useState({
    characters: false,
    number: false,
    upperCase: false,
    lowerCase: false,
  });
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [deleteText, setDeleteText] = useState<string>("");
  const [changePasswordActive, setChangePasswordActive] =
    useState<boolean>(false);
  const [image, setImage] = useState<any>({
    raw: "",
    preview: "",
  });

  const initialValues: InitialValues = {
    profile_picture: "",
    email: "zohebhassan@gmail.com",
    first_name: "Zoheb",
    last_name: "Hassan",
  };

  const initialValuesForPassword: InitialValuesForPassword = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: SettingsValidation,
    onSubmit: (value) => {},
  });

  const { handleChange, handleSubmit, values, touched, errors, setFieldValue } =
    formik;

  const formikForPassword = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesForPassword,
    validationSchema: ChangePasswordValidation,
    onSubmit: (value) => {},
  });

  const handleButtonClick = () => {
    if (image?.raw) {
      setImage({
        raw: "",
        preview: "",
      });
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    }
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setImage({
          preview: e.target.result as string,
          raw: file,
        });
        setFieldValue("profile_picture", e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const onSuccess = (data: any, provider: any) => {
    console.log("data", data);
    console.log("provider", provider);
  };

  const handleDelete = () => {
    if (deleteText !== "Delete") {
      toastMessage("error", "Text doesn't match.");
    } else {
      toastMessage("success", "Text matched.");
    }
  };

  const renderCondition = (condition: boolean, label: string) => (
    <div style={{ display: "flex", alignItems: "center", marginBottom: "7px" }}>
      {condition ? (
        <GreenFilledCircleIcon className={styles.conditionIcon} />
      ) : (
        <RedFilledCircleIcon className={styles.conditionIcon} />
      )}
      <label className={condition ? styles.conditionSuccess : ""}>
        {label}
      </label>
    </div>
  );

  useEffect(() => {
    const password = formikForPassword.values.new_password;
    setPasswordConditions({
      characters: password.length >= 8,
      number: /\d/.test(password),
      upperCase: /[A-Z]/.test(password),
      lowerCase: /[a-z]/.test(password),
    });
  }, [formikForPassword.values.new_password]);


  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <label className={styles.heading}>
          {changePasswordActive ? (
            <>
              {" "}
              <BackButtonIcon2
                onClick={() => setChangePasswordActive(false)}
              />{" "}
              Change Password
            </>
          ) : (
            "Account"
          )}
        </label>
      </div>

      {!changePasswordActive ? (
        <>
          <div className={styles.accountEditContainer}>
            <div style={{ marginBottom: "20px" }}>
              <div className={styles.photoContainer}>
                {image?.raw ? (
                  <img
                    alt="uploaded"
                    src={image?.preview}
                    className={styles.profile_picture}
                  />
                ) : (
                  <DefaultUploadPictureIcon
                    className={styles.profile_picture}
                  />
                )}
                <button onClick={handleButtonClick} className={styles.addBtn}>
                  {image?.raw ? <CrossIcon /> : <AddIcon />}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                </button>
              </div>
              {touched.profile_picture && errors.profile_picture
                ? errors.profile_picture && (
                    <div className={styles.error}>{errors.profile_picture}</div>
                  )
                : ""}
            </div>

            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    placeholder={"Enter first name"}
                    heading="First Name"
                    type={"text"}
                    value={values.first_name}
                    onChange={handleChange("first_name")}
                    onKeyDown={handleSubmit}
                    error={
                      touched.first_name && errors.first_name
                        ? errors.first_name
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    placeholder={"Enter last name"}
                    heading="Last Name"
                    type={"text"}
                    value={values.last_name}
                    onChange={handleChange("last_name")}
                    onKeyDown={handleSubmit}
                    error={
                      touched.last_name && errors.last_name
                        ? errors.last_name
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    placeholder={"Enter email"}
                    heading="Email Address"
                    type={"text"}
                    value={values.email}
                    onChange={handleChange("email")}
                    onKeyDown={handleSubmit}
                    error={touched.email && errors.email ? errors.email : ""}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    placeholder={"Password"}
                    heading="Password"
                    type={"text"}
                    value={"*****"}
                    onChange={handleChange("email")}
                    onKeyDown={handleSubmit}
                    disabled={true}
                  />
                  <button
                    className={styles.changePwdBtn}
                    onClick={() => setChangePasswordActive(true)}
                  >
                    Change Password
                  </button>
                </Grid>
              </Grid>
              <div className={styles.btnContainer}>
                <CustomButton
                  label="Save Changes"
                  containerClass={styles.btnContainerClass}
                  buttonClass={styles.btnClass}
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </div>
            </div>
          </div>

          <div className={styles.googleContainer}>
            <label className={styles.heading}>Connected Accounts</label>
            <label className={styles.sectionSubHeading}>
              Log in to WP dashbaord with your Google or Apple account.
            </label>
            <label className={styles.sectionSubHeading2}>
              You can log in to WP dashbaord with your Google account.
            </label>

            <LoginSocialGoogle
              client_id={
                "620806407500-e5rn11mb39hq1km6eire8ftagellhrfm.apps.googleusercontent.com"
              }
              scope="openid profile email"
              discoveryDocs="claims_supported"
              cookie_policy="single_host_origin"
              onResolve={({ provider, data }: IResolveParams) => {
                onSuccess(data, provider);
              }}
              className="mb-3"
              onReject={(err) => {
                // setGoogleloading(false);
              }}
            >
              <CustomButton
                label="Connect Google"
                buttonClass={styles.googleBtn}
                labelStyle={{ marginLeft: "10px", cursor: "pointer" }}
                iconStyle={styles.googleIcon}
                IconLeftSvg={GoogleIcon}
              />
            </LoginSocialGoogle>
          </div>

          <div className={styles.deleteContainer}>
            <label className={styles.heading}>Delete Account</label>
            <label className={styles.sectionSubHeading}>
              This will immediately delete all of your data including tasks,
              projects, comments, and more. This can't be undone.
            </label>
            <label className={styles.sectionSubHeading3}>
              Type <span>“Delete”</span> To Confirm
            </label>
            <CustomInput
              placeholder={"Delete"}
              value={deleteText}
              onChange={(e: any) => {
                setDeleteText(e.target.value);
              }}
              type={"text"}
              inputId={styles.input}
            />
            <div className={styles.btnContainer} style={{ marginTop: "10px" }}>
              <CustomButton
                label="Delete Account"
                containerClass={styles.btnContainerClass}
                buttonClass={styles.btnClass}
                onClick={() => handleDelete()}
              />
            </div>
          </div>
        </>
      ) : (
        <div>
          <label className={styles.passwordHeading}>
            Update the password you use for your WP account.
          </label>

          <div className={styles.passwordsContainer}>
            <Grid container spacing={2} sx={{ marginTop: "20px" }}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  placeholder="Enter your current password..."
                  heading="Current Password"
                  type="password"
                  showEye
                  value={formikForPassword.values.current_password}
                  onKeyDown={formikForPassword.handleSubmit}
                  onChange={formikForPassword.handleChange("current_password")}
                  error={
                    formikForPassword?.touched.current_password &&
                    formikForPassword?.errors.current_password
                      ? formikForPassword?.errors.current_password
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  placeholder="Enter your new password..."
                  heading="New Password"
                  type="password"
                  showEye
                  value={formikForPassword.values.new_password}
                  onKeyDown={formikForPassword.handleSubmit}
                  onChange={formikForPassword.handleChange("new_password")}
                  error={
                    formikForPassword?.touched.new_password &&
                    formikForPassword?.errors.new_password
                      ? formikForPassword?.errors.new_password
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  placeholder="Confirm your password..."
                  heading="Confirm Password"
                  type="password"
                  showEye
                  value={formikForPassword.values.confirm_password}
                  onKeyDown={formikForPassword.handleSubmit}
                  onChange={formikForPassword.handleChange("confirm_password")}
                  error={
                    formikForPassword?.touched.confirm_password &&
                    formikForPassword?.errors.confirm_password
                      ? formikForPassword?.errors.confirm_password
                      : ""
                  }
                />
              </Grid>
            </Grid>

            <div className={styles.conditionContainer}>
              <div style={{ width: "100%" }} className={styles.conditonLabel}>
                {renderCondition(passwordConditions.characters, "8 characters")}
                {renderCondition(
                  passwordConditions.upperCase,
                  "Uppercase letter (A-Z)"
                )}
              </div>
              <div style={{ width: "100%" }} className={styles.conditonLabel}>
                {renderCondition(passwordConditions.number, "Number (0-9)")}
                {renderCondition(
                  passwordConditions.lowerCase,
                  "Lowercase letter (a-z)"
                )}
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <CustomButton
              label={"Cancel"}
              onClick={() => setChangePasswordActive(false)}
              buttonClass={classNames(styles.cancel, styles.cancelEdit)}
            />
            <CustomButton
              label={"Save"}
              onClick={() => {
                formikForPassword.handleSubmit();
              }}
              buttonClass={classNames(styles.confirm, styles.saveEdit)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default AccountSettings;
