import Toast from "shared/components/toast";
import "./App.css";
import AuthRoute from "shared/routes/authRoute";

function App() {
  return (
    <div>
      <AuthRoute />
      <Toast />
    </div>
  );
}

export default App;
