import React, { useState } from "react";
import styles from "pages/meetings/style.module.scss";
import CustomButton from "shared/components/customButton";
import { CalendarIcon3 } from "assets/icons";
import UpdateSchedule from "shared/modals/updateSchedule";

function ScheduleGrid() {
  const [openSchedule, setOpenSchedule] = useState<boolean>(false);

  return (
    <div className={styles.infoContainer}>
      <label className={styles.heading}>Set Your Availability</label>
      <label className={styles.subHeading}>
        Let your clients know your available time.{" "}
      </label>
      <CustomButton
        label={"Update your schedule"}
        buttonClass={styles.calendarBtn}
        IconRightSvg={CalendarIcon3}
        iconStyle={styles.calendarIcon}
        onClick={() => {
          setOpenSchedule(true);
        }}
      />

      <UpdateSchedule
        open={openSchedule}
        handleClose={() => setOpenSchedule(false)}
      />
    </div>
  );
}

export default ScheduleGrid;
