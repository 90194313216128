import {
  ApproveIcon,
  DeleteIcon,
  DropDownIcon,
  DropDownPointerIcon,
  WarningIcon,
} from "assets/icons";
import React, { useState } from "react";
import CustomButton from "shared/components/customButton";
import styles from "./style.module.scss";
import { Menu, MenuItem } from "@mui/material";
import { status_enums } from "shared/utils/enum";
import GetStatus from "shared/components/getStatus";
import ConfirmationModal from "shared/modals/confirmation";

function MenuOptions({ handleDeleteAll, handleApproveAll }: any) {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [approveItem, setApproveItem] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      label: "Approve All",
      id: 2,
      icon: <ApproveIcon />,
      style: styles.black,
      action: () => {
        setOpenConfirm(true);
        setApproveItem(true);
      },
    },
    {
      label: "Delete All",
      id: 1,
      icon: <DeleteIcon />,
      style: styles.red,
      action: () => {
        setOpenConfirm(true);
        setDeleteItem(true);
      },
    },
  ];

  return (
    <div>
      <button onClick={handleClick} className={styles.btn}>
        <DropDownPointerIcon />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid #e2e2e2",
            padding: "0px 10px",
          },
        }}
      >
        {menuItems?.map((item) => {
          return (
            <MenuItem
              key={item.id}
              onClick={() => {
                handleClose();
                item?.action();
              }}
              sx={{
                padding: "10px 20px",
                borderRadius: "8px",
              }}
            >
              <span className={styles.icon}>{item?.icon}</span>
              <label className={item?.style}>{item?.label}</label>
            </MenuItem>
          );
        })}
      </Menu>

      <ConfirmationModal
        open={openConfirm}
        handleClose={() => {
          setApproveItem(false);
          setDeleteItem(false);
          setOpenConfirm(false);
        }}
        Icon={WarningIcon}
        label="Confirm Action"
        subLabel={
          approveItem
            ? "Are you sure you want to appove all the request(s)?"
            : deleteItem
            ? "Are you sure you want to delete all the request(s)?"
            : ""
        }
        actions={[
          {
            label: "Cancel",
            action: () => {
              setApproveItem(false);
              setDeleteItem(false);
              setOpenConfirm(false);
            },
            style: styles.cancel,
          },
          {
            label: approveItem
              ? "Yes, Approve."
              : deleteItem
              ? "Yes, Delete."
              : "",
            action: () => {
              if (approveItem) {
                handleApproveAll();
              } else if (deleteItem) {
                handleDeleteAll();
              }
              setOpenConfirm(false);
              setApproveItem(false);
              setDeleteItem(false);
            },

            style: styles.confirm,
          },
        ]}
      />
    </div>
  );
}

export default MenuOptions;
