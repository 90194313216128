import { useMediaQuery } from "@mui/material";
import { DashboardIcon } from "assets/icons";
import { FullImageLogoSvg } from "assets/images";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useWindowSize from "shared/customHooks/useWindowSize";
import { routeConstant } from "shared/routes/routesConstant";
import Header from "../header";
import { getRoutes } from "./constants";
import styles from "./style.module.scss";

interface SidebarProps {
  Component: any;
  path: any;
}

const SidebarComp = ({ Component, path }: SidebarProps) => {
  // Get window width
  const width = useWindowSize();
 
  // State for sidebar toggle
  const [toggle, setToggle] = useState<boolean>(false);
  // Get user data from Redux store
  const { user } = useSelector((state: any) => state.root);
  // Initialize navigation function
  const navigate = useNavigate();
  // Get routes based on user role
  const routes = getRoutes();

  // State for active routes
  const [activeRoutes, setActiveRoutes] = useState(() => 
    routes.find((role: any) => role.role_id === Number(user?.role?.role_id))?.routes || []
  );

  // Handle navigation to a specific route
  const handleNavigation = (item: any) => {
    navigate(item?.path);
    if (width < 992) {
      setToggle(false);
    }
  };

  // Update toggle state based on window width
  useEffect(() => {
    if (width > 992) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [width]);

  // Update activeRoutes when width changes
  // useEffect(() => {
  //   const currentRoutes = routes.find((role: any) => role.role_id === Number(user?.role?.role_id))?.routes || [];
  //   if (width < 992) {
  //     setActiveRoutes([
  //       ...currentRoutes,
  //       {
  //         title: "Search",
  //         path: routeConstant.search.path,
  //         icon: createElement(SearchIcon),
  //       }
  //     ]);
  //   } else {
  //     setActiveRoutes(currentRoutes);
  //   }
  // }, [width, routes, user?.role?.role_id]);

  // Placeholder for logout functionality
  const handleLogout = () => {
    // Implement logout functionality here
  };


  return (
    <div style={{ display: "flex" }}>
      <Sidebar
        className={classNames(styles.sidebar, width < 992 && styles.sidebar2)}
        onBackdropClick={() => setToggle(false)}
        toggled={toggle}
        breakPoint={"lg"}
      >
        <Menu className={styles.menu}>
          <div className={styles.logoContainer}>
            <FullImageLogoSvg
              onClick={() => navigate(routeConstant.dashboard.path)}
            />
          </div>
          <MenuItem
            onClick={() => {
              navigate(routeConstant.dashboard.path);
              if (width < 992) {
                setToggle(false);
              }
            }}
            className={styles.dashboardTab}
          >
            <div>
              <span>Dashboard</span>
              <DashboardIcon />
            </div>
          </MenuItem>
          {activeRoutes?.map((item: any, inx: any) => (
            <div key={inx}>
              {item?.path ? (
                <MenuItem
                  onClick={() => handleNavigation(item)}
                  icon={item?.icon}
                  className={classNames(
                    path === item?.path ? styles.active : "",
                    styles.menuItems
                  )}
                >
                  {item?.title}
                </MenuItem>
              ) : (
                <SubMenu
                  key={item?.title}
                  label={item?.title}
                  icon={item?.icon}
                  className={styles.subMenuContainer}
                >
                  {item?.subMenu?.map((subItem: any, inx: any, array: any) => (
                    <MenuItem
                      key={subItem?.path}
                      onClick={() => handleNavigation(subItem)}
                      className={
                        path === subItem?.path && array.length !== inx + 1
                          ? `${styles.subMenuActive} ${styles.borderSubMenu}`
                          : array.length !== inx + 1
                          ? styles.borderSubMenu
                          : path === subItem?.path
                          ? styles.subMenuActive
                          : ""
                      }
                    >
                      {subItem?.title}
                    </MenuItem>
                  ))}
                </SubMenu>
              )}
            </div>
          ))}
        </Menu>
      </Sidebar>
      <main style={{ width: "100%" }}>
        <div className={styles.mainContainer}>
          <Header toggle={toggle} setToggle={setToggle} />
          <div className={styles.componentContainer}>
            <Component />
          </div>
        </div>
      </main>
    </div>
  );
};

export default SidebarComp;
