import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DropDownPointerIcon,
  ServiceIcon,
} from "assets/icons";
import { UserPlaceholderPNG } from "assets/images";
import moment from "moment";
import styles from "pages/meetings/style.module.scss";
import React, { useState } from "react";

function MeetingGrid({ startDate, setStartDate }: any) {
  const handleDateChange = (days: number) => {
    const newDate = new Date(startDate);
    newDate.setDate(newDate.getDate() + days);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Remove time component
    if (newDate >= today) {
      setStartDate(newDate);
    }
  };

  let meetings = [
    {
      label: "Design new landing pages",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.",
      service: "UI/UX",
      user: "Cody Allen",
      start: "10:00 am",
      end: "10:00",
    },
    {
      label: "CRO Audit of our website",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.",
      service: "Custom",
      user: "Melissa",
      start: "02:30 pm",
      end: "02:00",
    },
    {
      label: "Revamp our current website",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.",
      service: "UI/UX",
      user: "Torii Rowe",
      start: "05:30 pm",
      end: "05:00",
    },
    {
      label: "Design new landing pages",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.",
      service: "UI/UX",
      user: "Cody Allen",
      start: "10:00 am",
      end: "10:00",
    },
    {
      label: "CRO Audit of our website",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.",
      service: "Custom",
      user: "Melissa",
      start: "02:30 pm",
      end: "02:00",
    },
    {
      label: "Revamp our current website",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.",
      service: "UI/UX",
      user: "Torii Rowe",
      start: "05:30 pm",
      end: "05:00",
    },
  ];

  function getColor(index: any) {
    const colors = ["#6C9B7D", "#5B97BD", "#A782C3"];
    return colors[index % colors.length];
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingContainer}>
        <label className={styles.heading}>Upcoming Events</label>
        <label className={styles.subHeading}>
          Events scheduled by clients based on your availability.{" "}
        </label>
      </div>

      <div className={styles.selectedDateContainer}>
        <div className={styles.selectedDate}>
          {moment(startDate).format("DD MMMM")}
        </div>
        <div className={styles.alignedContainer}>
          <label
            className={styles.selectedDate}
            style={{ marginRight: "10px" }}
          >
            {moment(startDate).format("dddd")}
          </label>
          <ArrowLeftIcon
            className={styles.arrowIcon}
            style={{ marginRight: "10px" }}
            onClick={() => handleDateChange(-1)}
          />
          <ArrowRightIcon
            className={styles.arrowIcon}
            onClick={() => handleDateChange(1)}
          />
        </div>
      </div>

      <div style={{ marginTop: "16px" }} className={styles.meetingContainer}>
        {meetings?.map((item: any, index: any) => {
          return (
            <div className={styles.meetingCard}>
              <div className={styles.timeContainer}>
                <label className={styles.timeHeading}>{item?.end}</label>
                <label className={styles.timeSubHeading}>{item?.start}</label>
              </div>
              <div
                className={styles.content}
                style={{
                  borderLeft: `2px solid ${getColor(index)}`,
                }}
              >
                <img
                  src={UserPlaceholderPNG}
                  className={styles.picture}
                  alt="profile"
                />
                <div className={styles.description}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                      className={styles.name}
                      style={{
                        color: getColor(index),
                      }}
                    >
                      {item?.user}
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ServiceIcon />{" "}
                      <label className={styles.serviceName}>
                        {item?.service}
                      </label>
                    </div>
                  </div>
                  <label className={styles.meetingName}>{item?.label}</label>
                  <label className={styles.meetingDescription}>
                    {item?.description}
                  </label>
                </div>
                <Options />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MeetingGrid;

function Options() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        className={styles.action}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <DropDownPointerIcon />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            border: "1px solid #e2e2e2",
            padding: "0px 10px",
          },
        }}
      >
        <MenuItem onClick={handleClose}>
          <label className={styles.menuItems}>Done</label>
        </MenuItem>
      </Menu>
    </div>
  );
}
