import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React, { useState } from "react";
import styles from "./style.module.scss";
import {
  CrossIcon,
  DropDownIcon,
  InfoCircleGray,
  CopyIcon,
  RedFilledMinus,
  GreenFilledPlus,
  GrayFilledCross,
} from "assets/icons";
import { Grid } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import CustomSelection from "shared/components/customSelection";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import classNames from "classnames";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface UpdateScheduleProps {
  open: boolean;
  handleClose: () => void;
}

export default function UpdateSchedule({
  open,
  handleClose,
}: UpdateScheduleProps) {
  const availabilityOptions = [
    {
      label: "Repeat Weekly",
    },
    {
      label: "Bi-weekly",
    },
    {
      label: "Monthly",
    },
  ];
  const labelStyle = "original";

  // For timezone array
  // eslint-disable-next-line
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    ...allTimezones,
  });

  const weekDays = [
    {
      label: "Mon",
      schedule: [
        {
          startTime: "09:00 AM",
          endTime: "09:30 AM",
        },
        {
          startTime: "01:00 PM",
          endTime: "01:30 PM",
        },
        {
          startTime: "06:00 AM",
          endTime: "06:30 AM",
        },
      ],
    },
    {
      label: "Tue",
      schedule: [],
    },
    {
      label: "Wed",
      schedule: [
        {
          startTime: "09:00 AM",
          endTime: "09:30 AM",
        },
        {
          startTime: "01:00 PM",
          endTime: "01:30 PM",
        },
        {
          startTime: "06:00 AM",
          endTime: "06:30 AM",
        },
      ],
    },
    {
      label: "Thu",
      schedule: [
        {
          startTime: "09:00 AM",
          endTime: "09:30 AM",
        },
        {
          startTime: "01:00 PM",
          endTime: "01:30 PM",
        },
        {
          startTime: "06:00 AM",
          endTime: "06:30 AM",
        },
      ],
    },
    {
      label: "Fri",
      schedule: [
        {
          startTime: "09:00 AM",
          endTime: "09:30 AM",
        },
        {
          startTime: "01:00 PM",
          endTime: "01:30 PM",
        },
        {
          startTime: "06:00 AM",
          endTime: "06:30 AM",
        },
      ],
    },
    {
      label: "Sat",
      schedule: [
        {
          startTime: "09:00 AM",
          endTime: "09:30 AM",
        },
        {
          startTime: "01:00 PM",
          endTime: "01:30 PM",
        },
        {
          startTime: "06:00 AM",
          endTime: "06:30 AM",
        },
      ],
    },
    {
      label: "Sun",
      schedule: [
        {
          startTime: "09:00 AM",
          endTime: "09:30 AM",
        },
        {
          startTime: "01:00 PM",
          endTime: "01:30 PM",
        },
        {
          startTime: "06:00 AM",
          endTime: "06:30 AM",
        },
      ],
    },
  ];

  const [openTimeZoneSelection, setOpenTimeZoneSelection] =
    useState<boolean>(false);
  const [openAvailabiltySelection, setOpenAvailabiltySelection] =
    useState<boolean>(false);
  // eslint-disable-next-line
  const [allTimeZones, setAllTimeZones] = useState<any>(options ? options : []);
  const [selectedTimeZone, setSelectedTimeZone] = useState<any>(options[0]);
  const [selectedAvailabiltyOption, setSelectedAvailabiltyOption] =
    useState<any>(availabilityOptions[0]);
  const [daysCounter, setDaysCounter] = useState<any>(1);
  const [hoursCounter, setHoursCounter] = useState<any>(0);
  const [schedule, setSchedule] = useState<any>(weekDays);

  const onClose = () => {
    handleClose();
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      borderRadius: "8px",
    },
  }));

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            padding: "24px",
            width: "100%",
            justifyContent: "center",
            maxWidth: (theme) => theme.spacing(110),
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent>
          <div className={styles.upperActionContainer}>
            <div className={styles.label}>Update Your Schedule</div>
            <div onClick={() => onClose()}>
              <CrossIcon className={styles.crossIcon} />
            </div>
          </div>

          <Grid container spacing={2} marginTop={"24px"}>
            <Grid item xs={6}>
              <label className={styles.inputLabel}>
                Advance Booking Time
                <HtmlTooltip
                  title={
                    <div style={{ padding: "14px" }}>
                      <label className={styles.tooptip}>
                        Maximum time in advance that an appointment can be
                        booked.
                      </label>
                    </div>
                  }
                >
                  <InfoCircleGray />
                </HtmlTooltip>
              </label>
              <div className={styles.counterContainer}>
                <div>
                  <input
                    value={daysCounter}
                    onChange={(e: any) => {
                      if (e.target.value > 10) {
                        setDaysCounter(10);
                      } else if (e.target.value < 0) {
                        setDaysCounter(0);
                      } else {
                        setDaysCounter(e.target.value);
                      }
                    }}
                    type="number"
                  />
                  {daysCounter > 1 ? "Days" : "Day"}
                </div>

                <div className={styles.counterArrows}>
                  <ArrowDropUpIcon
                    sx={{
                      color: "#808080",
                      marginBottom: "-10px",
                      zIndex: "9999",
                    }}
                    onClick={() => {
                      if (daysCounter > 0 && daysCounter < 10) {
                        setDaysCounter(daysCounter + 1);
                      }
                    }}
                  />
                  <ArrowDropDownIcon
                    sx={{ color: "#808080" }}
                    onClick={() => {
                      if (daysCounter > -1 && daysCounter <= 10) {
                        setDaysCounter(daysCounter - 1);
                      }
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <label className={styles.inputLabel}>
                Minimum Lead Time
                <HtmlTooltip
                  title={
                    <div style={{ padding: "14px" }}>
                      <label className={styles.tooptip}>
                        Minimum time before the appointment start that it can be
                        booked.
                      </label>
                    </div>
                  }
                >
                  <InfoCircleGray />
                </HtmlTooltip>
              </label>
              <div className={styles.counterContainer}>
                <div>
                  <input
                    value={hoursCounter}
                    onChange={(e: any) => {
                      if (e.target.value > 10) {
                        setHoursCounter(10);
                      } else if (e.target.value < 0) {
                        setHoursCounter(0);
                      } else {
                        setHoursCounter(e.target.value);
                      }
                    }}
                    type="number"
                  />
                  {hoursCounter > 1 ? "Hours" : "Hour"}
                </div>

                <div className={styles.counterArrows}>
                  <ArrowDropUpIcon
                    sx={{
                      color: "#808080",
                      marginBottom: "-10px",
                      zIndex: "9999",
                    }}
                    onClick={() => {
                      if (hoursCounter > -1 && hoursCounter < 10) {
                        setHoursCounter(hoursCounter + 1);
                      }
                    }}
                  />
                  <ArrowDropDownIcon
                    sx={{ color: "#808080" }}
                    onClick={() => {
                      if (hoursCounter > 0 && hoursCounter <= 10) {
                        setHoursCounter(hoursCounter - 1);
                      }
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ position: "relative" }}>
                <label className={styles.inputLabel}>
                  General Availability
                </label>

                <div
                  className={classNames(styles.selectionContainer)}
                  onClick={(e: any) => {
                    setOpenAvailabiltySelection(true);
                  }}
                >
                  {selectedAvailabiltyOption ? (
                    <label style={{ cursor: "pointer" }}>
                      {selectedAvailabiltyOption?.label}
                    </label>
                  ) : (
                    <label style={{ cursor: "pointer" }}>
                      Select Availability
                    </label>
                  )}

                  <DropDownIcon />
                </div>
                <CustomSelection
                  openSelection={openAvailabiltySelection}
                  setOpenSelection={setOpenAvailabiltySelection}
                  optionsArray={availabilityOptions}
                  setSelected={setSelectedAvailabiltyOption}
                  height={"auto"}
                  overflow={"auto"}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ position: "relative" }}>
                <label className={styles.inputLabel}>Time Zone</label>

                <div
                  className={classNames(styles.selectionContainer)}
                  onClick={(e: any) => {
                    setOpenTimeZoneSelection(true);
                  }}
                >
                  {selectedTimeZone ? (
                    <label style={{ cursor: "pointer" }}>
                      {selectedTimeZone?.label}
                    </label>
                  ) : (
                    <label style={{ cursor: "pointer" }}>
                      Select your time zone...
                    </label>
                  )}

                  <DropDownIcon />
                </div>
                <CustomSelection
                  openSelection={openTimeZoneSelection}
                  setOpenSelection={setOpenTimeZoneSelection}
                  optionsArray={allTimeZones}
                  setSelected={setSelectedTimeZone}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container sx={{ marginTop: "24px" }}>
            {schedule?.map((item: any, index: any, array: any) => {
              return (
                <>
                  <Grid item xs={1.5}>
                    <div
                      className={classNames(
                        styles.dayLabel,
                        index === 0 && styles.firstDayLabel,
                        index === array?.length - 1 && styles.lastDayLabel
                      )}
                    >
                      {item?.label}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <div className={styles.setTimeContainer}>
                      {item?.schedule?.length ? (
                        item?.schedule?.map((itm: any) => {
                          return <DropDownDate item={itm} />;
                        })
                      ) : (
                        <div className={styles.time}>Unavailable</div>
                      )}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className={styles.iconContainer}>
                      {item?.schedule?.length ? (
                        <>
                          {" "}
                          <RedFilledMinus />
                          <GreenFilledPlus />
                          <CopyIcon />{" "}
                        </>
                      ) : (
                        <GreenFilledPlus />
                      )}
                    </div>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

function DropDownDate({ item }: any) {
  const [hoverShowIcon, setHoverShowIcon] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const generateTimeSlots = (startHour = 0, endHour = 23, interval = 30) => {
    const slots = [];
    const startTime = new Date();
    startTime.setHours(startHour, 0, 0, 0);

    const endTime = new Date();
    endTime.setHours(endHour, 59, 59, 999);

    let id = 1;

    while (startTime <= endTime) {
      const formattedTime = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      slots.push({ id: id++, label: formattedTime });
      startTime.setMinutes(startTime.getMinutes() + interval);
    }

    return slots;
  };

  return (
    <div>
      <button
        className={styles.btnTime}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        onMouseEnter={() => {
          setHoverShowIcon(true);
        }}
        onMouseLeave={() => {
          setHoverShowIcon(false);
        }}
      >
        <div className={classNames(styles.setTime, open && styles.setTimeOpen)}>
          <div
            className={classNames(styles.time, open && styles.borderStartTime)}
          >
            {item?.startTime}
          </div>{" "}
          <div className={styles.time}>-</div>
          <div className={styles.time}>{item?.endTime}</div>
          <div>
            {hoverShowIcon && <GrayFilledCross className={styles.removeIcon} />}
          </div>
        </div>
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={styles.menuItems}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            height: "300px",
            overflow: "scroll",
            padding: "14px",
            paddingTop: "3px",
          },
        }}
      >
        {generateTimeSlots().map((itm) => {
          return (
            <MenuItem onClick={handleClose} className={styles.menuItem}>
              {itm?.label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
