import React from "react";
import styles from "./style.module.scss";
import classNames from "classnames";
import { CircularProgress } from "@mui/material";

interface CustomButtonProps {
  label?: any;
  containerStyle?: any;
  containerClass?: any;
  iconLeft?: any;
  iconRight?: any;
  iconStyle?: any;
  buttonClass?: any;
  labelClass?: any;
  labelStyle?: any;
  onClick?: any;
  IconLeftSvg?: any;
  IconRightSvg?: any;
  member?: any;
  alert?: boolean;
  loadingStyle?: any;
  loading?: boolean;
  disabled?: any;
  iconLeftStyle?: any;
}

function CustomButton({
  label,
  containerStyle,
  containerClass,
  iconLeft,
  iconRight,
  iconStyle,
  buttonClass,
  labelClass,
  labelStyle,
  onClick,
  IconLeftSvg,
  IconRightSvg,
  member,
  iconLeftStyle,
  alert,
  loadingStyle,
  loading,
  disabled,
}: CustomButtonProps) {
  return (
    <div
      className={containerClass && containerClass}
      style={containerStyle && containerStyle}
    >
      <button
        onClick={onClick}
        disabled={disabled ? disabled : false}
        className={
          buttonClass ? classNames(styles.button, buttonClass) : styles.button
        }
      >
        {loading ? (
          <CircularProgress sx={loadingStyle} />
        ) : (
          <>
            {alert && <div className={styles.alert} />}
            {member && (
              <>
                <img
                  src={member?.profile_picture}
                  alt="profilepic"
                  className={styles.img}
                />

                {member?.name}
              </>
            )}
            {IconLeftSvg && (
              <IconLeftSvg className={iconStyle} style={iconLeftStyle} />
            )}
            {iconLeft && (
              <img
                src={iconLeft}
                className={iconStyle}
                alt="icon-left"
                style={iconLeftStyle}
              />
            )}
            {!member && (
              <label
                className={classNames(labelClass, styles.label)}
                style={labelStyle}
              >
                {label}
              </label>
            )}
            {iconRight && (
              <img src={iconRight} alt="icon-right" className={iconStyle} />
            )}
            {IconRightSvg && <IconRightSvg className={iconStyle} />}
          </>
        )}
      </button>
    </div>
  );
}

export default CustomButton;
