import { Grid } from "@mui/material";
import { CrossIcon, DropDownIcon, WarningIcon } from "assets/icons";
import classNames from "classnames";
import { useFormik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import CustomInput from "shared/components/customInput";
import CustomSelection from "shared/components/customSelection";
import styles from "shared/modals/bookAnIntro/style.module.scss";
import styles1 from "./style.module.scss";
import { BookAnIntroValidation } from "shared/utils/validation";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import CustomButton from "shared/components/customButton";
import ConfirmationModal from "shared/modals/confirmation";

interface BookingFormProps {
  onClose: any;
  selectedTime: any;
  setSelectedTime: any;
  setOpenConfirmation: any;
}

interface InitialValues {
  full_name: string;
  email: string;
  service: string;
  description: string;
  phone: string;
}

const service = [
  {
    label: "UI/UX",
  },
  {
    label: "Development",
  },
  {
    label: "Custom",
  },
];

function BookingForm({
  onClose,
  selectedTime,
  setSelectedTime,
  setOpenConfirmation,
}: BookingFormProps) {
  const [serviceOpen, setServiceOpen] = useState<boolean>(false);
  const [serviceSelected, setServiceSelected] = useState<any>(null);

  const initialValues: InitialValues = {
    full_name: "",
    email: "",
    service: "",
    description: "",
    phone: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: BookAnIntroValidation,
    onSubmit: (value) => {
      onClose();
      setOpenConfirmation(true);
    },
  });

  const { handleChange, handleSubmit, values, touched, errors, setFieldValue } =
    formik;

  return (
    <div>
      <div className={styles.upperActionContainer}>
        <div className={styles.labelContainer}>
          <label className={styles.label}>Schedule Your Call</label>
          <label className={styles.selectedTime}>
            {moment(selectedTime?.date).format("dddd, MMMM") +
              " " +
              selectedTime?.schedule?.time}
          </label>
          <label className={styles.selectedTime}>
            (GMT+05:00) Pakistan Standard Time{" "}
          </label>
        </div>
        <div onClick={() => onClose()}>
          <CrossIcon className={styles.crossIcon} />
        </div>
      </div>

      <Grid container sx={{ marginTop: "24px" }} spacing={2}>
        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder={"Enter full name"}
            heading="Full Name"
            type={"text"}
            value={values.full_name}
            onChange={handleChange("full_name")}
            onKeyDown={handleSubmit}
            error={
              touched.full_name && errors.full_name ? errors.full_name : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={6} className={styles1.gridEnd}>
          <div style={{ position: "relative" }}>
            <label className={styles1.inputLabel}>General Availability</label>

            <div
              className={classNames(
                touched.service && errors.service && styles1.errorBorder,
                styles1.selectionContainer
              )}
              onClick={(e: any) => {
                setServiceOpen(true);
              }}
            >
              {serviceSelected ? (
                <label style={{ cursor: "pointer" }}>
                  {serviceSelected?.label}
                </label>
              ) : (
                <label style={{ cursor: "pointer" }}>Select Service</label>
              )}

              <DropDownIcon />
            </div>
            <CustomSelection
              openSelection={serviceOpen}
              setOpenSelection={setServiceOpen}
              optionsArray={service}
              setSelected={(item: any) => {
                setServiceSelected(item);
                setFieldValue("service", item?.label);
              }}
              height="auto"
              overflow="auto"
            />
          </div>
          {touched.service && errors.service
            ? errors.service && (
                <div className={styles1.error}>{errors.service}</div>
              )
            : ""}
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder={"Enter email"}
            heading="Email Address"
            type={"text"}
            value={values.email}
            onChange={handleChange("email")}
            onKeyDown={handleSubmit}
            error={touched.email && errors.email ? errors.email : ""}
          />
        </Grid>
        <Grid item xs={12} md={6} className={styles1.gridEnd}>
          <label className={styles1.inputLabel}>Phone 02</label>
          <div
            className={
              touched.phone && errors.phone
                ? classNames(styles1.phoneNumber, styles1.errorBorder)
                : classNames(styles1.phoneNumber)
            }
          >
            <PhoneInputWithCountrySelect
              placeholder="Enter phone number"
              value={values.phone}
              onChange={(e: any) => {
                handleChange("phone");
                setFieldValue("phone", e);
              }}
            />
          </div>
          {touched.phone && errors.phone
            ? errors.phone && (
                <div className={styles1.error}>{errors.phone}</div>
              )
            : ""}
        </Grid>
        <Grid item xs={12}>
          <label className={styles1.inputLabel}>Description</label>
          <textarea
            name="description"
            onChange={(e) => {
              setFieldValue("description", e.target.value);
            }}
            className={classNames(
              styles1.description,
              touched.description && errors.description && styles1.errorBorder
            )}
            value={values.description}
          />
          {touched.description && errors.description
            ? errors.description && (
                <div className={styles1.error}>{errors.description}</div>
              )
            : ""}
        </Grid>
      </Grid>

      <div className={styles1.btnContainer}>
        <CustomButton
          label={"Back"}
          buttonClass={styles1.backBtn}
          onClick={() => {
            setSelectedTime(null);
          }}
        />
        <CustomButton
          label={"Book My Meeting"}
          buttonClass={styles1.meetBtn}
          onClick={() => {
            handleSubmit();
          }}
        />
      </div>
    </div>
  );
}

export default BookingForm;
